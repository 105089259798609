import NavBarMobi from "@renatomobi/js-navbar-mobi/NavBarMobi";
import { AppKey } from "../config/GlobalKeys";

const getDomainWithoutSubdomain = url => {
    const urlParts = url.hostname.split('.')

    if (urlParts.length < 4) {
        return urlParts
            .slice(0)
            .slice(-(urlParts.length === 4 ? 3 : 2))
            .join('.')
    }

    return url.hostname
}

const DOMAIN = getDomainWithoutSubdomain(window.location);

export function getUser(action) {
    NavBarMobi.getUserCookie(AppKey, DOMAIN)
        .then(result => {
            if (result.status === 'success') {
                action(result.obj);
            } else {
                action(null);
            }
        })
        .catch(error => {
            action(null);
        });
}

export function loginMobi(result) {
    NavBarMobi.login(result, AppKey, DOMAIN);
}

export function logout() {
    NavBarMobi.logout(DOMAIN);
}