import React from 'react';
import { Text, View, } from '@react-pdf/renderer';

const Table = ({ children, style = {} }) => (
    <View style={{ borderLeftWidth: 1, borderLeftColor: '#dedede', ...style }} >
        {children}
    </View>
)

const Thead = ({ children, style }) => (
    <Tr style={{
        borderTopWidth: 1,
        borderTopColor: '#dedede',
        backgroundColor: '#efefef70',
        ...style
    }}>
        {children}
    </Tr>
)

const Tr = ({ children, style = {} }) => (
    <View style={{ borderBottomWidth: 1, borderBottomColor: '#dedede', display: 'flex', flexDirection: 'row', ...style, }}>
        {children}
    </View>
)

const Td = ({ children, flex, fontWeight, color, align, fontSize, style = {}, styleView = {}, childrenDefault = true }) => {
    let stylesText = {
        fontSize: fontSize ? fontSize : 6,
        color: color ? color : 'black',
        ...style
    }

    return (
        <View style={{
            flex: flex ? flex : 1,
            borderRightWidth: 1,
            borderRightColor: '#dedede',
            padding: 2,
            display: 'flex',
            alignItems: align ? align : 'center',
            justifyContent: 'center',
            ...styleView
        }}>
            {
                childrenDefault ?
                    <Text style={fontWeight ? { fontFamily: 'Times-Bold', ...stylesText, } : { ...stylesText }}>
                        {children}
                    </Text>
                    :
                    children
            }
        </View>
    )
}

export { Table, Thead, Tr, Td }



