import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const AbortController = () => {

    const {
        descritores,
        descritoresLegendas,
        fluxoStatus
    } = useSelector(state => state.calendarioLetivo)

    const {
        turnos,
        status,
        escolas,
        modelos,
        modelo,
        historico,
        superintencias,
        tecnicos
    } = useSelector(state => state.acompanhamento)

    useEffect(() => {
        return () => {
            try {
                if (superintencias.loading) superintencias.controller.abort();
            } catch (error) { }
        }
    }, [superintencias])

    useEffect(() => {
        return () => {
            try {
                if (historico.loading) historico.controller.abort();
            } catch (error) { }
        }
    }, [historico])

    useEffect(() => {
        return () => {
            try {
                if (modelo.loading) modelo.controller.abort();
            } catch (error) { }
        }
    }, [modelo])

    useEffect(() => {
        return () => {
            try {
                if (modelos.loading) modelos.controller.abort();
            } catch (error) { }
        }
    }, [modelos])

    useEffect(() => {
        return () => {
            try {
                if (escolas.loading) escolas.controller.abort();
            } catch (error) { }
        }
    }, [escolas])

    useEffect(() => {
        return () => {
            try {
                if (status.loading) status.controller.abort();
            } catch (error) { }
        }
    }, [status])

    useEffect(() => {
        return () => {
            try {
                if (fluxoStatus.loading) fluxoStatus.controller.abort();
            } catch (error) { }
        }
    }, [fluxoStatus])

    useEffect(() => {
        return () => {
            try {
                if (turnos?.loading) turnos?.controller?.abort()

            } catch (e) { }
        }
    }, [turnos])

    useEffect(() => {
        return () => {
            try {
                if (descritores?.loading) descritores?.controller?.abort()

            } catch (e) { }
        }
    }, [descritores])

    useEffect(() => {
        return () => {
            try {
                if (descritoresLegendas?.loading) descritoresLegendas?.controller?.abort()

            } catch (e) { }
        }
    }, [descritoresLegendas])

    useEffect(() => {
        return () => {
            try {
                if (tecnicos.loading) tecnicos.controller.abort();
            } catch (error) { }
        }
    }, [tecnicos])


    return null
}

export default AbortController;
