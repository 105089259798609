import { FormControl, Grid, Tooltip, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ModalTemplate from '../../../../components/modal/ModalTemplate';
import TextField from '@mui/material/TextField';
import CalendarioServices from '../../CalendarioService';
import { useDispatch } from 'react-redux';
import Masked from 'vanilla-masker'
import toastr from 'toastr'

const ModalModeloFormulario = ({ open, close, countModelos, anoSelecionado, user, modelo, isModeloUnico }) => {


    const [loading, setLoading] = useState(false)
    const [descricao, setDescricao] = useState('')
    const [dataInicio, setDataInicio] = useState('')
    const [dataFim, setDataFim] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        if (modelo) {
            setDescricao(modelo.descricao)
            setDataInicio(modelo.data_inicio)
            setDataFim(modelo.data_termino)
        } else {
            setDescricao(`Modelo ${countModelos + 1}`)
        }
    }, [countModelos, modelo])

    const validaForm = () => {
        if (dataInicio.length !== 10) {
            toastr.warning("Data de início inválida!");
            return false
        }

        if (dataFim.length !== 10) {
            toastr.warning("Data de término inválida!");
            return false
        }

        let date_inicio = CalendarioServices.getDateByDataSTR(dataInicio).getTime()
        let date_fim = CalendarioServices.getDateByDataSTR(dataFim).getTime()

        if (date_inicio >= date_fim) {
            toastr.warning("Data fim menor ou igual data de início!");
            return false;
        }

        return true
    }


    const onCreateModelo = () => {
        if (validaForm()) {
            const onSuccess = () => close()
            const onLoading = (val) => setLoading(val)

            CalendarioServices.caadastrarNovoModelo(dispatch, {
                id: modelo ? modelo.id : null,
                ano: anoSelecionado,
                modelo: modelo ? modelo.modelo : countModelos + 1,
                empresa_id: user.empresa.id,
                descricao: descricao,
                data_inicio: dataInicio,
                data_termino: dataFim
            }, onLoading, onSuccess)
        }
    }

    const renderSubTitle = () => {

        if (modelo?.id) {
            const styles = { fontSize: 14, backgroundColor: '#dedede80', padding: '4px 10px', borderRadius: 20, }

            return (
                <div style={{ marginTop: 5, marginBottom: -5 }}>
                    {!isModeloUnico && <span style={{ ...styles, marginRight: 5 }}>Modelo {modelo.modelo}</span>}
                    {<span style={{ ...styles, marginRight: 5 }}>{modelo.ano}</span>}
                </div>
            )
        } else {
            return null
        }

    }


    return (
        <ModalTemplate
            title={modelo?.id ? `Alterar período do calendario` : `Cadastrar novo modelo para ${anoSelecionado}`}
            subHeader={renderSubTitle()}
            open={open}
            textConfirm={modelo ? "SALVAR" : "CADASTRAR"}
            closeModal={close}
            desabilitarBotao={true}
            habilitarConfirmacao={true}
            onConfirm={() => onCreateModelo()}
            loading={!!loading}>
            <Grid container >
                <Grid item md={12}>
                    <FormControl style={{ width: '100%', }} >
                        <TextField
                            value={descricao}
                            onChange={(ev) => setDescricao(ev.target.value)}
                            label="Descrição"
                            variant="outlined" />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: 15 }} >
                        <Tooltip placement='top-start' title="Atenção: essa data não é a data de início do semestre!">
                            <TextField
                                value={dataInicio}
                                label="Data de abertura do calendário"
                                variant="outlined"
                                onChange={(ev) => setDataInicio(Masked.toPattern(ev.target.value, '99/99/9999'))}
                            />
                        </Tooltip>
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: 15 }} >
                        <Tooltip placement='top-start' title="Atenção: essa data não é a data de término do semestre!">
                            <TextField
                                value={dataFim}
                                label="Data de fechamento do calendário"
                                variant="outlined"
                                onChange={(ev) => setDataFim(Masked.toPattern(ev.target.value, '99/99/9999'))}
                            />
                        </Tooltip>
                    </FormControl>
                </Grid>
            </Grid>
        </ModalTemplate>
    )
}

export default ModalModeloFormulario;