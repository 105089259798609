export const TOKEN_APP = "77c9ef64c2e3033a873aba28a05ab4036ca578810602020f293abcd4ea0791e5";

export const KEY_WORKER = "calendario-letivo-worker";

export const STATUS_WORKER = {
  POSSUI_ATUALIZACAO: "POSSUI_ATUALIZACAO",
  SEM_ATUALIZACAO: "SEM_ATUALIZACAO",
};

export const ROLES = {
  PERFIL_ESCOLA: 0,
  PERFIL_GESTOR: 1,
  PERFIL_ADMIN: 2,
  PERFIL_GRE: 3,
  PERFIL_ALUNO: 4,
  PERFIL_PROFESSOR: 5,
  PERFIL_TECNICO: 6,
  PERFIL_CIDADE: 10,
};

export const TIPOS_DESCRITORES = {
  INICIO_SEMESTRE_1: 1,
  INICIO_SEMESTRE_2: 2,

  FIM_SEMESTRE_1: 3,
  FIM_SEMESTRE_2: 4,

  INICIO_BIMESTRE_1: 5,
  INICIO_BIMESTRE_2: 6,
  INICIO_BIMESTRE_3: 7,
  INICIO_BIMESTRE_4: 8,

  FIM_BIMESTRE_1: 9,
  FIM_BIMESTRE_2: 10,
  FIM_BIMESTRE_3: 11,
  FIM_BIMESTRE_4: 12,

  SABADO_LETIVO: 13,
  REPOSICAO_AULA: 14,
  
  FERIADOS: 15, //vale para os FERIADOS/DIAS SANTIFICADOS E FERIADOS MUNICIPAIS

  INICIO_TRIMESTRE_1: 16,
  INICIO_TRIMESTRE_2: 17,
  INICIO_TRIMESTRE_3: 18,

  FIM_TRIMESTRE_1: 19,
  FIM_TRIMESTRE_2: 20,
  FIM_TRIMESTRE_3: 21,
};

export const TIPOS_DESCRITORES_BIMESTRE = [
  TIPOS_DESCRITORES.INICIO_BIMESTRE_1,
  TIPOS_DESCRITORES.INICIO_BIMESTRE_2,
  TIPOS_DESCRITORES.INICIO_BIMESTRE_3,
  TIPOS_DESCRITORES.INICIO_BIMESTRE_4,
  TIPOS_DESCRITORES.FIM_BIMESTRE_1,
  TIPOS_DESCRITORES.FIM_BIMESTRE_2,
  TIPOS_DESCRITORES.FIM_BIMESTRE_3,
  TIPOS_DESCRITORES.FIM_BIMESTRE_4,
];
export const TIPOS_DESCRITORES_SEMESTRE = [
  TIPOS_DESCRITORES.INICIO_SEMESTRE_1,
  TIPOS_DESCRITORES.INICIO_SEMESTRE_2,
  TIPOS_DESCRITORES.FIM_SEMESTRE_1,
  TIPOS_DESCRITORES.FIM_SEMESTRE_2,
];
export const TIPOS_DESCRITORES_TRIMESTRE = [
  TIPOS_DESCRITORES.INICIO_TRIMESTRE_1,
  TIPOS_DESCRITORES.INICIO_TRIMESTRE_2,
  TIPOS_DESCRITORES.INICIO_TRIMESTRE_3,
  TIPOS_DESCRITORES.FIM_TRIMESTRE_1,
  TIPOS_DESCRITORES.FIM_TRIMESTRE_2,
  TIPOS_DESCRITORES.FIM_TRIMESTRE_3,
];

export const TIPOS_DESCRITORES_SABADO = [TIPOS_DESCRITORES.SABADO_LETIVO, TIPOS_DESCRITORES.REPOSICAO_AULA];

export const configCkEditor = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "strikethrough",
    "underline",
    "link",
    "|",
    "numberedList",
    "bulletedList",
    "|",
    "outdent",
    "indent",
    "alignment",
    "|",
    "undo",
    "redo",
  ],
  heading: {
    options: [
      { model: "paragraph", title: "Normal", class: "ck-heading_paragraph" },
      { model: "heading1", view: "h1", title: "h1", class: "ck-heading_heading1" },
      { model: "heading2", view: "h2", title: "h2", class: "ck-heading_heading2" },
      { model: "heading3", view: "h3", title: "h3", class: "ck-heading_heading3" },
    ],
  },
};

export const caracAceitos = [
  "!",
  "£",
  "Ã",
  "ã",
  '"',
  "¤",
  "Ä",
  "ä",
  "#",
  "¥",
  "Å",
  "å",
  "$",
  "¦",
  "Æ",
  "æ",
  "%",
  "§",
  "Ç",
  "ç",
  "¨",
  "È",
  "è",
  "'",
  "©",
  "É",
  "é",
  "(",
  "ª",
  "Ê",
  "ê",
  ")",
  "«",
  "Ë",
  "ë",
  "*",
  "¬",
  "Ì",
  "ì",
  "+",
  "Í",
  "í",
  ",",
  "®",
  "Î",
  "î",
  "-",
  "¯",
  "Ï",
  "ï",
  ".",
  "°",
  "Ð",
  "ð",
  "/",
  "±",
  "Ñ",
  "ñ",
  "²",
  "Ò",
  "ò",
  "³",
  "Ó",
  "ó",
  "´",
  "Ô",
  "ô",
  "µ",
  "Õ",
  "õ",
  "¶",
  "Ö",
  "ö",
  "·",
  "×",
  "÷",
  "¸",
  "Ø",
  "ø",
  "¹",
  "Ù",
  "ù",
  "º",
  "Ú",
  "ú",
  "»",
  "Û",
  "û",
  ":",
  "¼",
  "Ü",
  "ü",
  ";",
  "[",
  "{",
  "½",
  "Ý",
  "ý",
  "<",
  "\\",
  "|",
  "¾",
  "Þ",
  "þ",
  "=",
  "]",
  "}",
  "¿",
  "ß",
  "ÿ",
  ">",
  "^",
  "~",
  "À",
  "à",
  "?",
  "_",
  "¡",
  "Á",
  "á",
  "@",
  "'",
  "¢",
  "Â",
  "â",
  "–",
  "ß",
  "&",
  "Œ",
  "œ",
];

export const CORES_MARCADORES_CALENDARIO = {
  FERIADOS: "#FF0000"
}