import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { getUser } from "../global/service";
import Login from "../views/login/components/Login";
import SuspenseLoading from "../views/SuspenseLoading";
import Main from "../views/template/Main";

import {
  DASHBOARD,
  ROOT,
  CALENDARIO,
  ACOMPANHAMENTO_CALENDARIO,
  DETALHES_CALENDARIO,
  GERA_GRAFICO,
  RELATORIO,
} from "./HashRouter";

import PainelDashboard from "../views/dashboard/components/Painel";
import PainelCalendario from "../views/calendario/components/Painel";
import PainelAcompanhamento from "../views/calendario-acompanhamento/components/Painel";
import PainelVisualizacaoCalendario from "../views/calendario-acompanhamento/components/PainelVisualizacao";
import PainelGrafico from "../views/grafico/components/Painel";
import PainelRelatorio from "../views/relatorio/components/Painel";

import { useDispatch } from "react-redux";
import { alteraModeloUnico, setUserLogado, alterarModeloTurnos } from "../global/reducer";
import { ROLES } from "../global/constants";

const MainRoutes = () => (
  <main>
    <Suspense fallback={SuspenseLoading}>
      <Routes>
        <Route
          path={ROOT}
          element={
            <LoginRoute>
              <Login />
            </LoginRoute>
          }
        />
        <Route path={GERA_GRAFICO} element={<PainelGrafico />} />
        {/* <Route
                    path={DASHBOARD}
                    element={
                        <PrivateRoute perfil={[1, 0]}>
                            <PainelDashboard />
                        </PrivateRoute>
                    }
                /> */}
        <Route
          path={CALENDARIO}
          element={
            <PrivateRoute perfil={[1, 0]}>
              <PainelCalendario />
            </PrivateRoute>
          }
        />
        <Route
          path={ACOMPANHAMENTO_CALENDARIO}
          element={
            <PrivateRoute perfil={[ROLES.PERFIL_GESTOR, ROLES.PERFIL_GRE]}>
              <PainelAcompanhamento />
            </PrivateRoute>
          }
        />
        <Route
          path={DETALHES_CALENDARIO}
          element={
            <PrivateRoute perfil={[ROLES.PERFIL_GESTOR, ROLES.PERFIL_GRE]}>
              <PainelVisualizacaoCalendario />
            </PrivateRoute>
          }
        />
        <Route
          path={RELATORIO}
          element={
            <PrivateRoute perfil={[ROLES.PERFIL_GESTOR, ROLES.PERFIL_GRE]}>
              <PainelRelatorio />
            </PrivateRoute>
          }
        />
        {/* <Route
            path={CALENDARIO_ESCOLAR}
            element={
                <PrivateRoute perfil={[1, 6]}>
                    <CalendarioEscolas />
                </PrivateRoute>
            }
        /> */}
        {/* <Route
            path={DADOS_CALENDARIO_ESCOLAR_ESCOLA_SELECIONADA}
            element={
                <PrivateRoute perfil={[1, 6]}>
                    <DadosCalendarioEscolaSelecionada />
                </PrivateRoute>
            }
        /> */}
        <Route path="*" element={<Navigate to={ROOT} />} />
      </Routes>
    </Suspense>
  </main>
);

function LoginRoute({ children, ...rest }) {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    getUser((u) => setUser(u));
  }, []);

  if (user) {
    return <Navigate to={user.perfil === ROLES.PERFIL_ESCOLA ? CALENDARIO : ACOMPANHAMENTO_CALENDARIO} replace />;
  }
  if (user === undefined) {
    return <span>verificando...</span>;
  }
  return children;
}

function PrivateRoute({ children, ...props }) {
  const dispatch = useDispatch();
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    getUser((u) => {
      if (u && u.app_configuracoes && u.app_configuracoes.modelo_turnos) {
        let modelo = u.app_configuracoes.modelo_turnos.replace(/'/g, '"');
        dispatch(alterarModeloTurnos(JSON.parse(modelo)));
      } else {
        dispatch(alterarModeloTurnos([]));
      }
      setUser(u);
      dispatch(setUserLogado(u));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (user === undefined) {
    return <span>verificando...</span>;
  }
  if (!user) {
    return <Navigate to={ROOT} replace />;
  }
  return <Main {...props}>{children}</Main>;
}

export default MainRoutes;
