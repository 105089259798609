import { Alert, AlertTitle, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingData from '../../../../components/LoadingData';
import ModalTemplate from '../../../../components/modal/ModalTemplate';
import { atualizaDescritoresParaImportar } from '../../reducer/calendario';
import CalendarioServices from '../../CalendarioService';
import { ROLES } from '../../../../global/constants';

const ModalImportacao = ({ anoSelecionado, open, close, turnosDisponiveis, isModeloUnico }) => {

    const [modeloSelecionado, setModeloSelecionado] = useState()
    const [turnosSelecionados, setTurnosSelecioandos] = useState([])
    const [loading, setLoading] = useState(false)
    const [modalConfirmacao, setModalConfirmacao] = useState(false)
    const { user } = useSelector(state => state.global);

    const dispatch = useDispatch()

    const { modelosParaImportar, descritoresParaImportar } = useSelector(state => state.calendarioLetivo)

    useEffect(() => {
        if (modelosParaImportar?.dados && modelosParaImportar?.dados.length === 1) {
            setModeloSelecionado(modelosParaImportar.dados[0].id)
        }
    }, [modelosParaImportar?.dados])

    useEffect(() => {
        if (modeloSelecionado) {
            let params = { calendario_modelo_id: modeloSelecionado };
            if (user && user.perfil+"" === ROLES.PERFIL_ESCOLA+"") {
                params.escape_perfil = 1;
            }
            CalendarioServices.buscaDescritoresParaImportar(dispatch, params)
        }
    }, [modeloSelecionado])

    useEffect(() => {
        return () => {
            dispatch(atualizaDescritoresParaImportar(null))
        }
    }, [])

    useEffect(() => {
        if (isModeloUnico) {
            let turnos = turnosDisponiveis.map(t => t.sigla)
            setTurnosSelecioandos(turnos)
        }
    }, [isModeloUnico, turnosDisponiveis])

    const getDesabled = () => {
        if (!!modeloSelecionado && turnosSelecionados.length > 0) {
            return true
        } else {
            return false
        }
    }

    const onSubmit = () => {
        let params = {
            calendario_id: modeloSelecionado,
            ano: anoSelecionado
        }

        turnosSelecionados.forEach((turno, key) => {
            params[`turnos[${key}]`] = turno
        })

        const onLoading = (val) => setLoading(val)
        const onSuccess = () => close()

        CalendarioServices.importarModelo(dispatch, params, onLoading, onSuccess)
    }

    const retornaMsgDescritorDiaLetivo = (row) => {
        // regra dia letivo: 0 - Ignorado/Indiferente; 1 - Sim (dia letivo); 2 - Não (não letivo)
        if (row.descritor) {
            switch (row.descritor.dia_letivo + "") {
                case "1":
                    return 'Sim';
                case "0":
                    return 'Ignorado';
                case "2":
                    return 'Não';
                default:
                    return '-';
            }
        } else {
            return '-';
        }
    }

    const onChangeTurnos = (value) => {
        let turnos = [...turnosSelecionados]
        let index = turnos.findIndex(turno => turno === value);
        if (index !== -1) {
            turnos.splice(index, 1);
        } else {
            turnos.push(value)
        }
        setTurnosSelecioandos(turnos)
    }


    return (
        <>
            <ModalTemplate
                title={
                    isModeloUnico ?
                        `Importar calendário para ${anoSelecionado}`
                        :
                        `Importar modelo para ${anoSelecionado}`}
                open={open}
                textConfirm={"IMPORTAR"}
                closeModal={close}
                desabilitarBotao={getDesabled()}
                habilitarConfirmacao={getDesabled()}
                onConfirm={() => setModalConfirmacao(true)}
                loading={!!loading}>
                <Grid container spacing={2}  >
                    <Grid item md={12}>
                        {!isModeloUnico &&
                            <>
                                {
                                    turnosSelecionados.length > 1 &&
                                    <Alert severity='info' style={{ marginBottom: 20 }}>
                                        <AlertTitle>Sobre os turnos:</AlertTitle>
                                        <ul>
                                            <li>Este modelo será utilizado para os {turnosSelecionados.length} turnos.</li>
                                            <li>Caso queira um calendário diferente para cada turno, importe um de cada vez.</li>
                                        </ul>
                                    </Alert>
                                }
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Selecione os turnos</FormLabel>
                                    <FormGroup row >
                                        {turnosDisponiveis.map((turno, key) => (
                                            <FormControlLabel
                                                key={key}
                                                control={
                                                    <Checkbox
                                                        name={turno.descricao}
                                                        checked={turnosSelecionados.includes(turno.sigla)}
                                                        value={turno.sigla}
                                                        onChange={(ev) => onChangeTurnos(ev.target.value)}
                                                    />
                                                }
                                                label={turno.descricao}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </>
                        }
                    </Grid>
                    <Grid item md={12}>
                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label">Selecione o modelo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={modeloSelecionado ? modeloSelecionado : ''}
                                label="Selecione o modelo"
                                onChange={(val) => setModeloSelecionado(val.target.value)} >
                                {
                                    modelosParaImportar && modelosParaImportar.dados && modelosParaImportar.dados.map((modelo, key) => {
                                        return <MenuItem
                                            key={key}
                                            value={modelo.id}>{modelo.descricao ? modelo.descricao : `Modelo ${modelo.modelo}`}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        {descritoresParaImportar && <LoadingData obj={descritoresParaImportar} msg="Carregando descritores..." />}
                        {modelosParaImportar && <LoadingData obj={modelosParaImportar} msg="Carregando modelos..." />}
                    </Grid>
                    {
                        descritoresParaImportar && descritoresParaImportar.dados &&
                        <Grid item md={12} >

                            <span >Lista de descritores:</span>
                            <TableContainer component={Paper} variant="outlined" style={{ marginTop: 10, marginBottom: 5 }} >
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>#</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Descritor</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Data início</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Data fim</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Dia Letivo</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {descritoresParaImportar.dados.length === 0 &&
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>Nenhum descritor encontrado.</TableCell>
                                            </TableRow>
                                        }
                                        {descritoresParaImportar.dados.map((row, key) => {
                                            return (
                                                <TableRow
                                                    key={key}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>{key + 1}</TableCell>
                                                    <TableCell> {row.descritor.descricao}</TableCell>
                                                    <TableCell>{row.data_inicio}</TableCell>
                                                    <TableCell>{row.data_fim}</TableCell>
                                                    <TableCell>{retornaMsgDescritorDiaLetivo(row)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                </Grid>
            </ModalTemplate>
            <Dialog
                open={modalConfirmacao}
                onClose={() => setModalConfirmacao(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Deseja realizar importação?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Após confirmar, não será possível desfazer a importação.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end', display: 'flex' }} >
                    <Button
                        style={{ width: 10 }}
                        onClick={() => setModalConfirmacao(false)}>
                        Não
                    </Button>
                    <Button
                        style={{ width: 10 }}
                        onClick={() => {
                            setModalConfirmacao(false)
                            onSubmit()
                        }} autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModalImportacao;