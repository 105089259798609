import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
    dadosRelatorio: null,
    escolas: null,
    superintencias: null
}

const relatorioGresSlice = createSlice({
    name: "relatorioGresReducer",
    initialState: INITIAL_STATE,
    reducers: {
        listaDadosRelatorio: (state, action) => {
            state.dadosRelatorio = action.payload ? { ...action.payload } : null
        },
        listaEscolas: (state, action) => {
            state.escolas = action.payload ? { ...action.payload } : null
        },
        listaSuperintendencias: (state, action) => {
            state.superintencias = action.payload ? { ...action.payload } : null;
        },
    }
})

export const {
    listaDadosRelatorio,
    listaEscolas,
    listaSuperintendencias
} = relatorioGresSlice.actions

export default relatorioGresSlice.reducer