import ApiRequest from "../../../api/ApiRequest";
import * as ApiRoute from "../../../api/ApiRoute";
import { listaDadosRelatorio, listaSuperintendencias, listaEscolas } from "../reducer"

export default class RelatorioGreAPI extends ApiRequest {
    static buscaRelatorioGres(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_RELATORIO_GRE

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params, limit: 0 })
            }

            const callback = async (obj) => {
                dispatch(listaDadosRelatorio(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar os dados do relatório')
        }
    }
    static buscaSuperintencias(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_ALL_SUPERINTENDENCIAS

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params })
            }

            const callback = async (obj) => {
                dispatch(listaSuperintendencias(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar as superintendências!')
        }
    }
    static buscaEscolasDaRede(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_ESCOLAS

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params, inc_turnos: 1, use_loja: 1, limit: 0, status: 1})
            }

            const callback = async (obj) => {
                dispatch(listaEscolas(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar as escolas!')
        }
    }
}