import CalendarioAcompanhamentoAPI from "./api/CalendarioAcompanhamentoAPI";
import Service from "../../api/Service";

export default class CalendarioAcompanhamentoServices {
    static buscaTurnosGerais(dispatch, params) {
        Service.factoryRequest(dispatch, CalendarioAcompanhamentoAPI.buscaTurnosGerais, true, params);
    }
    static buscaStatusGerais(dispatch) {
        Service.factoryRequest(dispatch, CalendarioAcompanhamentoAPI.buscaStatusGerais, true);
    }
    static buscaEscolasDaRede(dispatch, params) {
        Service.factoryRequest(dispatch, CalendarioAcompanhamentoAPI.buscaEscolasDaRede, true, params);
    }
    static buscaCalendarios(dispatch, params) {
        Service.factoryRequest(dispatch, CalendarioAcompanhamentoAPI.buscaCalendarios, true, params);
    }
    static buscaDadosCalendarioById(dispatch, params) {
        Service.factoryRequest(dispatch, CalendarioAcompanhamentoAPI.buscaDadosCalendarioById, true, params);
    }
    static buscaHistoricoById(dispatch, params) {
        Service.factoryRequest(dispatch, CalendarioAcompanhamentoAPI.buscaHistoricoById, true, params);
    }
    static buscaSuperintencias(dispatch, params) {
        Service.factoryRequest(dispatch, CalendarioAcompanhamentoAPI.buscaSuperintencias, true, params);
    }
    static buscarTecnicosGerais(dispatch, params) {
        Service.factoryRequest(dispatch, CalendarioAcompanhamentoAPI.buscarTecnicosGerais, true, params);
    }

}
