import navbarMobi from '@renatomobi/js-navbar-mobi';
import React, { useEffect, useState } from 'react';
import { getUser, } from '../../global/service';
import TemplateService from './TemplateService';
import { useLocation } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import { Alert, Container, } from '@mui/material';
import ModalTemplate from '../../components/modal/ModalTemplate';
import ReactPlayer from 'react-player'
import Menu from './Menu';
import './main.css';


export default function Main(props) {
    const [isDisabledAux, setIsDisabledAux] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [user, setUser] = useState(null);
    const [modalVideo, setModalVideo] = useState(null);
    const { search } = useLocation();
    const idEmpresa = user?.empresa?.id
    let searchParams = new URLSearchParams(search);
    let isDisabled = searchParams.has("hide") ? true : false;

    useEffect(() => {
        if (isDisabled) {
            setIsDisabledAux(true)
        }
    }, [])

    useEffect(() => {
        let options = TemplateService.preparaNavBar();

        options.setOnReady(() => {
            options.to_perfil = () => {
            }
            options.setOnToogleMenu(() => {
                setShowMenu(!showMenu)
            });
            options.setOnShowMenu(() => {
                setShowMenu(true);
            })
            options.setOnHideMenu(() => {
                setShowMenu(false);
            })

        });
        navbarMobi(options);
        getUser((u) => setUser(u));
    }, []);

    const ModalVideo = () => {
        return (
            <ModalTemplate
                title={'Vídeo'}
                open={modalVideo}
                textConfirm={"Enviar"}
                closeModal={() => setModalVideo(false)}
                onConfirm={() => null}
                desabilitarBotao={false}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
                    <ReactPlayer
                        controls={true}
                        url={'https://youtu.be/mHIVEC3MVOo'} />
                </div>
            </ModalTemplate>
        )
    }

    return (
        <div>
            {!isDisabled && <div id="navbar-mobi"></div>}
            <div className='background-style' />
            <Menu
                setShowMenu={setShowMenu}
                showMenu={showMenu} />
            <Container
                maxWidth="xl"
                className='container-calendario'>
                {user &&
                    <>
                        {props.perfil.includes(parseInt(user.perfil)) ?
                            <div style={{ marginTop: isDisabledAux ? 16 : 75, position: 'relative', marginBottom: '24px' }}>
                                {!isDisabledAux && <BreadCrumb />}
                                {props.children}
                            </div>
                            :
                            <div style={{ marginTop: 75, position: 'relative' }}>
                                <Alert severity="warning">Seu perfil não tem permissão para esta página!</Alert>
                            </div>
                        }
                    </>}
                {modalVideo && <ModalVideo />}
            </Container>
            <div className='footerbar-calendario'>
                <Container maxWidth="xl">
                    <a target="_blank" href={`https://www.mobifile.com.br/files/manual/calendario/${idEmpresa}/calendario.pdf`}>Manual do Calendário</a>
                    |
                    <a target="_blank" href={`https://www.mobifile.com.br/files/portaria/calendario/${idEmpresa}/Circular calendario.pdf`}>Circular Calendário</a>
                    |
                    <a href='#' onClick={(e) => { e.preventDefault(); setModalVideo(true) }}>Vídeo tutorial</a>
                </Container>
            </div>
        </div>
    );
}