import ApiRequest from "../../api/ApiRequest";
import * as ApiRoute from "../../api/ApiRoute";
import Service from "../../api/Service";
import * as CacheKeys from "../../config/CacheKeys";
import { atualizaListaAnosAtivos } from "../../global/reducer";
import Dados from "../../models/Dados";

export default class HeaderApi extends ApiRequest {
  static buscaAllAnos(obj, ignoreCache = false, params = {}) {
    return (dispatch) => {
      const key = CacheKeys.KEY_LISTA_ALL_ANOS;
      const exp = CacheKeys.EXP;

      let useCache = (dados) => {
        let obj = new Dados();
        obj.dados = dados;
        dispatch(atualizaListaAnosAtivos(obj));
      };

      Service.getCache(ignoreCache, exp, key, useCache).then((cache) => {
        if (!cache) {
          let formBody = super.getParams({
            ...params,
          });

          let response = (obj, success) => {
            let dados = [];

            if (obj && obj.dados) {
              dados = obj.dados.filter((ano) => ano.status + "" === "1");
              dispatch(atualizaListaAnosAtivos({ ...obj, dados }));
            }

            if (success) {
              localStorage.setItem(key, JSON.stringify({ dados: dados, data: new Date() }));
            }
          };

          return super.requestAuth(
            ApiRoute.GET_ALL_ANOS,
            {
              method: "post",
              body: formBody,
            },
            obj,
            response,
            "Ops! Não foi possível obter as informações de anos"
          );
        }
      });
    };
  }
}
