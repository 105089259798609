import Service from "../../api/Service";
import HeaderApi from "./HeaderApi";

export default class HeaderServices {
    static buscaListaAnos(dispatch, params, ignoreCache = false) {
        Service.factoryRequest(dispatch, HeaderApi.buscaAllAnos, ignoreCache, params);
    }
}


