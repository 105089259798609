export const EXP = 86400; //UM DIA
export const KEY_LISTA_DADOS_CALENDARIO = "lista_dados_calendario"
//export const EXP_LISTA_PESQUISAS = 86400; //UM DIA
//export const KEY_LISTA_PESQUISAS = "lista_pesquisas"; 
export const KEY_SOMA_DIAS_LETIVOS = "soma_dias_letivos";
export const KEY_TOTAL_DIAS_LETIVOS = "total_dias_letivos";
export const KEY_TOTAL_SUPERINTENDENCIA = "total_superintendencia";
export const KEY_TOTAL_FUNCIONARIOS = "total_funcionarios";
export const KEY_LISTA_FREQUENCIA = "lista_frequencia";
export const KEY_LISTA_ANIVERSARIANTES_ALUNOS = "lista_aniversariantes_alunos";
export const KEY_LISTA_ANIVERSARIANTES_FUNCIONARIOS = "lista_aniversariantes_funcionarios";
export const KEY_LISTA_ESCOLAS_TURNO = "lista_escolas_turno";
export const KEY_LISTA_TIPOS_AVALIACOES = "lista_tipos_avaliacoes";
export const KEY_LISTA_SERIES_AVALIACOES = "lista_series_avaliacoes";
export const KEY_LISTA_SERIES_AVALIACOES_PROFESSOR = "lista_series_avaliacoes_professor";
export const KEY_LISTA_DISCIPLINAS_AVALIACOES = "lista_disciplinas_avaliacoes";
export const KEY_LISTA_TURMAS_AVALIACOES = "lista_turmas_avaliacoes";
export const KEY_LISTA_BANCO_DE_ITENS = "lista_banco_de_itens";
export const KEY_LISTA_SERIE_HABILIDADES = "lista_serie_habilidades";
export const KEY_AVALIACAO_CADASTRADA = "avaliacao_cadastrada";
export const KEY_LISTA_AVALIACOES = "lista_avaliacoes";
export const KEY_LISTA_AVALIACOES_CARTAO_RESPOSTA = "lista_avaliacoes_cartao_resposta";
export const KEY_AVALIACAO_SELECIONADA = "avaliacao_selecionada";
export const KEY_LISTA_FUNCIONARIOS = "lista_funcionarios";
export const KEY_LISTA_FUNCIONARIOS_PONTO = "lista_funcionarios_ponto";
export const KEY_LISTA_HORARIOS_PONTO = "lista_horarios_ponto";
export const KEY_LISTA_FUNCIONARIOS_FUNCOES = "lista_funcionarios_funcoes";
export const KEY_LISTA_ESCOLAS_FUNCOES = "lista_escolas_funcoes";
export const KEY_PAG_TOTAL_FUNCIONARIOS = "total_funcionarios_paginado";
export const KEY_LISTA_ESCOLAS = "lista_escolas";
export const KEY_LISTA_ESCOLAS_ALUNOS = "lista_escolas_alunos";
export const KEY_LISTA_CARGOS_FUNCIONARIOS = "lista_cargos_funcionarios";
export const KEY_FUNCIONARIO_SELECIONADO = "funcionario_selecionado";
export const KEY_FUNCIONARIO_SELECIONADO_FUNCOES = "funcionario_selecionado_funcoes";
export const KEY_TURMAS_PROFESSOR = "turmas_professor";
export const KEY_DISCIPLINAS_PROFESSOR = "disciplinas_professor";
export const KEY_DISCIPLINAS_FILTRO = "disciplinas_filtro";
export const KEY_COUNT_TOTAL_AVALIACOES = "total_avaliacoes";
export const KEY_LISTA_ALUNOS = "lista_alunos";
export const KEY_LISTA_ALUNOS_RESPONDER = "lista_alunos_responder";
export const KEY_COUNT_TOTAL_ALUNOS = "count_total_alunos";
export const KEY_LISTA_SUPERINTENDENCIAS = "lista_superintendencias";
export const KEY_ALUNO_SELECIONADO = "aluno_selecionado";
export const KEY_ALUNO_SELECIONADO_TURMAS = "aluno_selecionado_turmas";
export const KEY_LISTA_TURMAS = "lista_turmas";
export const KEYY_LISTA_SERIES = "lista_series";
export const KEY_LISTA_TURNOS = "lista_turnos";
export const KEY_LISTA_ZONAS = "lista_zonas";
export const KEY_LISTA_CORRACA = "lista_corraca";
export const KEY_LISTA_LOC_DIFERENCIADA = "lista_loc_diferenciada";
export const KEY_LISTA_TOTAIS_ALUNOS_POR_SERIE = "lista_totais_aluno_por_serie";
export const KEY_LISTA_TOTAIS_ALUNOS_POR_TURMA = "lista_totais_aluno_por_turma";
export const KEY_LISTA_TOTAIS_ALUNOS_POR_TURMA_DASH = "lista_totais_aluno_por_serie";
export const KEY_LISTA_OCORRENCIAS_ALUNO = "lista_ocorrencias_aluno";
export const KEY_LISTA_NOTAS_ALUNO = "lista_notas_aluno";
export const KEY_LISTA_FALTAS_ALUNO = "lista_faltas_aluno";
export const KEY_LISTA_TURMAS_AVALIACAO_SELECIONADA = "lista_turmas_avaliacao_selecionada";
export const KEY_LISTA_STATUS_ALUNOS = "lista_status_alunos";
export const KEY_LISTA_STATUS_FILTRO = "lista_status_filtro";
export const KEY_LISTA_FALTAS_POR_DISCIPLINA_ALUNO = "lista_faltas_por_disciplina_aluno";
export const KEY_LISTA_ALL_ANOS = "lista_all_anos";
/* export const KEY_NOVA_AVALIACAO = "dados_nova_avaliacao"; */
export const KEY_LISTA_ESCOLAS_CALENDARIO = "lista_escolas";
export const KEY_LISTA_PERIODOS_CALENDARIO = "lista_periodos_calendario";
export const KEY_LISTA_DESCRITORES_CALENDARIO_ESCOLA_SELECIONADA = "lista_descritores_calendario_escola_selecionada";
export const KEY_LISTA_DESCRITORES_CALENDARIO_EMPRESA_RELACIONADA = "lista_descritores_calendario_empresa_relacionada";
export const KEY_ESCOLA_SELECIONADA_VISUALIZAR_DADOS_CALENDARIO = "escola_selecionada_visualizar_dados_calendario";
