import React from 'react';

const DadosCalendarioAnual = ({ dados_descritores, modelo_trimestre = false }) => {
    
    let dadosTabela = modelo_trimestre ? 
    {
        'countDiasLetivos1semestre': dados_descritores.countDiasLetivos1semestre,
        'countDiasLetivos2semestre': dados_descritores.countDiasLetivos2semestre,
        'inicio1Trimestre': dados_descritores.inicio1Trimestre,
        'inicio2Trimestre': dados_descritores.inicio2Trimestre,
        'inicio3Trimestre': dados_descritores.inicio3Trimestre,
        'fim1Trimestre': dados_descritores.fim1Trimestre,
        'fim2Trimestre': dados_descritores.fim2Trimestre,
        'fim3Trimestre': dados_descritores.fim3Trimestre,
    }
    :
    {
        'countDiasLetivos1semestre': dados_descritores.countDiasLetivos1semestre,
        'countDiasLetivos2semestre': dados_descritores.countDiasLetivos2semestre,
        'inicio1Bimestre': dados_descritores.inicio1Bimestre,
        'inicio2Bimestre': dados_descritores.inicio2Bimestre,
        'inicio3Bimestre': dados_descritores.inicio3Bimestre,
        'inicio4Bimestre': dados_descritores.inicio4Bimestre,
        'fim1Bimestre': dados_descritores.fim1Bimestre,
        'fim2Bimestre': dados_descritores.fim2Bimestre,
        'fim3Bimestre': dados_descritores.fim3Bimestre,
        'fim4Bimestre': dados_descritores.fim4Bimestre,
    }

    const TabelaModeloBimestre = ({dadosTabela}) => (
        <tbody>
            <tr>
                <td > 1º</td>
                <td>
                    BIMESTRE - {getInfo(dadosTabela.inicio1Bimestre)} A {getInfo(dadosTabela.fim1Bimestre)}
                </td>
            </tr>
            <tr>
                <td>2º</td>
                <td>
                    BIMESTRE - {getInfo(dadosTabela.inicio2Bimestre)} A {getInfo(dadosTabela.fim2Bimestre)}
                </td>
            </tr>
            <tr style={{ fontWeight: 'bold' }}>
                <td>1º</td>
                <td>
                    SEMESTRE = {dadosTabela.countDiasLetivos1semestre} DIAS LETIVOS
                </td>
            </tr>
            <tr>
                <td>3º</td>
                <td>
                    BIMESTRE - {getInfo(dadosTabela.inicio3Bimestre)} A {getInfo(dadosTabela.fim3Bimestre)}
                </td>
            </tr>
            <tr>
                <td>4º</td>
                <td>
                    BIMESTRE - {getInfo(dadosTabela.inicio4Bimestre)} A {getInfo(dadosTabela.fim4Bimestre)}
                </td>
            </tr>
            <tr style={{ fontWeight: 'bold' }}>
                <td>2º</td>
                <td>
                    SEMESTRE = {dadosTabela.countDiasLetivos2semestre} DIAS LETIVOS
                </td>
            </tr>
            <tr style={{ fontWeight: 'bold' }}>
                <td colSpan={2}>
                    TOTAL = {dadosTabela.countDiasLetivos1semestre + dadosTabela.countDiasLetivos2semestre} DIAS LETIVOS
                </td>
            </tr>
        </tbody>
    )

    const TabelaModeloTrimestre = ({dadosTabela}) => (
        <tbody>
            <tr>
                <td > 1º</td>
                <td>
                    TRIMESTRE - {getInfo(dadosTabela.inicio1Trimestre)} A {getInfo(dadosTabela.fim1Trimestre)}
                </td>
            </tr>
            <tr>
                <td>2º</td>
                <td>
                    TRIMESTRE - {getInfo(dadosTabela.inicio2Trimestre)} A {getInfo(dadosTabela.fim2Trimestre)}
                </td>
            </tr>
            <tr>
                <td>3º</td>
                <td>
                    TRIMESTRE - {getInfo(dadosTabela.inicio3Trimestre)} A {getInfo(dadosTabela.fim3Trimestre)}
                </td>
            </tr>
            <tr style={{ fontWeight: 'bold' }}>
                <td>1º</td>
                <td>
                    SEMESTRE = {dadosTabela.countDiasLetivos1semestre} DIAS LETIVOS
                </td>
            </tr>
            <tr style={{ fontWeight: 'bold' }}>
                <td>2º</td>
                <td>
                    SEMESTRE = {dadosTabela.countDiasLetivos2semestre} DIAS LETIVOS
                </td>
            </tr>
            <tr style={{ fontWeight: 'bold' }}>
                <td colSpan={2}>
                    TOTAL = {dadosTabela.countDiasLetivos1semestre + dadosTabela.countDiasLetivos2semestre} DIAS LETIVOS
                </td>
            </tr>
        </tbody>
    )

    const getInfo = (value) => {
        return value ? value : 'Não informado'
    }

    return (
        <div style={{ backgroundColor: '#d4edda', padding: 10, borderRadius: 5 }}>
            <p style={{ marginBottom: 10, fontWeight: 'bold', fontSize: 14 }} >Dados do calendário anual:</p>
            <table className='table-calendario' >
                {modelo_trimestre ? <TabelaModeloTrimestre dadosTabela={dadosTabela} /> : <TabelaModeloBimestre dadosTabela={dadosTabela} />}
            </table>
        </div >
    )
}

export default DadosCalendarioAnual;