import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import RelatorioGreService from "../RelatorioGreService"
import { listaDadosRelatorio } from "../reducer"
import Utils from "../../../utils/Uitls";

import HeaderGlobal from '../../../components/header/HeaderGlobal';
import TabelaEscolasSemestres from "./TabelaEscolasSemestres"
import LoadingData from "../../../components/LoadingData";

const Painel = () => {
    const dispatch = useDispatch()

    const { user, anoSelecionado } = useSelector(state => state.global)
    const { dadosRelatorio, superintencias } = useSelector(state => state.relatorioGre)

    useEffect(() => {
        if (!superintencias) {
            RelatorioGreService.buscaSuperintencias(dispatch, { ano: anoSelecionado, limit: 0 })
        }
    }, [])
    
    useEffect(() => {
        if (!dadosRelatorio) {
            RelatorioGreService.buscaRelatorioGres(dispatch, { ano: anoSelecionado, somente_escolas: 1 })
        }
    }, [dadosRelatorio])
    
    useEffect(() => {
        if(anoSelecionado){
            dispatch(listaDadosRelatorio(null))
        }
    }, [anoSelecionado])

    return (
        <>
            <HeaderGlobal titulo={'Relatório de semestres'} />
            {dadosRelatorio && <LoadingData obj={dadosRelatorio} msg="Carregando dados do relatório..." />}
            {dadosRelatorio && dadosRelatorio.dados &&
                <>
                    <TabelaEscolasSemestres dadosRelatorio={dadosRelatorio.dados} superintencias={superintencias} />
                </>
            }
        </>
    )
}

export default Painel;