import { Breadcrumbs, Chip, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DASHBOARD, CALENDARIO, ACOMPANHAMENTO_CALENDARIO, DETALHES_CALENDARIO } from "../config/HashRouter";
import { Link, useLocation } from "react-router-dom";
import { styled, emphasize } from "@mui/material/styles";
import { useSelector } from "react-redux";

const inputRotas = [
  { nome: "Dashboard", path: DASHBOARD, estado: false, caminhos: [] },
  { nome: "Calendário", path: CALENDARIO, estado: false, caminhos: [] },
  { nome: "Acompanhamento calendário escolar", path: ACOMPANHAMENTO_CALENDARIO, estado: false, caminhos: [] },
  { nome: "Visualizar calendário", path: DETALHES_CALENDARIO, estado: false, caminhos: [] },
];

const BreadCrumb = () => {
  const [rotas, setRotas] = useState(inputRotas);
  const [rotaNome, setRotaNome] = useState("");
  const location = useLocation();
  const user = useSelector((state) => state.global.user);

  useEffect(() => {
    if (user) {
      let auxRotas = [...inputRotas];
      let caminhos = [];
      auxRotas.forEach((rota) => {
        if (rota.path === location.pathname) {
          rota.search = location.search;
          setRotaNome(rota.nome);
          caminhos = rota.caminhos;
        }
      });
      auxRotas.forEach((rota, index) => {
        rota.estado = false;
        if (caminhos.includes(index)) {
          rota.estado = true;
        }
      });
      setRotas(auxRotas);
    }
  }, [location]);

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor = theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      "&:hover, &:focus": {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      "&:active": {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });

  const StyledLink = styled(Link)(({ theme }) => {
    return {
      textDecoration: "none",
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
    };
  });

  const showLinks = (breadcrumbs) => {
    let auxbread = [];
    breadcrumbs.forEach((bread, index) => {
      if (bread.estado) {
        auxbread = auxbread.concat(
          <StyledLink key={index} to={bread.path}>
            <StyledBreadcrumb label={bread.nome} />
          </StyledLink>
        );
      }
    });
    auxbread = auxbread.concat(<StyledBreadcrumb key={auxbread.length} label={rotaNome} />);

    return auxbread;
  };
  return (
    <Grid container sx={{ marginTop: 2, marginBottom: 2 }}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {showLinks(rotas)}
      </Breadcrumbs>
    </Grid>
  );
};
export default BreadCrumb;
