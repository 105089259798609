const url = 'https://apiv3.mobieduca.me/';
const url2 = 'https://apps.mobieduca.me/'

export const DOMAIN = url;
export const DOMAIN2 = url2;

export const LOGIN = DOMAIN + 'login/run';
export const GET_INFO_CALENDARIO = DOMAIN2 + 'api-apps/calendario/lista';
export const GET_SOMA_DIAS_LETIVOS = DOMAIN + 'superintendencia/rel_soma_dias_letivos';
export const GET_LISTA_FREQUENCIA = DOMAIN + 'empresa/rel_presenca_diario_turno';
export const GET_TOTAL_DIAS_LETIVOS = DOMAIN + 'calendario_letivo/dias_letivos_empresa';
export const GET_TOTAL_SUPERINTENDENCIA = DOMAIN + 'superintendencia/all_count';
export const GET_TOTAL_FUNCIONARIOS = DOMAIN + 'funcionario_escola/count';
export const GET_LISTA_ANIVERSARIANTES_ALUNOS = DOMAIN + 'ano_letivo/all';
export const GET_LISTA_ANIVERSARIANTES_FUNCIONARIOS = DOMAIN + 'funcionario_escola/all_funcionarios';
export const GET_LISTA_ESCOLAS_TURNO = DOMAIN + 'escola/rel_presenca_diario';

export const GET_AVALIACAO_TIPOS = DOMAIN + 'avaliacao/all_tipos';
export const GET_AVALIACAO_SERIES = DOMAIN + 'ano_letivo/all_series_rede';
export const GET_AVALIACAO_SERIES_PROFESSOR = DOMAIN + 'funcionario_escola_turma/turmas_professor';
export const GET_AVALIACAO_DISCIPLINAS = DOMAIN + 'disciplina/all';
export const GET_AVALIACAO_DISCIPLINAS_BANCO_DE_ITENS = DOMAIN + 'banco_item/all_disciplinas';
export const GET_DISCIPLINAS_FILTRO = DOMAIN + 'avaliacao/all_list_disciplinas';
export const GET_AVALIACAO_TURMAS = DOMAIN + 'ano_letivo/all_turmas';
export const GET_AVALIACAO_TURMAS_TOTAIS = DOMAIN + 'avaliacao/all_turmas_totais';
export const GERA_CARTAO_RESPOSTA = DOMAIN + 'avaliacao/folha_resposta';
export const GET_ALUNOS_RESPONDER = DOMAIN + 'avaliacao/alunos_para_responder';
export const GET_QUESTOES_BANCO_ITENS = DOMAIN + 'banco_item/all';
export const CADASTRAR_AVALIACAO = DOMAIN + 'avaliacao/salvar';
export const CADASTRAR_AVALIACAO_TURMAS = DOMAIN + 'avaliacao/salvar_turmas';
export const GET_HABILIDADES = DOMAIN + 'habilidade/all';
export const SAVE_HABILIDADE = DOMAIN + 'habilidade/save';
export const GET_AVALIACOES = DOMAIN + 'avaliacao/all';
export const COUNT_AVALIACOES = DOMAIN + 'avaliacao/count';
export const EXCLUIR_AVALIACAO = DOMAIN + 'avaliacao/deletar';
export const FIND_AVALIACAO = DOMAIN + 'avaliacao/find';

export const GET_FUNCIONARIOS = DOMAIN + 'funcionario/all';
export const GET_FUNCIONARIOS_FUNCOES = DOMAIN + 'funcao/all';
export const GET_FUNCIONARIOS_PONTO = DOMAIN + 'funcionario_frequencia/all_funcionarios';
export const GET_HORARIOS_PONTO = DOMAIN + 'funcionario_frequencia/all_horarios';
export const COUNT_FUNCIONARIOS = DOMAIN + 'funcionario/count';
export const GET_CARGOS_FUNCIONARIOS = DOMAIN + 'cargo/all';
export const CADASTRAR_FUNCIONARIO = DOMAIN + 'funcionario/save'
export const CADASTRA_FUNCAO_FUNCIONARIO = DOMAIN + 'funcionario_escola/save'
export const GET_FUNCIONARIO_SELECIONADO = DOMAIN + 'funcionario/find';
export const EXCLUI_FUNCAO_FUNCIONARIO = DOMAIN + 'funcionario_escola/alterar_status';
export const EXCLUI_DISCIPLINA_PROFESSOR = DOMAIN + '/funcionario_escola_turma/delete';
export const EXCLUI_FUNCIONARIO = DOMAIN + 'funcionario/alterar_status';
export const GET_FUNCIONARIO_SELECIONADO_FUNCOES = DOMAIN + 'funcionario_escola/all';
export const GET_DISCIPLINAS_TURMAS = DOMAIN + 'funcionario_escola_turma/all';
export const SALVA_DISCIPLINAS_PROFESSOR = DOMAIN + 'funcionario_escola_turma/save_all_disciplinas';
export const ANO_LETIVO_GROUP_TURMA = DOMAIN + 'ano_letivo/group_by_turma';

export const GET_ALL_SUPERINTENDENCIAS = DOMAIN + 'superintendencia/all';
export const GET_ESCOLAS = DOMAIN + 'escola/all';
export const GET_ALL_ALUNOS = DOMAIN + 'ano_letivo/all';
export const GET_ALUNOS_COUNT = DOMAIN + 'ano_letivo/count';
export const GET_ALUNOS_ALL_COUNT = DOMAIN + 'aluno/count';
export const CADASTRAR_ALUNO = DOMAIN + 'aluno/save';
export const ENTURMAR_ALUNO = DOMAIN + 'ano_letivo/save';
export const CADASTRA_RESPONSAVEL_ALUNO = DOMAIN + 'responsavel/save';
export const EXCLUI_ALUNO = DOMAIN + 'aluno/save';
export const GET_ALUNO_SELECIONADO = DOMAIN + 'ano_letivo/last';
export const GET_ALUNO_SERIES = DOMAIN + 'ano_letivo/all_series_obj_rede';
export const GET_ALUNO_TURMAS = DOMAIN + 'ano_letivo/all_turmas_obj_rede';
export const GET_ALUNO_TURNOS = DOMAIN + 'ano_letivo/all_turnos_obj_rede';
export const GET_ALUNO_POR_CAMPO = DOMAIN + 'aluno/all';
export const GET_ALUNO_POR_FIND = DOMAIN + 'aluno/find';
export const DESENTURMAR_ALUNO = DOMAIN + "ano_letivo/alterar_aluno_status";
export const GET_ENDERECO_CEP = DOMAIN + 'cep/find';
export const BUSCA_TOTAIS_ALUNOS_SERIE = DOMAIN + "ano_letivo/group_by_serie_ano";
export const BUSCA_TOTAIS_ALUNOS_TURMA = DOMAIN + "ano_letivo/group_by_turma";
export const GET_ALUNO_ZONAS = DOMAIN + "aluno/all_zonas";
export const GET_ALUNO_CORRACA = DOMAIN + "aluno/all_corraca";
export const GET_ALUNO_LOCALIZACAO_DIFERENCIADA = DOMAIN + "aluno/all_localizacao_diferenciada";
export const GET_STATUS_ALUNOS = DOMAIN + "ano_letivo/all_status";
export const GET_OCORRENCIAS_ALUNO = DOMAIN + "ocorrencias";
export const GET_TOTAIS_OCORRENCIAS_ALUNO = DOMAIN + "ocorrencia/group_by_gravidade";
export const GET_NOTAS_ALUNO = DOMAIN + "nota/all";
export const GET_FREQUENCIAS_ALUNO = DOMAIN + "frequencia/all";
export const ATUALIZA_PCD = DOMAIN + "aluno/atualiza_pcd";
export const BUSCA_FALTAS_POR_DISCIPLINA = DOMAIN + "apps/aula_online/aluno_resposta/group_by_aluno_e_disciplina";
export const GET_ANO_LETIVO_ALL_ALUNOS = DOMAIN + "ano_letivo/all_alunos";
export const GET_ALL_MODELOS_TECNICOS = DOMAIN + "apps/calendario/historico/all_modelos_tecnicos";

export const GET_DESCRITORES_LEGENDA = DOMAIN + "apps/calendario/descritor_simples/all"
export const GET_DESCRITORES_CALENDARIO = DOMAIN + "apps/calendario/modelo_descritores/all"
export const ADICIONA_DESCRITOR = DOMAIN + "apps/calendario/modelo_descritores/save"
export const REMOVER_DESCRITOR = DOMAIN + 'apps/calendario/modelo_descritores/delete'

export const GET_LISTA_ESCOLAS_CALENDARIO = DOMAIN + 'escola/all';
export const GET_PERIODO_ESCOLAS_CALENDARIO = DOMAIN + 'calendario_letivo/escolas_periodos';
export const GET_LISTA_DESCRITORES_ESCOLA_SELECIONADA_CALENDARIO = DOMAIN + 'calendario_letivo/all';
export const GET_LISTA_DESCRITORES_EMPRESA_RELACIONADA_CALENDARIO = DOMAIN + 'apps/calendario/descritor/all';
export const SAVE_DESCRITOR_CALENDARIO_ESCOLA_SELECIONADA = DOMAIN + 'calendario_letivo/save';
export const GET_INFO_ESCOLA_SELECIONADA_CALENDARIO = DOMAIN + 'escola/find';

export const GET_FALTAS_FREQUENCIA = DOMAIN + "apps/aula_online/aluno_resposta/group_by_aluno_e_disciplina";
export const GET_ALUNOS_SITUACAO = DOMAIN + "ano_letivo/all_alunos";
export const GET_PROFESSORES_FREQUENCIA = DOMAIN + "funcionario_escola_turma/all";

export const CADASTRA_MODELO = DOMAIN + "apps/calendario/modelo/save"
export const GET_MODELOS_CALENDARIO = DOMAIN + "apps/calendario/modelo/all"
export const GET_MODELO_BY_ID = DOMAIN + "apps/calendario/modelo/find"

export const GET_HISTORICO_BY_MODELO_ID = DOMAIN + "apps/calendario/historico/all"

export const IMPORTAR_MODELO = DOMAIN + "apps/calendario/modelo/importar"

// export const GET_TURNOS = DOMAIN + "ano_letivo/all_turnos"
export const GET_TURNOS = DOMAIN + "escola_turno/group_by_turno"
export const GET_TURNOS_BY_ESCOLA = DOMAIN + "escola_turno/all"
export const GET_FLUXO_STATUS_CALENDARIO = DOMAIN + "apps/calendario/modelo/fluxo_calendario"
export const GET_ALL_STATUS_CALENDARIO = DOMAIN + "apps/calendario/historico/all_status"
export const ATUALIZA_STATUS_CALENDARIO = DOMAIN + "apps/calendario/historico/save"

export const ENVIA_DIAS_LETIVOS = DOMAIN + "apps/calendario/dias_letivos/save"
export const BUSCA_QUANTIDADE_DIAS_LETIVOS = DOMAIN + "apps/calendario/config_dias/all"

export const GET_TOTAIS_AVALIACOES_POR_ENSINO = DOMAIN + "avaliacao_aluno/counts_by_ano_ensino";
export const GET_SITUACOES_ANO_ANTERIOR = DOMAIN + "ano_letivo/all_situacoes_ano_anterior";
export const GET_SITUACOES_NOVA_MATRICULA = DOMAIN + "ano_letivo/all_situacoes_nova_matricula";
export const GET_ALL_ANOS = DOMAIN + "aplicacao/all_anos";

export const GET_PDF = DOMAIN2 + 'pdf/'

export const GET_RELATORIO_GRE = DOMAIN + "apps/calendario/modelo/relatorio_gres";