import { Autocomplete, Box, Card, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, styled, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import LoadingData from '../../../components/LoadingData';
import CalendarioAcompanhamentoServices from '../CalendarioAcompanhamentoServices';
import ListaEscolas from './ListaEscolas';
import HeaderGlobal from '../../../components/header/HeaderGlobal';
import { ROLES } from '../../../global/constants';
import AbortController from './AbortController';


const Input = styled(TextField)`
  background: #fff;
  border-radius: 4px;
`;

const Painel = () => {
    const {
        turnos,
        status,
        escolas,
        modelos,
        superintencias,
        tecnicos
    } = useSelector(state => state.acompanhamento)

    const {
        anoSelecionado,
        user
    } = useSelector(state => state.global)

    const [filtroEscolas, setFiltroEscolas] = useState('')
    const [gre, setGre] = useState()

    const dispatch = useDispatch()

    useEffect(() => {
        CalendarioAcompanhamentoServices.buscaStatusGerais(dispatch)
        CalendarioAcompanhamentoServices.buscaSuperintencias(dispatch, { use_loja: 1, com_escolas: 1 })
    }, [])
    useEffect(() => {
        if (superintencias?.dados && superintencias.dados.length === 1) {
            setGre(superintencias.dados[0])
        }
    }, [superintencias])

    useEffect(() => {
        if (anoSelecionado) {
            CalendarioAcompanhamentoServices.buscaTurnosGerais(dispatch, { ano: anoSelecionado, })
            CalendarioAcompanhamentoServices.buscaEscolasDaRede(dispatch, { ano: anoSelecionado })
            CalendarioAcompanhamentoServices.buscaCalendarios(dispatch, { ano: anoSelecionado, somente_escolas: 1, renew_cache: 1 })
            CalendarioAcompanhamentoServices.buscarTecnicosGerais(dispatch, { ano: anoSelecionado, somente_escolas: 1, renew_cache: 1, limit: 0, inc_nome: 1 })
        }
    }, [anoSelecionado])


    const renderLoading = () => {
        return (
            <>
                {turnos && <LoadingData obj={turnos} msg={"Buscando turnos..."} />}
                {status && <LoadingData obj={status} msg={"Buscando status..."} />}
                {escolas && <LoadingData obj={escolas} msg={"Buscando escolas..."} />}
                {modelos && <LoadingData obj={modelos} msg={"Buscando calendários..."} />}
                {tecnicos && <LoadingData obj={tecnicos} msg={"Buscando técnicos..."} />}
                {superintencias && <LoadingData obj={superintencias} msg={`Buscando ${user.empresa.nome_sup_plural}...`} />}
            </>
        )
    }

    return (
        <>
            <AbortController />
            <Grid container style={{ width: '100%' }} >
                <Grid item xs={12}>
                    <HeaderGlobal titulo={'Acompanhamento calendário'} />
                </Grid>
                <Grid item xs={12}>
                    <Card style={{ padding: 10, marginBottom: 30 }} >
                        {renderLoading()}
                        {
                            (turnos && turnos.dados) &&
                            (status && status.dados) &&
                            (escolas && escolas.dados) &&
                            (modelos && modelos.dados) &&
                            (tecnicos && tecnicos.dados) &&
                            <>
                                <Grid container spacing={2}>
                                    {
                                        user.perfil === ROLES.PERFIL_GESTOR &&
                                        <Grid item md={6} sm={12} style={{ width: '100%' }}>
                                            <FormControl fullWidth size="small">
                                                <Autocomplete
                                                    options={superintencias?.dados}
                                                    autoHighlight
                                                    freeSolo
                                                    noOptionsText={`Nenhuma ${user.empresa.nome_sup_singular} encontrada`}
                                                    value={gre ? gre : ''}
                                                    isOptionEqualToValue={(option, value) => option?.id + "" === value?.id + ""}
                                                    onChange={(event, newValue) => setGre(newValue)}
                                                    getOptionLabel={(option) => option.nome || ""}
                                                    renderOption={(props, option) => (
                                                        <Box component="li"  {...props} >
                                                            {option.nome}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <Input
                                                            {...params}
                                                            size={'small'}
                                                            label={`Selecione a ${user.empresa.nome_sup_singular}`}
                                                            placeholder='Selecione'
                                                            className="select-form-avalicao"
                                                            sx={{ background: "#fff" }}

                                                            InputLabelProps={{ shrink: true, fontSize: "0.75rem", }}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                style: { fontSize: "14px", height: 23 },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid item md={user.perfil === ROLES.PERFIL_GESTOR ? 6 : 12} sm={12} style={{ width: '100%' }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="textfield-filtrar-escola"
                                            label={"Buscar escolas"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FaSearch size={18} color={"#ccc"} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={filtroEscolas}
                                            onChange={(e) => setFiltroEscolas(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <ListaEscolas
                                    escolaSearch={filtroEscolas}
                                    greSelecionado={gre?.id}
                                />
                            </>
                        }
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Painel;