import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MdClose } from 'react-icons/md'

const StyledDialog = styled(Dialog)`
    .css-hz1bth-MuiDialog-container{
        align-items: flex-start;
    }
   & > .MuiDialog-container > .MuiPaper-root {
     max-width: unset;
     min-width: 50vw;
   }
 `;


const ModalTemplate = ({ children, open, title, onConfirm, closeModal, desabilitarBotao, subHeader, habilitarConfirmacao, isItem, isPreview, funcDownload, loading, textConfirm }) => {

    return <StyledDialog
        open={open}
        scroll='paper'
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center', height: '95vh', marginTop: '40px' }}>
        <DialogTitle style={{ padding: 16 }} >
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <Typography variant="h5" component="div">
                    {title}
                </Typography>
                {habilitarConfirmacao && <MdClose size={25} style={{ cursor: 'pointer' }} onClick={closeModal} />}
            </Grid>
            <Grid container>
                {subHeader}
            </Grid>
        </DialogTitle>
        <DialogContent dividers={true} sx={{ background: isItem ? '#ededed' : 'white' }} style={{ padding: 16 }}>
            {children}
        </DialogContent>
        <DialogActions hidden={desabilitarBotao}>
            <Grid container justifyContent='flex-end' >
                {isPreview && <Button
                    variant='contained'
                    sx={{
                        width: 'min-content',
                        background: '#1976d2',
                        padding: '6px',
                        marginRight: '0.5rem',
                        "&:hover": {
                            background: '#1b67a1',
                        }
                    }}
                    onClick={() => { funcDownload() }}
                >Download</Button>}

                <Button
                    variant='contained'
                    sx={{
                        width: '75px',
                        background: '#6c757d',
                        padding: '6px',
                        "&:hover": {
                            background: 'rgb(90, 98, 104)',
                        }
                    }}
                    onClick={closeModal}
                >Fechar</Button>

            </Grid>
        </DialogActions>
        <DialogActions hidden={!habilitarConfirmacao}>
            <Grid container justifyContent='flex-end' >
                <Button
                    disabled={loading}
                    variant='contained'
                    sx={{
                        width: 'min-content',
                        background: '#6c757d',
                        padding: '6px',
                        marginRight: '1rem',
                        "&:hover": {
                            background: '#54585c',
                        }
                    }}
                    onClick={closeModal}
                >Cancelar</Button>

                <Button
                    disabled={loading}
                    variant='contained'
                    sx={{
                        width: 'min-content',
                        background: '#1976d2',
                        padding: '6px',
                    }}
                    onClick={onConfirm}
                >{loading ? 'Aguarde...' : textConfirm ? textConfirm : 'Confirmar'}</Button>
            </Grid>
        </DialogActions>
    </StyledDialog>
}
export default ModalTemplate;