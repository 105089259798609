import Dados from "../models/Dados";

export default class Service {
    static factoryRequest(dispatch, request, ignoreCache = false, params = {}, action = null) {
        let obj = new Dados();
        obj.setAction(() => {
            obj.clear();
            if (action)
                dispatch(request(obj, ignoreCache, params, action))
            else
                dispatch(request(obj, ignoreCache, params))
        });
        obj.action();
    }

    static async getCache(ignoreCache, time, key, action){
        let expire = time;
        let dados = localStorage.getItem(key);
        //console.log('getcatche', dados.length, ignoreCache);
        let cache = false;
        if (dados && !ignoreCache) {
            let json = JSON.parse(dados);

            //console.log(json)

            let data = new Date(json.data);
            let agora = new Date();

            //milisegundos
            let diff = agora.getTime() - data.getTime();
            let tempo = expire * 1000;
            //console.log(diff, tempo)
            if (diff < tempo) {
                action(json.dados);
                cache = true;
            }

        }
        //console.log(cache)
        return cache;
    }
}