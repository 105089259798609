import { CORES_MARCADORES_CALENDARIO, ROLES, TIPOS_DESCRITORES } from "../../global/constants";
import { getUser } from "../../global/service";

export const isUsuarioSemec = (user) => {
    switch (user.perfil) {
        case ROLES.PERFIL_ESCOLA:
            if (user.relacionado.empresa_id+"" === '121') {
                return true;    
            } 
            return false;
        case ROLES.PERFIL_GESTOR:
            if (user.relacionado.id+"" === '121') {
                return true;
            }
            return false;
        case ROLES.PERFIL_GRE:
            if (user.relacionado.empresa_id+"" === '121') {
                return true;
            }
            return false;
        default:
            return false;
    }
}

export const converterParaDate = (dataString) => {
    const [dia, mes, ano] = dataString.split('/');
    const dataGMT = new Date(Date.UTC(parseInt(ano, 10), parseInt(mes, 10) - 1, parseInt(dia, 10)));
    dataGMT.setUTCDate(parseInt(dia, 10) + 1);
    return dataGMT;
}

export const geraListaFeriadosRelacionadosUsuario = (usuario, listaFeriadosDia) => {
    if (isUsuarioSemec(usuario)) {
        return listaFeriadosDia;            
    } else {
        if (listaFeriadosDia) {
            let feriado_municipal = listaFeriadosDia.find(item => item.municipio+"" !== "");
            if (listaFeriadosDia.length === 1) {
                if (feriado_municipal) {
                    return [];
                }
            }
            return listaFeriadosDia;
        } 
        return listaFeriadosDia;
    }
}

export const geraListaDescritoresFeriadoNaoCadastrados = (user, lista_feriados) => {
    let lista = [];
    let lista_feriados_filtrados = geraListaFeriadosRelacionadosUsuario(user, lista_feriados);
    lista_feriados_filtrados.forEach(item => {
        let obj = {
            data_inicio: item.data,
            data_fim: item.data,
            cor: CORES_MARCADORES_CALENDARIO.FERIADOS,
            descricao: item.nome.toUpperCase(),
            descricao_custom: null,
            descritor: {
                cor: CORES_MARCADORES_CALENDARIO.FERIADOS,
                descricao: item.nome.toUpperCase(),
                aceita_descricao: 0
            },
            tipo_descritor_id: TIPOS_DESCRITORES.FERIADOS
        };
        lista.push(obj);
    })
    return lista;
}

export const verificaDescricaoAlternativa = (descritor, isPDF = false) => {
    if (isPDF && descritor && descritor.descritor && descritor.descritor.aceita_descricao+"" === '1' && descritor.descricao_custom && descritor.descricao_custom !== "") {
        return true;
    }
    if (descritor && descritor.aceita_descricao+"" === '1' && descritor.descricao_custom && descritor.descricao_custom !== "") {
        return true;
    }
    return false;
}

export const verificaUsuarioAcessoModeloTrimestre = (user, anoSelecionado) => {
    if (user?.app_configuracoes?.trimestre && (JSON.parse(user?.app_configuracoes?.trimestre).map(Number).includes(anoSelecionado))) {
        return true;
    }
    return false;
} 

export const retornaBloqueioCalendario = async () => {
    try{
        let user = null
        await getUser(u => user = u)
        return JSON.parse(user.app_configuracoes.bloqueio_calendario.replace(/'/g, '"'));
    }catch(e){
        return null
    }
}