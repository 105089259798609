import React from 'react';
import { Page, Document, StyleSheet, View, Text } from '@react-pdf/renderer';
import MesCalendario from '../../../components/pdf/MesCalendario';
import HeaderPdf from '../../../components/pdf/Header'
import { Table, Td, Thead, Tr } from '../../../components/pdf/Table';
import { ROLES } from '../../../global/constants';
import { verificaUsuarioAcessoModeloTrimestre } from '../CalendarioHelper';


const CalendarioPDF = ({ meses, header, legendas, dadosCalendarioAnual, user }) => {

    const renderMeses = () => {
        return (meses.map((dado, key) => {
                    return (
                        <>
                            {dado ?
                                <View
                                    key={key}
                                    style={{ flex: '1 0 41%', marginVertical: 2, marginHorizontal: 2 }}>
                                    <MesCalendario
                                        par={key % 2 === 0}
                                        ano={dado.ano}
                                        mes={dado.mes}
                                        semanas={dado.semanas}
                                        {...dado}
                                        user={user}
                                    />
                                </View>
                                :
                                <View
                                    key={key}
                                    style={{ flex: '1 0 41%', marginVertical: 2, marginHorizontal: 2 }}
                                    break={key === 2 ? true : false}
                                />}
                            {key === 15 && <View break />}
                        </>
                    )
                })
        )
    }

    const renderLegendas = () => {
        return (
            <View style={{display: 'flex', flexDirection:'row', width: '100%', height: '100%', marginTop:5}} break>
                <View style={{ flex: meses.length > 12 ? 2 : 1.5, padding: '10px 5px 10px 10px' }}>
                    <Table>
                        <Thead>
                            <Td style={{ fontSize: 7, fontFamily: 'Times-Bold' }}>LEGENDA</Td>
                        </Thead>
                        {legendas.map((leg, key) => {
                            return (
                                <Tr key={key} >
                                    {leg.map((l, k) => (
                                        <Td childrenDefault={false} key={k} align={'flex-start'} >
                                            <View style={{ display: 'flex', flexDirection: 'row' }} >
                                                <View style={{ height: 5, width: 5, marginRight: 5, backgroundColor: l.cor, borderRadius: '100px' }}></View>
                                                <Text style={{ fontSize: 5, color: 'black', fontFamily: 'Times-Roman' }} >{l.descricao}</Text>
                                            </View>
                                        </Td>
                                    ))}
                                </Tr>
                            )
                        })}
                    </Table>
                </View>
                {verificaUsuarioAcessoModeloTrimestre(user, header.anoSelecionado) ? <TabelaDadosCalendarioAnualModeloTrimestre /> : <TabelaDadosCalendarioAnual />}
            </View>
        )
    }

    const TabelaDadosCalendarioAnual = () => {

        let itens_tabela = [
            { labelEsq: '1º BIMESTRE - ', labelDir: `${getInfo(inicio1Bimestre)} A ${getInfo(fim1Bimestre)} - ${getInfo(countDiasLetivos1bimestre, true)}` },
            { labelEsq: '2º BIMESTRE - ', labelDir: `${getInfo(inicio2Bimestre)} A ${getInfo(fim2Bimestre)} - ${getInfo(countDiasLetivos2bimestre, true)}` },
            { labelEsq: '1º SEMESTRE - ', labelDir: `${countDiasLetivos1semestre} DIAS LETIVOS` },
            { labelEsq: '3º BIMESTRE - ', labelDir: `${getInfo(inicio3Bimestre)} A ${getInfo(fim3Bimestre)} - ${getInfo(countDiasLetivos3bimestre, true)}` },
            { labelEsq: '4º BIMESTRE - ', labelDir: `${getInfo(inicio4Bimestre)} A ${getInfo(fim4Bimestre)} - ${getInfo(countDiasLetivos4bimestre, true)}` },
            { labelEsq: '2º SEMESTRE - ', labelDir: `${countDiasLetivos2semestre} DIAS LETIVOS` },
            { labelEsq: 'TOTAL = ', labelDir: `${countDiasLetivos1semestre + countDiasLetivos2semestre} DIAS LETIVOS`}
        ]

        const TdTabela = ({ item, estilosTexto = {} }) => (
            <Td align={'flex-start'}>
                <Text style={{ fontFamily: 'Times-Bold', ...estilosTexto }}>{item.labelEsq}</Text>
                <Text style={estilosTexto}>{item.labelDir}</Text>
            </Td>
        )

        return <View style={{ flex: 1, padding: '10px 10px 10px 5px' }}>
            <Table>
                <Thead>
                    <Td style={{ fontSize: 7, fontFamily: 'Times-Bold' }}>DADOS DO CALENDÁRIO ANUAL</Td>
                </Thead>
                {meses.length > 12 ?
                    itens_tabela.map((item, index) => (
                        <Tr key={index}>
                            <TdTabela item={item} />
                        </Tr>
                    ))
                    : <>
                        <Tr>
                            <TdTabela item={itens_tabela[0]}/>
                            <TdTabela item={itens_tabela[3]}/>
                        </Tr>
                        <Tr>
                            <TdTabela item={itens_tabela[1]}/>
                            <TdTabela item={itens_tabela[4]}/>
                        </Tr>
                        <Tr>
                            <TdTabela item={itens_tabela[2]}/>
                            <TdTabela item={itens_tabela[5]}/>
                        </Tr>
                        <Tr>
                            <TdTabela item={itens_tabela[6]} estilosTexto={{ textAlign: 'center' }}/>
                        </Tr>
                    </>}
            </Table>
        </View>
    }

    const TabelaDadosCalendarioAnualModeloTrimestre = () => {

        let itens_tabela = [
            { labelEsq: '1º TRIMESTRE - ', labelDir: `${getInfo(inicio1Trimestre)} A ${getInfo(fim1Trimestre)} - ${getInfo(countDiasLetivos1trimestre, true)}` },
            { labelEsq: '2º TRIMESTRE - ', labelDir: `${getInfo(inicio2Trimestre)} A ${getInfo(fim2Trimestre)} - ${getInfo(countDiasLetivos2trimestre, true)}` },
            { labelEsq: '3º TRIMESTRE - ', labelDir: `${getInfo(inicio3Trimestre)} A ${getInfo(fim3Trimestre)} - ${getInfo(countDiasLetivos3trimestre, true)}` },
            { labelEsq: '1º SEMESTRE - ', labelDir: `${countDiasLetivos1semestre} DIAS LETIVOS` },
            { labelEsq: '2º SEMESTRE - ', labelDir: `${countDiasLetivos2semestre} DIAS LETIVOS` },
            { labelEsq: 'TOTAL = ', labelDir: `${countDiasLetivos1semestre + countDiasLetivos2semestre} DIAS LETIVOS`}
        ]

        const TdTabela = ({ item, estilosTexto = {} }) => (
            <Td align={'flex-start'}>
                <Text style={{ fontFamily: 'Times-Bold', ...estilosTexto }}>{item.labelEsq}</Text>
                <Text style={estilosTexto}>{item.labelDir}</Text>
            </Td>
        )

        return <View style={{ flex: 1, padding: '10px 10px 10px 5px' }}>
            <Table>
                <Thead>
                    <Td style={{ fontSize: 7, fontFamily: 'Times-Bold' }}>DADOS DO CALENDÁRIO ANUAL</Td>
                </Thead>
                {meses.length > 12 ?
                    itens_tabela.map((item, index) => (
                        <Tr key={index}>
                            <TdTabela item={item} />
                        </Tr>
                    ))
                    : <>
                        <Tr>
                            <TdTabela item={itens_tabela[0]}/>
                            <TdTabela item={itens_tabela[3]}/>
                        </Tr>
                        <Tr>
                            <TdTabela item={itens_tabela[1]}/>
                            <TdTabela item={itens_tabela[4]}/>
                        </Tr>
                        <Tr>
                            <TdTabela item={itens_tabela[2]}/>
                            <TdTabela item={""}/>
                        </Tr>
                        <Tr>
                            <TdTabela item={itens_tabela[5]} estilosTexto={{ textAlign: 'center' }}/>
                        </Tr>
                    </>}
            </Table>
        </View>
    }

    const renderFooter = () => {

        const retornaFuncaoEmpresa = (campoRelacionado, listaAtual) => {
            let novaLista = [...listaAtual];
            if (user.relacionado[campoRelacionado] === 121) {
                novaLista.push('Superintendente(a)');
            } else if (user.relacionado[campoRelacionado] === 134) {
                novaLista.push('Regional');
            }
            return novaLista;
        }

        const retornaListaFuncoesRelacionadaPerfil = (user) => {
            let lista = ['Diretor', 'Coordenador(a) Pedagógico(a)'];
            switch (user.perfil) {
                case ROLES.PERFIL_ESCOLA:
                    lista = retornaFuncaoEmpresa('empresa_id', lista);
                    break;
                case ROLES.PERFIL_GESTOR:
                    lista = retornaFuncaoEmpresa('id', lista);
                    break;
                case ROLES.PERFIL_GRE:
                    lista = retornaFuncaoEmpresa('empresa_id', lista);
                    break;
                default:
                    break;
            }
            return lista;
        }

        let lista_funcoes = retornaListaFuncoesRelacionadaPerfil(user);
        return (
                <View style={styles.footer}>
                    {lista_funcoes.map((funcao, index) => 
                        <View key={index} style={{ flex: 1, textAlign: 'center' }}>
                            <Text style={{ ...styles.textFooter, marginBottom: 2 }}>_____________________________________</Text>
                            <Text style={styles.textFooter}>{funcao}</Text>
                        </View>
                    )}
                </View>
        )
    }

    const getInfo = (value, isBimestre = false) => {
        if (isBimestre) {
            return value ? value + ' DIAS LETIVOS' : 'Não informado'
        }
        return value ? value : 'Não informado'
    }

    const {
        countDiasLetivos1semestre,
        countDiasLetivos2semestre,
        countDiasLetivos1bimestre,
        countDiasLetivos2bimestre,
        countDiasLetivos3bimestre,
        countDiasLetivos4bimestre,
        inicio1Bimestre,
        inicio2Bimestre,
        inicio3Bimestre,
        inicio4Bimestre,
        fim1Bimestre,
        fim2Bimestre,
        fim3Bimestre,
        fim4Bimestre,
        countDiasLetivos1trimestre,
        countDiasLetivos2trimestre,
        countDiasLetivos3trimestre,
        inicio1Trimestre,
        inicio2Trimestre,
        inicio3Trimestre,
        fim1Trimestre,
        fim2Trimestre,
        fim3Trimestre
    } = dadosCalendarioAnual

    const FolhaAssinaturaComissaoEscolar = () => {
        const lista_linhas = Array.from({ length: 30 }, (_, index) => index + 1);

        return <Page
            size="A4"
            wrap={true}
            style={{ padding: 8 }}>
            <HeaderPdf header={header} >
                <View style={{ marginTop: '16px' }}>
                    <Text style={styles.title}>ASSINATURAS DA COMISSÃO DE ELABORAÇÃO DO CALENDÁRIO ESCOLAR / {header.anoSelecionado}</Text>
                </View>
            </HeaderPdf>
            <View style={{ display: 'flex', flexDirection: 'column', flex: 1, flexWrap: 'wrap' }}>
                <View style={{ width: '100%', paddingLeft: '16px', paddingRight: '16px' }}>
                    <Table>
                        <Thead>
                            <Td style={{ fontSize: 7, fontFamily: 'Times-Bold' }}>NOME</Td>
                            <Td style={{ fontSize: 7, fontFamily: 'Times-Bold' }}>FUNÇÃO</Td>
                            <Td style={{ fontSize: 7, fontFamily: 'Times-Bold' }}>ASSINATURA</Td>
                        </Thead>
                        {lista_linhas.map((item, index) => <Tr key={index}>
                            <Td childrenDefault={false} styleView={{ height: '14px' }}></Td>
                            <Td childrenDefault={false} styleView={{ height: '14px' }}></Td>
                            <Td childrenDefault={false} styleView={{ height: '14px' }}></Td>
                        </Tr>)}
                    </Table>
                </View>
                <View style={{ marginTop: '24px', width: '100%' }}>
                    <Text style={{ ...styles.textDataEmissao, marginBottom: '24px', textAlign: 'center' }}>Teresina, {header.infoDataEmissao} {header.infoHoraEmissao}</Text>
                    <Text style={{ marginBottom: '64px', fontSize: 10, textAlign: 'center' }}>______________________________________________</Text>
                    <Text style={{ ...styles.textFooter, fontSize: 10, textAlign: 'center' }}>Assinatura do Diretor, Coordenador Pedagógico, Representantes do Conselho Escolar demais participantes</Text>
                </View>
            </View>
            <View fixed>
                <Text style={styles.textDataEmissao}>{header.dataEmissao}</Text>
            </View>
        </Page>
    }

    const exibeFolhaAssinatura = (user) => {
        // exibe a folha de assinatura somente para os relacionados a semec
        switch (user.perfil) {
            case ROLES.PERFIL_ESCOLA:
                if (user.relacionado.empresa_id+"" === '121') {
                    return true;    
                } 
                return false;
            case ROLES.PERFIL_GESTOR:
                if (user.relacionado.id+"" === '121') {
                    return true;
                }
                return false;
            case ROLES.PERFIL_GRE:
                if (user.relacionado.empresa_id+"" === '121') {
                    return true;
                }
                return false;
            default:
                return false;
        }
    }

    return (
        <Document>
            <Page
                size="A4"
                wrap={true}
                style={{ padding: 8 }}>
                <HeaderPdf header={header} >
                    <View>
                        <Text style={styles.title}>CALENDÁRIO ESCOLAR {header.anoSelecionado}</Text>
                    </View>
                </HeaderPdf>
                <View style={{ display: 'flex', flexDirection:'row', flex:1,  flexWrap: 'wrap'}}>
                    {renderMeses()}
                    {renderLegendas()}
                </View>
                {renderFooter()}
                <View fixed>
                    <Text style={styles.textDataEmissao}>{header.dataEmissao}</Text>
                </View>
            </Page>
            {exibeFolhaAssinatura(user) && <FolhaAssinaturaComissaoEscolar />}
        </Document>
    )
}

export default CalendarioPDF;

const styles = StyleSheet.create({
    title: {
        fontSize: 10,
        fontFamily: 'Times-Bold',
        textAlign: 'center',
    },
    footer: {
        padding: '8px 8px 8px 8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    textFooter: {
        fontSize: 8,
        fontFamily: 'Times-Roman',
    },
    viewColor: {
        backgroundColor: 'red'
    },
    textDataEmissao: {
        fontSize: 6,
        fontWeight: '400',
        fontFamily: 'Courier',
        textAlign: 'right'
    },
});




