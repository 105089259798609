import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginAPI from "../api/LoginAPI";
import toastr from "toastr";
import "../login.css";
import RouteService from "../../../config/RouteService";
import { ROLES } from "../../../global/constants";
import packageInfo from "../../../../package.json";
import { ACOMPANHAMENTO_CALENDARIO, CALENDARIO, DASHBOARD } from "../../../config/HashRouter";
import { Button, CircularProgress, Divider, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { alteraModeloUnico, alterarModeloTurnos } from "../../../global/reducer";

const LogoMobi = styled("div")(({ url }) => ({
  width: "55%",
  height: "40px",
  backgroundImage: `url(${url})`,
  backgroundSize: "cover",
}));

const VERSAO_WEB = packageInfo.version;

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.addEventListener("keypress", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        entrar();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function entrar() {
    try {
      let email = document.querySelector("#email").value;
      let senha = document.querySelector("#senha").value;

      if (!email) {
        toastr.warning("Digite seu e-mail!");
        return;
      }

      if (!senha) {
        toastr.warning("Digite sua senha!");
        return;
      }

      setLoading(true);
      dispatch(
        LoginAPI.login({ email, senha }, (dados, success) => {
          setLoading(false);
          if (dados && dados.app_configuracoes && dados.app_configuracoes.modelo_turnos) {
            let modelo = dados.app_configuracoes.modelo_turnos.replace(/'/g, '"');
            dispatch(alterarModeloTurnos(JSON.parse(modelo)));
          } else {
            dispatch(alterarModeloTurnos([]));
          }

          if (success) {
            RouteService.goToPage(
              navigate,
              dados.perfil === ROLES.PERFIL_ESCOLA ? CALENDARIO : ACOMPANHAMENTO_CALENDARIO
            );
          }
        })
      );
    } catch (e) {
      //console.log(e)
    }
  }

  return (
    <div className="wrapper-login">
      <div className="login-page" style={{ backgroundImage: 'url("/imgs/backlogin2.png")' }}>
        <img className="img-logomarca-pc" src="/imgs/logo2.png" alt="" />
        <div className="container-form">
          <div className="form">
            {loading && (
              <div className="progress-loading">
                <div className="progress-indicator"></div>
              </div>
            )}
            <form className="login-form">
              <div className="title-login">
                <span>Calendário Letivo</span>
              </div>
              <div className="subtitle-login">
                <span>Versão {VERSAO_WEB}</span>
              </div>
              {/* <img width={250} style={{ marginBottom: 25 }} src='/imgs/logo-login.png' /> */}
              <input type="text" placeholder="Usuário/Email" id="email" />

              <input type="password" placeholder="Senha" id="senha" />

              <Button
                id="bt-entrar"
                variant="contained"
                disabled={loading}
                color={"success"}
                onClick={entrar}
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  background: "#54bddb",
                  width: "85%",
                  backgroundColor: "#077f6a",
                }}
              >
                {loading ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: 10 }} />
                    Entrando...
                  </>
                ) : (
                  "Entrar"
                )}
              </Button>
            </form>
            <Grid container justifyContent="center" p={2}>
              <LogoMobi url="/imgs/mobieducame.png" />
            </Grid>
          </div>
        </div>
      </div>
      {/* <footer ><div className="logo" style={{ marginLeft: 16 }}>Versão {VERSAO_WEB}</div></footer> */}
    </div>
  );
}
