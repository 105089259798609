import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Grid,
    Box,
    Autocomplete,
    TextField,
    FormControl,
    styled,
    InputAdornment
} from '@mui/material/';
import { FaSearch } from 'react-icons/fa';
import { ROLES } from "../../../global/constants";

import Utils from "../../../utils/Uitls";
import { useEffect } from "react";

const Input = styled(TextField)`
  background: #fff;
  border-radius: 4px;
`;

const headersTitle = ['GRE', 'Escola', 'INEP', 'Cidade', 'Status', 'Semestre I', 'Semestre II', 'Total de dias letivos']

const TabelaEscolasSemestres = ({ dadosRelatorio, superintencias }) => {
    const { user } = useSelector(state => state.global)

    const [siSelecionada, setSiSelecionada] = useState('')
    const [filtroEscolas, setFiltroEscolas] = useState('')
    const [limitePaginacao, setLimitePaginacao] = useState(30)

    const filtroEscola = (isCsv = false) => {
        return dadosRelatorio.filter(el => (!siSelecionada || el.si_id + "" === siSelecionada.id + "") &&
            (!filtroEscolas || el.escola.toLowerCase().includes(filtroEscolas)))
            .slice(0, isCsv ? dadosRelatorio.length : limitePaginacao)
    }

    const listaEscolas = useMemo(() => {
        return filtroEscola(false)
    }, [siSelecionada, filtroEscolas])

    const handleGerarCsv = () => {

        const now = new Date();
        const serial = {
            month: now.getMonth(),
            day: now.getDay(),
            hour: now.getHours(),
            minutes: now.getMinutes(),
            seconds: now.getSeconds()
        }

        const fileName = `Relatorio_Periodos_Escolas_${serial.month}${serial.day}${serial.hour}${serial.minutes}${serial.seconds}`;
        const headers = ['gre', 'escola', 'inep', 'cidade', 'status', 'semestre1', 'semestre2'];
        const list = filtroEscola(true).map(el => ({
            gre: el.gre,
            escola: el.escola,
            inep: el.inep,
            cidade: el.cidade,
            status: el.status,
            semestre1: `${Utils.formatDataString(el.ini1)} - ${Utils.formatDataString(el.fim1)}`,
            semestre2: `${Utils.formatDataString(el.ini2)} - ${Utils.formatDataString(el.fim2)}`
        }));

        Utils.exportCsv(list, headers, fileName);
    }
    return (
        <>
            <Grid container spacing={1}>
                {
                    user.perfil === ROLES.PERFIL_GESTOR &&
                    <Grid item md={3} sm={12} style={{ width: '100%' }}>
                        <FormControl fullWidth size="small">
                            <Autocomplete
                                options={superintencias?.dados}
                                autoHighlight
                                freeSolo
                                noOptionsText={'Nenhuma superintendência encontrada'}
                                value={siSelecionada ? siSelecionada : ''}
                                isOptionEqualToValue={(option, value) => option?.id + "" === value?.id + ""}
                                onChange={(event, newValue) => setSiSelecionada(newValue)}
                                getOptionLabel={(option) => option.nome || ""}
                                renderOption={(props, option) => (
                                    <Box component="li"  {...props} >
                                        {option.nome}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <Input
                                        {...params}
                                        size={'small'}
                                        label="Selecione a superintendência"
                                        placeholder='Selecione'
                                        className="select-form-avalicao"
                                        sx={{ background: "#fff" }}

                                        InputLabelProps={{ shrink: true, fontSize: "0.75rem", }}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { fontSize: "14px", height: 23 },
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                }
                <Grid item md={user.perfil === ROLES.PERFIL_GESTOR ? 3 : 12} sm={12} style={{ width: '100%' }}>
                    <TextField
                        fullWidth
                        size="small"
                        id="textfield-filtrar-escola"
                        label={"Buscar escolas"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <FaSearch size={18} color={"#ccc"} />
                                </InputAdornment>
                            ),
                        }}
                        value={filtroEscolas}
                        onChange={(e) => setFiltroEscolas(e.target.value)}
                    />
                </Grid>
                <Grid container item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 1 }}>
                    <Grid item >
                        <Button
                            variant="contained"
                            onClick={handleGerarCsv}
                        >
                            Gerar CSV
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Paper sx={{ marginBottom: '50px', paddingBottom: '10px' }}>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    headersTitle.map((el, i) =>
                                        <TableCell key={i} align="center">{el}</TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listaEscolas && listaEscolas.map((item) => (
                                <TableRow key={item.escola_id}>
                                    <TableCell align="left"> {item.gre} </TableCell>
                                    <TableCell align="left"> {item.escola} </TableCell>
                                    <TableCell align="center"> {item.inep} </TableCell>
                                    <TableCell align="left"> {item.cidade} </TableCell>
                                    <TableCell align="center"> {item.status} </TableCell>
                                    <TableCell align="center"> {Utils.formatDataString(item.ini1)} - {Utils.formatDataString(item.fim1)} </TableCell>
                                    <TableCell align="center"> {Utils.formatDataString(item.ini2)} - {Utils.formatDataString(item.fim2)} </TableCell>
                                    <TableCell align="center"> {item.total} </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {listaEscolas && limitePaginacao <= listaEscolas.length &&
                    <Box sx={{ textAlign: "center", marginTop: '10px' }}>
                        <Button
                            onClick={() => setLimitePaginacao((li => li + 30))}
                            variant="contained"
                            style={{ width: 200 }}
                        >
                            VER MAIS
                        </Button>

                    </Box>
                }
            </Paper>
        </>
    )
}

export default TabelaEscolasSemestres;