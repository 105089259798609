import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaCheck, FaTimes } from 'react-icons/fa'
import ModalTemplate from '../../../../components/modal/ModalTemplate';
import CalendarioServices from '../../CalendarioService';

const ModalRegras = ({ open, descritorSelecionado, close, descritoresCalendario, modelo }) => {

    const [itens, setItens] = useState([])

    useEffect(() => {
        if (descritorSelecionado && descritoresCalendario) {
            let lista = []
            descritorSelecionado.regras.forEach(regra => {
                let { tipo_descritor_ini, tipo_descritor_fim, descricao } = regra.periodo
                let infoRegras = CalendarioServices.buscaRegrasDescritores(descritoresCalendario, descritorSelecionado, tipo_descritor_ini, tipo_descritor_fim, regra, modelo)
                let obj = {
                    descricao: descricao,
                    quantidade: regra.quantidade,
                    possuiItens: infoRegras?.possuiItens,
                    descritores: infoRegras?.descritores
                }
                lista.push(obj)
                // }
            });
            setItens(lista)
        }
    }, [descritorSelecionado, descritoresCalendario])

    return (
        <ModalTemplate
            title={`${descritorSelecionado?.descricao} (Quantidade mínima)`}
            open={open}
            closeModal={close}
            desabilitarBotao={false}>
            <Grid container >
                <Grid item md={12}>
                    {itens.map((item, key) => {
                        return (
                            <div key={key} style={{ padding: '5px 10px', border: '1px solid #dedede', margin: '10px 0px', borderRadius: 4 }} >
                                <div style={{ fontWeight: 'bold' }}>{item.descricao} </div>
                                <div>
                                    {item.possuiItens &&
                                        <FaCheck style={{ marginRight: 5 }} color='green' fontSize={18} />
                                    }
                                    Quantidade mínima: {item.quantidade}
                                </div>
                                {item.descritores && item.descritores.length > 0 &&
                                    <div style={{ fontSize: 12, marginTop: 8 }}>
                                        Data(s) inseridas(s):
                                        {item.descritores.map((desc, key) => <span key={key}> {desc.data_inicio};</span>)}
                                    </div>
                                }
                            </div>
                        )
                    })}
                </Grid>
            </Grid>
        </ModalTemplate>
    )
}

export default ModalRegras;