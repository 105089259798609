import React, { useEffect, useState } from "react";
import Utils from "../../../utils/Uitls";
import CalendarioServices from "../CalendarioService";
import ModalItem from "./modal/ModalItem";
import { useDispatch, useSelector } from "react-redux";
import { alteraDataFimDescritores, alteraDataIniDescritores } from "../reducer/calendario";
import { Grid } from "@mui/material";
import MesView from "./MesView";
import { ROLES } from "../../../global/constants";
import "../styles/calendario.css";
import { verificaUsuarioAcessoModeloTrimestre } from "../CalendarioHelper";

const meses = [
  { nome: "Janeiro", value: 0 },
  { nome: "Fevereiro", value: 1 },
  { nome: "Março", value: 2 },
  { nome: "Abril", value: 3 },
  { nome: "Maio", value: 4 },
  { nome: "Junho", value: 5 },
  { nome: "Julho", value: 6 },
  { nome: "Agosto", value: 7 },
  { nome: "Setembro", value: 8 },
  { nome: "Outubro", value: 9 },
  { nome: "Novembro", value: 10 },
  { nome: "Dezembro", value: 11 },
];

const Calendario = ({ ano, permiteAlteracao }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState();

  const [descritoresByData, setDescritoresByData] = useState([]);
  const [descritoresForm, setDescritoresForm] = useState([]);

  const [dataMinimaModelo, setDataMinimaModelo] = useState();
  const [dataMaximaModelo, setDataMaximaModelo] = useState();

  const [anoSelecionado, setAnoSelecionado] = useState(ano);

  const { descritores, data_ini, data_fim, descritoresLegendas, modeloSelecionado, modelos } = useSelector(
    (state) => state.calendarioLetivo
  );

  const { modelo: modeloAcompanhamento } = useSelector((state) => state.acompanhamento);

  const { user } = useSelector((state) => state.global);

  const dispatch = useDispatch();

  const modelo_trimestre = verificaUsuarioAcessoModeloTrimestre(user, ano);

  useEffect(() => {
    let temModelo = modeloAcompanhamento?.dados || modelos?.dados;
    if (temModelo && modeloSelecionado) {
      let modelo = modeloAcompanhamento?.dados ?? modelos.dados.find((modelo) => modelo.id == modeloSelecionado);
      if (modelo) {
        setDataMaximaModelo(CalendarioServices.getDateByDataSTR(modelo.data_termino));
        setDataMinimaModelo(CalendarioServices.getDateByDataSTR(modelo.data_inicio));
      }
    }
  }, [modelos?.dados, modeloSelecionado, modeloAcompanhamento]);

  useEffect(() => {
    if (descritoresLegendas && descritoresLegendas.dados && descritores && descritores.dados && data_ini && data_fim) {
      //verifica se é domingo
      if (data_ini === data_fim && data_ini.getDay() === 0) {
        closeModal();
      } else {
        const getDescritoresForm = async () => {
          //busca os descritores para o seletor do modal
          let itens = await CalendarioServices.getDescritoresParaSeletorFormulario(
            descritoresLegendas.dados.lista,
            descritores.dados,
            data_ini,
            data_fim,
            user.perfil === ROLES.PERFIL_GESTOR
          );
          setDescritoresForm(itens);
        };

        getDescritoresForm();
      }
    }
  }, [descritoresLegendas?.dados, descritores?.dados, data_ini, data_fim, type]);

  useEffect(() => {
    if (["editar", "excluir"].includes(type) && !!data_ini) {
      let dataFormat = Utils.formatData2(data_ini);
      let desc = descritores && descritores.dados ? descritores.dados.descritores : {};

      let descByData = desc[dataFormat];
      let isRede = user.perfil === ROLES.PERFIL_GESTOR;
      if (!isRede && descritores) {
        descByData = descByData.filter((desc) => desc.descritor_rede === 0);
      }
      setDescritoresByData(descByData);
    }
  }, [type, data_ini]);

  const openModal = (date, type, click) => {
    let dateFormat = CalendarioServices.getDateByDataSTR(date);
    setType(type);

    if (type === "editar" || type === "excluir") {
      dispatch(alteraDataIniDescritores(dateFormat));
      dispatch(alteraDataFimDescritores(dateFormat));
    } else {
      if (click === "long") {
        if (data_ini) {
          dispatch(alteraDataFimDescritores(dateFormat));
        } else {
          dispatch(alteraDataIniDescritores(dateFormat));
        }
      } else {
        if (data_ini) {
          dispatch(alteraDataFimDescritores(dateFormat));
        } else {
          dispatch(alteraDataIniDescritores(dateFormat));
          dispatch(alteraDataFimDescritores(dateFormat));
        }
      }
    }
  };

  const closeModal = () => {
    setType(undefined);
    setDescritoresByData([]);
    dispatch(alteraDataFimDescritores(undefined));
    dispatch(alteraDataIniDescritores(undefined));
  };

  const onConfirm = async (desc_id, data_ini, data_fim, desc_custom = null) => {
    let descritor =
      type === "salvar"
        ? descritoresLegendas.dados.lista.find((desc) => desc.id === desc_id)
        : descritoresByData.find((desc) => desc.id === desc_id);

    let modelo = modeloAcompanhamento?.dados ?? modelos.dados.find((modelo) => modelo.id === modeloSelecionado);

    let valida = await CalendarioServices.validacaoSubmit(
      descritor,
      data_ini,
      data_fim,
      descritores.dados.descritoresLista,
      type,
      modelo,
      modelo_trimestre
    );

    if (valida) {
      if (type === "salvar") {
        cadastraDescritor(desc_id, desc_custom);
      }
      if (type === "editar") {
        editaDescritor(desc_id, data_ini, data_fim, desc_custom);
      }

      if (type === "excluir") {
        removerDescritor(desc_id);
      }
    }
  };

  const cadastraDescritor = (desc_id, desc_custom = null) => {

    let params = {
      data_inicio: Utils.formatData2(data_ini),
      data_fim: Utils.formatData2(data_fim),
      ano: anoSelecionado,
      descritor_id: desc_id,
      data_alteracao: Utils.formatData3(new Date()),
      modelo_id: modeloSelecionado,
      domingos: CalendarioServices.getDomingos(data_ini, data_fim),
    }

    if (desc_custom) {
      params.descricao_custom = desc_custom
    }

    const onLoading = (val) => setLoading(val);
    CalendarioServices.adicionarDescritor(
      dispatch,
      params,
      descritores.dados.descritoresLista,
      onLoading,
      closeModal,
      modelo_trimestre
    );
  };

  const editaDescritor = (desc_id, data_ini, data_fim, desc_custom = null) => {
    let descritor = descritoresByData.find((desc) => desc.id === desc_id);

    let params = {
      id: desc_id,
      data_inicio: Utils.formatData2(data_ini),
      data_fim: Utils.formatData2(data_fim),
      ano: anoSelecionado,
      descritor_id: descritor.descritor_id,
      data_alteracao: Utils.formatData3(new Date()),
      modelo_id: modeloSelecionado,
      domingos: CalendarioServices.getDomingos(data_ini, data_fim),
    }

    if (desc_custom) {
      params.descricao_custom = desc_custom;
    }

    const onLoading = (val) => setLoading(val);
    CalendarioServices.editarDescritor(
      dispatch,
      params,
      descritores.dados.descritoresLista,
      onLoading,
      closeModal,
      modelo_trimestre
    );
  };

  const removerDescritor = (desc_id) => {
    const onLoading = (val) => setLoading(val);
    CalendarioServices.removerDescritor(
      dispatch,
      {
        id: desc_id,
        modelo_id: modeloSelecionado,
      },
      descritores.dados.descritoresLista,
      onLoading,
      closeModal,
      modelo_trimestre
    );
  };

  return (
    <>
      <Grid item md={4} sm={4} xs={12}>
        <div
          onClick={() => setAnoSelecionado(ano - 1)}
          className={`button-ano-calendario ${anoSelecionado === ano - 1 ? "button-select" : ""}`}
        >
          <span>{ano - 1}</span>
        </div>
      </Grid>
      <Grid item md={4} sm={4} xs={12}>
        <div
          onClick={() => setAnoSelecionado(ano)}
          className={`button-ano-calendario ${anoSelecionado === ano ? "button-select" : ""}`}
        >
          <span>{ano}</span>
        </div>
      </Grid>
      <Grid item md={4} sm={4} xs={12}>
        <div
          onClick={() => setAnoSelecionado(ano + 1)}
          className={`button-ano-calendario ${anoSelecionado === ano + 1 ? "button-select" : ""}`}
        >
          <span>{ano + 1}</span>
        </div>
      </Grid>
      {meses.map((mes, key) => (
        <Grid key={key} item sm={4} md={4} xs={12} lg={3}>
          <MesView
            diasLetivos={descritores && descritores.dados ? descritores.dados.diasLetivos : {}}
            descritores={descritores && descritores.dados ? descritores.dados.descritores : {}}
            bloqueios={descritores && descritores.dados && descritores.dados.bloqueios}
            title={`${mes.nome} ${anoSelecionado}`}
            startDate={new Date(anoSelecionado, mes.value, 1)}
            mes={mes.value}
            ano={anoSelecionado}
            openModal={openModal}
            dataMinima={dataMinimaModelo}
            dataMaxima={dataMaximaModelo}
            permiteAlteracao={permiteAlteracao}
          />
        </Grid>
      ))}
      <ModalItem
        action={(descritor_id, data_ini, data_fim, descricaoAlt) => onConfirm(descritor_id, data_ini, data_fim, descricaoAlt)}
        close={closeModal}
        open={!!data_ini && !!data_fim}
        type={type}
        descritoresByData={descritoresByData}
        descritores={descritoresForm}
        loading={loading}
      />
    </>
  );
};

export default Calendario;
