import React from 'react'
import { Text, View } from '@react-pdf/renderer';
import { Td } from './Table';
import { verificaDescricaoAlternativa } from '../../views/calendario/CalendarioHelper';
const ObterTextoDescritores = ({ descritoresMes, styles, par }) => {
    let data_desc_agrupado = {};
    descritoresMes.forEach((desc, key,) => {
        let dia_ini = `${desc.data_inicio.substring(0, 2)}`;
        let mes_ini = `${desc.data_inicio.substring(3, 5)}`;
        let dia_fim = `${desc.data_fim.substring(0, 2)}`;
        let mes_fim = `${desc.data_fim.substring(3, 5)}`;

        let data_desc = "";
        if (mes_ini === mes_fim) {
            if (dia_ini !== dia_fim) {
                data_desc = `${dia_ini} à ${dia_fim}`;
            } else {
                data_desc = `${dia_ini}`;
            }
        } else {
            data_desc = `${dia_ini}/${mes_ini} à ${dia_fim}/${mes_fim}`;
        }

        let descritor_com_descricao_alternativa = verificaDescricaoAlternativa(desc, true);

        if (descritor_com_descricao_alternativa) {
            if (!data_desc_agrupado[desc.descricao_custom.trim().toUpperCase()]) {
                data_desc_agrupado[desc.descricao_custom.trim().toUpperCase()] = {
                    nome: desc.descricao_custom.trim().toUpperCase(),
                    dias: [data_desc],
                    cor: desc.descritor.cor ? desc.descritor.cor : "#ffffff"
                };
            } else {
                data_desc_agrupado[desc.descricao_custom.trim().toUpperCase()].dias.push(data_desc);
            }
        } else {
            if (!data_desc_agrupado[desc.descritor.descricao]) {
                data_desc_agrupado[desc.descritor.descricao] = {
                    nome: desc.descritor.descricao,
                    dias: [data_desc],
                    cor: desc.descritor.cor ? desc.descritor.cor : "#ffffff"
                };
            } else {
                data_desc_agrupado[desc.descritor.descricao].dias.push(data_desc);
            }
        }

    });
    const sortedData = Object.keys(data_desc_agrupado).sort((a, b) => {
        const diasA = data_desc_agrupado[a].dias[0].slice(0, 2);
        const diasB = data_desc_agrupado[b].dias[0].slice(0, 2);
        return diasA.localeCompare(diasB)

    });

    return sortedData.map((item, key) => (
        <View key={key} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ paddingBottom: 1 }}>
                <Td styleView={{ flexDirection: 'row', backgroundColor: data_desc_agrupado[item].cor, maxWidth: 1, maxHeight: 1, borderRadius: '100px', border: 'none', marginRight: 1 }}></Td>
            </View>
            <Text style={{ ...styles.text, textAlign: par ? 'right' : 'left' /* , marginTop: key === 0 ? 5 : 0 */ }}>
                {`${data_desc_agrupado[item].dias} - ${data_desc_agrupado[item].nome}`}
            </Text>
        </View>
    ));
}
export default ObterTextoDescritores