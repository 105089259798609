import {
  Box,
  Button,
  Chip,
  FormControl,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaEye, FaHistory, FaExchangeAlt } from "react-icons/fa";
import StringUtils from "../../../utils/StringUtils";
import { DETALHES_CALENDARIO } from "../../../config/HashRouter";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import ListStatus from "./ListaStatus";
import ModalHistorico from "../../calendario/components/modal/ModalHistorico";
import CalendarioServices from "../../calendario/CalendarioService";
import ModalAlteraStatus from "../../calendario/components/modal/ModalAlteraStatus";
import { alteraModeloSelecionado, atualizaDescritoresCalendario, atualizaStatusCalendarioSelecionado } from "../../calendario/reducer/calendario";
import CalendarioAcompanhamentoServices from "../CalendarioAcompanhamentoServices";


const ListaEscolas = ({ escolaSearch, greSelecionado }) => {
  const { user } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tabSelect, setTabSelect] = useState(0);
  const [escolasFormatadas, setEscolasFormatadas] = useState([]);
  const [statusSelecionado, setStatusSelecionado] = useState();
  const [modalHistorico, setModalHistorico] = useState();
  const [modalAlteraStatus, setModalAlteraStatus] = useState();
  const [loadingAlteraStatus, setLoadingAlteraStatus] = useState(false);
  const [modelo, setModelo] = useState();
  const [escola, setEscola] = useState();
  const [turno, setTurno] = useState();
  const [isModeloUnico, setIsModeloUnico] = useState(false);
  const [limitList, setLimitList] = useState({
    I: 30,
    M: 30,
    N: 30,
    T: 30,
  });

  const { escolas, turnos, modelos, status, superintencias, modelo: modeloAcompanhamento, tecnicos} = useSelector((state) => state.acompanhamento);

  const { anoSelecionado, modelo_turnos } = useSelector((state) => state.global);
  const { descritores, fluxoStatus } = useSelector(state => state.calendarioLetivo);

  const abrirVisualizacao = (escola) => {
    if (escola.modelo.id) {
      navigate({
        pathname: DETALHES_CALENDARIO,
        search: `?id=${escola.modelo.id}`,
      });
    } else {
      toastr.warning("Escola não iniciou a configuração do calendário");
    }
  };

  const abrirHistorico = (escola) => {
    if (escola.modelo.id) {
      setEscola(escola.nome);
      setModelo(escola.modelo);
      setModalHistorico(escola.modelo.id);

    } else {
      toastr.warning("Escola não iniciou a configuração do calendário");
    }
  };

  const fecharHistorico = () => {
    setEscola(undefined);
    setModelo(undefined);
    setModalHistorico(undefined);
  };

  const abrirModalAlterarStatus = (modelo, nomeDaEscola) => {
    let nova_lista_modelo = {...modelo, escola: {nome: nomeDaEscola}}
    setModelo(nova_lista_modelo);
    setModalAlteraStatus(true);
  }

  const fecharModalAlterarStatus = () => {
    setModelo(undefined);
    setModalAlteraStatus(undefined);
  }

  const verificaPerfilLogadoEStatusCalendario = (fluxoStatus, status_atual) => {
      let permite_alteracao = !!fluxoStatus.find(
        (f) => f.perfil === user.perfil && f.status_anterior_id + "" === status_atual + ""
      );
      return permite_alteracao;
  };

  const alteraSituacaoCalendario = (calendario_id, status_id, comentario, gera_dias_letivos) => {
    const onLoading = (val) => setLoadingAlteraStatus(val);
    const onSuccess = () => {
      if (modeloAcompanhamento?.dados) {
        dispatch(
          alteraModeloSelecionado({
            ...modeloAcompanhamento,
            dados: { ...modeloAcompanhamento.dados, status_atual_id: status_id },
          })
        );
      } else {
        dispatch(
          atualizaStatusCalendarioSelecionado({
            calendario_id,
            status_id,
          })
        );
      }
        toastr.success("Situação do calendário alterada com sucesso.");
        setModalAlteraStatus(false);
        CalendarioAcompanhamentoServices.buscaCalendarios(dispatch, { ano: anoSelecionado, somente_escolas: 1, renew_cache: 1 })
    };

    const enviaDiasLetivosCallback = () => {
      if (descritores?.dados) {
        enviaDiasLetivos(calendario_id, onLoading, onSuccess);
      } else {
        onSuccess();
      }
    };

    let callback = gera_dias_letivos ? enviaDiasLetivosCallback : onSuccess;

    let params = {
      calendario_id,
      status_id,
      comentario,
    };
    CalendarioServices.alteraSituacaoCalendario(dispatch, params, onLoading, callback);
  };

  const enviaDiasLetivos = (calendario_id, onLoading, onSuccess) => {
    let datas = Object.keys(descritores.dados.diasLetivos);

    let params = {
      calendario_id,
      dias_letivos: `${datas.map((d) => d.split("/").reverse().join("-"))}`,
      total: datas.length,
    };
    CalendarioServices.enviaDiasLetivos(dispatch, params, onLoading, onSuccess);
  };

  const getInfos = (escola_id, modelos, turno, status, superintencias, si_id, fluxoStatus, tecnicos) => {
    let gre = {};
    superintencias.forEach((s) => {
      gre[s.id] = s.nome;
    });

    let nao_iniciou = {
      status_id: -1,
      status_nome: "Não iniciou",
      status_cor: "#ffffff",
      variant: "outlined",
      modelo: undefined,
      modelo_descricao: "-",
      turnos: [],
      superintendencia: gre[si_id] ? gre[si_id] : "-",
      permite_alteracao_status: false,
      tecnico_nome: '-'
    };

    if (turno) {
      let modelosDaEscola = modelos.filter((m) => m.escola_id === escola_id);
      let modelo = undefined;

      modelosDaEscola.forEach((m) => {
        if (isModeloUnico) {
          modelo = m;
        } else {
          if (m[CalendarioServices.getNameTurno(turno)] === 1) {
            modelo = m;
          }
        }
      });

      if (modelo) {
        let turnos = [];
        if (modelo.manha === 1) turnos.push("M");
        if (modelo.tarde === 1) turnos.push("T");
        if (modelo.noite === 1) turnos.push("N");
        if (modelo.integral === 1) turnos.push("I");

        let { status_atual_id: status_id } = modelo;
        let status_find = status[status_id];
        let tecnico = tecnicos.find(tecnico => tecnico.calendario_id + '' === modelo.id + '')
        return {
          status_id: status_find ? status_id : -1,
          status_nome: status_find ? status_find.descricao : "-",
          status_cor: status_find ? status_find.cor : "#fff",
          variant: "filled",
          modelo: modelo,
          modelo_descricao: `Modelo ${modelo.modelo}`,
          turnos,
          superintendencia: gre[si_id] ? gre[si_id] : "-",
          permite_alteracao_status: verificaPerfilLogadoEStatusCalendario(fluxoStatus, status_id),
          tecnico_nome: tecnico.usuario_nome || '-'
        };
      } else {
        return nao_iniciou;
      }
    } else {
      return nao_iniciou;
    }
  };
  useEffect(() => {
    if (!fluxoStatus) {
      CalendarioServices.buscaFluxoStatus(dispatch);
    }
  }, [fluxoStatus]);

  useEffect(() => {
    if (modelo_turnos && anoSelecionado) {
      setIsModeloUnico(!modelo_turnos.includes(anoSelecionado));
    }
  }, [anoSelecionado, modelo_turnos]);

  useEffect(() => {
    if (turnos?.dados) {
      let turno = turnos.dados[tabSelect];
      setTurno(turno);
    }
  }, [tabSelect, turnos?.dados]);

  useEffect(() => {
    if (escolas?.dados && modelos?.dados && superintencias?.dados && turno && fluxoStatus?.dados && tecnicos?.dados) {
      let escolasFormat = escolas.dados
        .filter((escola) => isModeloUnico || (!isModeloUnico && !!escola[turno.sigla]))
        .map((escola) => {
          let st = getInfos(escola.id, modelos.dados, turno.sigla, status.dados, superintencias.dados, escola.si_id, fluxoStatus?.dados, tecnicos?.dados);
          return { ...escola, ...st };
        });

        let escolasSuperIntendenciaOrdenada = escolasFormat.sort((a, b) =>
        a.superintendencia.localeCompare(b.superintendencia));
        setEscolasFormatadas(escolasSuperIntendenciaOrdenada)
    }
  }, [modelos?.dados, escolas?.dados, superintencias?.dados, turno, fluxoStatus?.dados, tecnicos?.dados]);

  useEffect(() => {
    if (modelo) {
      CalendarioServices.buscaDescritoresCalendario(dispatch, { calendario_modelo_id: modelo?.id });
    } else {
      dispatch(atualizaDescritoresCalendario(null));
    }
  }, [modelo]);

  const ButtonTable = ({ msg, type, children, onClick, disabled }) => {
    if (disabled) {
      return (
        <Button
          disabled
          color={type ? type : "info"}
          variant="contained"
          sx={{ minWidth: "32px", maxWidth: "32px", minHeight: "32px", maxHeight: "32px", padding: "0px 0px" }}
          onClick={onClick}
        >
          {children}
        </Button>
      );
    }

    return (
      <Tooltip title={msg}>
        <Button
          color={type ? type : "info"}
          variant="contained"
          sx={{ minWidth: "32px", maxWidth: "32px", minHeight: "32px", maxHeight: "32px", padding: "0px 0px" }}
          onClick={onClick}
        >
          {children}
        </Button>
      </Tooltip>
    );
  };

  const getTooltipTurnos = (turnos) => {
    return `Este calendário é utilizado pelo(s) turno(s):${turnos.map(
      (t) => ` ${CalendarioServices.getNameTurno(t)}`
    )}.`;
  };

  const listaTurnos = useMemo(() => {
    if (turnos && turnos.dados) {
      const ordemTurno = { M: 1, T: 2, N: 3, I: 4 };
      return [...turnos.dados].sort((a, b) =>
        ordemTurno[a.sigla] > ordemTurno[b.sigla] ? 1 : ordemTurno[a.sigla] < ordemTurno[b.sigla] ? -1 : 0
      );
    } else {
      return [];
    }
  }, [turnos]);

  let modelosByEscolas = useMemo(
    () =>
      escolasFormatadas
        .filter((e) => {
          if (greSelecionado) {
            return !!e.modelo && e.si_id === greSelecionado;
          } else {
            return !!e.modelo;
          }
        })
        .map((escola) => escola.modelo),
    [greSelecionado, escolasFormatadas]
  );

  let escolasPorStatus = useMemo(
    () =>
      statusSelecionado ? escolasFormatadas.filter((es) => es.status_id === statusSelecionado) : escolasFormatadas,
    [statusSelecionado, escolasFormatadas]
  );

  let escolasPorGre = useMemo(
    () => (greSelecionado ? escolasPorStatus.filter((escola) => escola.si_id === greSelecionado) : escolasPorStatus),
    [greSelecionado, escolasPorStatus]
  );

  let escolasPorBusca = useMemo(
    () =>
      !!escolaSearch
        ? escolasPorGre.filter((escola) =>
          StringUtils.retiraAcentos(escola.nome.toUpperCase()).includes(
            StringUtils.retiraAcentos(escolaSearch.toUpperCase())
          )
        )
        : escolasPorGre,
    [escolaSearch, escolasPorGre]
  );

  let escolasLista = [];

  if (turno) {
    escolasLista = escolasPorBusca.slice(0, limitList[`${turno.sigla}`]);
  }

  return (
    <>
      {!isModeloUnico && (
        <div className="div-turnos-select-acompanhamento">
          <FormControl fullWidth size="small">
            <TextField
              select
              value={tabSelect}
              label="Turnos"
              InputLabelProps={{ shrink: true, fontSize: "0.75rem" }}
              onChange={(e) => setTabSelect(e.target.value)}
              sx={{ background: "#fff" }}
              size="small"
              InputProps={{ style: { fontSize: "18px" } }}
            >
              {listaTurnos.map((item, i) => {
                return (
                  <MenuItem key={i} sx={{ fontSize: "18px" }} value={i}>
                    {item.descricao}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>
        </div>
      )}
      <div style={{ width: "100%", border: "1px solid #dedede", marginTop: 10 }}>
        {!isModeloUnico && (
          <Box className="div-turnos-acompanhamento" sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabSelect} onChange={(e, value) => setTabSelect(value)}>
              {listaTurnos.map((t, key) => (
                <Tab
                  style={{ width: listaTurnos.length > 0 ? 100 / listaTurnos.length + "%" : 120 }}
                  label={t.descricao}
                  key={key}
                />
              ))}
            </Tabs>
          </Box>
        )}
        {turnos && turnos.dados && turnos.dados.length > 0 && status && status.dados && modelosByEscolas && (
          <ListStatus
            isModeloUnico={isModeloUnico}
            turnoSelecionado={turnos.dados[tabSelect].sigla}
            setStatusSelecionado={setStatusSelecionado}
            statusSelecionado={statusSelecionado}
            modelos={modelosByEscolas}
            greSelecionado={greSelecionado}
          />
        )}
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 650, marginTop: 1 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 10, fontWeight: "bold" }}>#</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>{user.empresa.nome_sup_singular}</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Escola</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Cidade</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Inep</TableCell>
                {!isModeloUnico && <TableCell style={{ fontWeight: "bold" }}>Modelo</TableCell>}
                <TableCell style={{ fontWeight: "bold" }}>Técnico Responsável</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ width: 10 }}></TableCell>
                <TableCell style={{ width: 10 }}></TableCell>
                <TableCell style={{ width: 10 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {escolasLista.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5}>Nenhuma escola encontrada.</TableCell>
                </TableRow>
              )}
              {escolasLista.map((escola, key) => (
                <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell> {key + 1}</TableCell>
                  <TableCell component="th" scope="row">
                    {escola.superintendencia}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {escola.nome}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {escola.cidade}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {escola.inep}
                  </TableCell>
                  {!isModeloUnico && (
                    <TableCell component="th" scope="row">
                      <div>{escola.modelo_descricao}</div>
                      <div style={{ minWidth: 100 }}>
                        {escola.turnos.map((turno, key) => (
                          <Tooltip key={key} title={getTooltipTurnos(escola.turnos)}>
                            <div
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#1876d2",
                                marginLeft: key === 0 ? 0 : 5,
                                width: 20,
                                height: 20,
                                borderRadius: 10,
                                float: "left",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  color: "#fff",
                                  fontSize: 10,
                                }}
                              >
                                {turno}
                              </span>
                            </div>
                          </Tooltip>
                        ))}
                      </div>
                      <div style={{ clear: "both" }} />
                    </TableCell>
                  )}
                  <TableCell component="th" scope="row">
                    {escola.tecnico_nome}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Chip
                      size={"small"}
                      label={escola.status_nome}
                      style={{ backgroundColor: `${escola.status_cor}60` }}
                      variant={escola.variant}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonTable
                      disabled={!escola.permite_alteracao_status}
                      msg={"Alterar situação calendário"}
                      onClick={() => abrirModalAlterarStatus(escola?.modelo, escola?.nome)}
                      type={"secondary"}
                    >
                      <FaExchangeAlt size={18} />
                    </ButtonTable>
                  </TableCell>
                  <TableCell>
                    <ButtonTable
                      disabled={escola.status_id === -1}
                      msg={"Visualizar"}
                      onClick={() => abrirVisualizacao(escola)}
                      type={"success"}
                    >
                      <FaEye size={18} />
                    </ButtonTable>
                  </TableCell>
                  <TableCell>
                    <ButtonTable
                      disabled={escola.status_id === -1}
                      msg={"Histórico"}
                      onClick={() => abrirHistorico(escola)}
                      type={"info"}
                    >
                      <FaHistory size={18} />
                    </ButtonTable>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {turno && limitList[`${turno.sigla}`] <= escolasLista.length && (
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <Button
            onClick={() => setLimitList((l) => ({ ...l, [`${turno.sigla}`]: l[`${turno.sigla}`] + 30 }))}
            variant="contained"
            style={{ width: 200 }}
          >
            VER MAIS
          </Button>
        </div>
      )}
      <ModalHistorico
        open={!!modalHistorico}
        close={() => fecharHistorico()}
        modeloSelecionado={modalHistorico}
        escola={escola}
        modelo={modelo}
        turno={turno?.descricao}
        isModeloUnico={isModeloUnico}
      />
      {
        !!modalAlteraStatus && descritores && <ModalAlteraStatus
          open={!!modalAlteraStatus}
          close={() => fecharModalAlterarStatus()} 
          anoSelecionado={anoSelecionado} 
          user={user} 
          onSubmit={alteraSituacaoCalendario} 
          loading={loadingAlteraStatus} 
          modelo={modelo}
        />
      }
    </>
  );
};

export default ListaEscolas;
