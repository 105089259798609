import { Alert, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa'

const ListaDescritores = ({ title, lista, cor, progress, totais, msgEmpty, openModal, dataVisible }) => {

    const [percentual, setPercentual] = useState(0)

    useEffect(() => {
        if (lista && totais) {
            let countLista = lista.length
            let countTotais = totais.length
            let percentual = (((countTotais - countLista) * 100) / countTotais).toFixed(1)
            setPercentual(percentual);
        }
    }, [lista, totais])

    return (
        <div style={{ padding: 5, margin: '10px 0px' }}>
            <span style={{ fontSize: 14, fontWeight: 'bold', }}>{title}</span>
            {progress &&
                <div style={{ height: '20px', width: '100%', borderRadius: 4, backgroundColor: '#dedede', margin: '5px 0px 10px 0px', textAlign: 'center', position: 'relative' }} >
                    <span style={{ position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, textAlign: 'center', fontSize: 14 }}>{percentual}%</span>
                    <div style={{ height: '100%', width: `${percentual}%`, backgroundColor: '#0AA0EC', borderRadius: 4 }} />
                </div>
            }
            {lista.map((item, key) => {
                return (
                    <div key={key} style={{
                        backgroundColor: cor ? cor : item.cor,
                        padding: '2px 4px 2px 8px',
                        minHeight: 30,
                        borderRadius: 5,
                        marginTop: 5,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div>
                            <span style={{ color: '#fff', fontSize: 12 }} >{item.descricao}</span>
                            {dataVisible && <div style={{ color: '#fff', fontSize: 8 }} >{item.data_alteracao}</div>}
                        </div>
                        {
                            item.regras && item.regras.length > 0 &&
                            <IconButton style={{ width: 30, }} onClick={() => openModal(item)} >
                                <FaInfoCircle color='white' fontSize={15} />
                            </IconButton>
                        }
                    </div>
                )
            })}
            {!msgEmpty && lista.length === 0 &&
                <p style={{ marginTop: 5, fontSize: 14, margin: 0 }}>{'Nenhum descritor listado.'} </p>}
            {msgEmpty && lista.length === 0 &&
                <Alert severity="success">{msgEmpty}</Alert>
            }
        </div>
    )
}

export default ListaDescritores;