import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const AbortController = () => {

    const {
        descritores,
        descritoresLegendas,
        fluxoStatus,
        modelosParaImportar,
        descritoresParaImportar,
        modelos,
        turnos,
        statusGerais,
    } = useSelector(state => state.calendarioLetivo)

    useEffect(() => {
        return () => {
            try {
                if (fluxoStatus.loading) fluxoStatus.controller.abort();
            } catch (error) {

            }
        }
    }, [fluxoStatus])

    useEffect(() => {
        return () => {
            try {
                if (descritores?.loading) descritores?.controller?.abort()

            } catch (e) { }
        }
    }, [descritores])

    useEffect(() => {
        return () => {
            try {
                if (descritoresLegendas?.loading) descritoresLegendas?.controller?.abort()

            } catch (e) { }
        }
    }, [descritoresLegendas])

    useEffect(() => {
        return () => {
            try {
                if (modelosParaImportar?.loading) modelosParaImportar?.controller?.abort()

            } catch (e) { }
        }
    }, [modelosParaImportar])

    useEffect(() => {
        return () => {
            try {
                if (descritoresParaImportar?.loading) descritoresParaImportar?.controller?.abort()

            } catch (e) { }
        }
    }, [descritoresParaImportar])

    useEffect(() => {
        return () => {
            try {
                if (modelos?.loading) modelos?.controller?.abort()

            } catch (e) { }
        }
    }, [modelos])

    useEffect(() => {
        return () => {
            try {
                if (turnos?.loading) turnos?.controller?.abort()

            } catch (e) { }
        }
    }, [turnos])

    useEffect(() => {
        return () => {
            try {
                if (statusGerais?.loading) statusGerais?.controller?.abort()

            } catch (e) { }
        }
    }, [statusGerais])


    return null
}

export default AbortController;
