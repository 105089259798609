import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const HeaderPdf = ({ children, header }) => {
    return (
        <View
            fixed
            style={styles.header}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <View style={styles.viewDadosHeader}>
                    <View>
                        <Image style={styles.logo} source={header.logo} />
                    </View>
                    <View>
                        {header.entidade && <Text style={styles.textHeader} >{header.entidade}</Text>}
                        {header.orgao && <Text style={styles.textHeader}>{header.orgao}</Text>}
                        {header.dadosEscola && <Text style={styles.textHeader}>{header.dadosEscola}</Text>}
                        {header.anoSelecionado && <Text style={styles.textHeader}>ANO LETIVO: {header.anoSelecionado}</Text>}
                    </View>
                </View>
            </View>
            {children}
        </View>
    )
}

export default HeaderPdf;


const styles = StyleSheet.create({
    header: {
        padding: 8,
    },
    viewDadosHeader: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        marginTop: 6,
        alignItems: 'center'
    },
    textHeader: {
        fontWeight: 'bold',
        fontSize: 8,
        marginBottom: 2,
        fontFamily: 'Times-Roman'
    },
    logo: {
        height: 50,
        width: 35,
        marginRight: 10
    },
});

