import { USER_LOGADO } from '../../../config/GlobalKeys';
import { LOGIN } from '../../../api/ApiRoute';
import toastr from 'toastr';
import ApiRequest from '../../../api/ApiRequest';
import { loginMobi } from '../../../global/service';

export default class LoginAPI extends ApiRequest {

    static login(crendenciais, action, isProfessor = false) {
        let onSuccess = (result) => {
            if (!isProfessor) {
                localStorage.setItem(USER_LOGADO, window.btoa(JSON.stringify(result)));
                loginMobi(result);
            }
            action(result, true);
        }

        let onError = (e) => {
            action(null, false);
            if (e)
                toastr.warning(e);
            else
                toastr.warning("Não foi possível entrar no aplicativo. Tente novamente.");
        }

        return super.requestCadastro(crendenciais, LOGIN, onSuccess, onError);
    }
}