import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Alert, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import LoadingData from "../../../../components/LoadingData";
import ModalTemplate from "../../../../components/modal/ModalTemplate";
import { configCkEditor } from "../../../../global/constants";
import StringUtils from "../../../../utils/StringUtils";
import CalendarioServices from "../../CalendarioService";

const ModalAlteraStatus = ({ open, close, anoSelecionado, user, onSubmit, loading, modelo }) => {
  const [statusSelecionado, setStatusSelecionado] = useState();
  const [statusFiltrados, setStatusFiltrados] = useState([]);
  const [modeloDescricao, setModeloDescricao] = useState("");
  const [turnoDescricao, setTurnoDescricao] = useState("");
  const [escolaDescricao, setEscolaDescricao] = useState("");
  const [comentario, setComentario] = useState("");
  const [erros, setErros] = useState([]);

  const dispatch = useDispatch();

  const { statusGerais, fluxoStatus } = useSelector((state) => state.calendarioLetivo);

  useEffect(() => {
    if (!fluxoStatus) {
      CalendarioServices.buscaFluxoStatus(dispatch);
    } else if (!statusGerais) {
      CalendarioServices.buscaStatusGerais(dispatch, {});
    } else {
      if (modelo && statusGerais.dados && fluxoStatus.dados) {
        setEscolaDescricao(modelo.escola.nome);
        setModeloDescricao(modelo.descricao ? modelo.descricao : `Modelo: ${modelo.modelo}`);
        // setTurnoDescricao(CalendarioServices.getNameTurno(turno))

        let fluxosDisponiveis = fluxoStatus.dados.filter(
          (fluxo) => fluxo.perfil === user.perfil && fluxo.status_anterior_id === modelo.status_atual_id
        );

        let fluxoObj = {};
        fluxosDisponiveis.forEach((f) => {
          fluxoObj[`${f.status_novo_id}`] = true;
        });

        let stFiltrados = statusGerais.dados.filter((st) => !!fluxoObj[`${st.id}`]);
        setStatusFiltrados(stFiltrados);
      }
    }
  }, [fluxoStatus?.dados, modelo, statusGerais?.dados]);

  const renderSubTitle = () => {
    const styles = { fontSize: 14, backgroundColor: "#dedede80", padding: "4px 10px", borderRadius: 20 };
    return (
      <div style={{ marginTop: 5, marginBottom: -5 }}>
        {escolaDescricao && <span style={{ ...styles, marginRight: 5 }}>{`${escolaDescricao}`}</span>}
        {modelo && !!modelo && <span style={{ ...styles, marginRight: 5 }}>{`ANO LETIVO ${modelo.ano}`}</span>}
        {modelo && !!modelo.manha && <span style={{ ...styles, marginRight: 5 }}>{"MANHÃ"}</span>}
        {modelo && !!modelo.tarde && <span style={{ ...styles, marginRight: 5 }}>{"TARDE"}</span>}
        {modelo && !!modelo.noite && <span style={{ ...styles, marginRight: 5 }}>{"NOITE"}</span>}
        {modelo && !!modelo.integral && <span style={{ ...styles, marginRight: 5 }}>{"INTEGRAL"}</span>}
      </div>
    );
  };

  const envia = () => {
    setErros([]);
    let { diferenca, textAtt } = StringUtils.testeCaracteresEspeciais(comentario);
    if (diferenca.length > 0) {
      setErros(diferenca);
      setComentario(textAtt);
      toastr.warning("Texto com caracteres não reconhecidos");
      return;
    }
    onSubmit(modelo?.id, statusSelecionado.id, comentario, statusSelecionado.gera_dias_letivos + "" === "1");
  };

  return (
    <ModalTemplate
      title={`Alterar status do calendário`}
      open={open}
      textConfirm={"ALTERAR"}
      closeModal={close}
      subHeader={renderSubTitle()}
      desabilitarBotao={true}
      habilitarConfirmacao={true}
      onConfirm={() => envia()}
      loading={!!loading}
    >
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {fluxoStatus && <LoadingData obj={fluxoStatus} msg={"Carregando configurações..."} />}
          {statusGerais && <LoadingData obj={statusGerais} msg={"Carregando status..."} />}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Alterar situação</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statusSelecionado ? statusSelecionado : ""}
              label="Alterar situação"
              onChange={(val) => setStatusSelecionado(val.target.value)}
            >
              {statusFiltrados.map((status, key) => {
                return (
                  <MenuItem key={key} value={status}>
                    {status.descricao}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth style={{ margin: "15px 0px 0px" }}>
            <span style={{ color: "#666", fontSize: 15, margin: "0px 0px 5px 0px" }}>Insira uma justificativa:</span>
            <div style={{ width: "99%" }}>
              <CKEditor
                editor={ClassicEditor}
                data={comentario}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setComentario(data);
                }}
                config={configCkEditor}
              />
            </div>
          </FormControl>
        </Grid>
        {erros.length > 0 && (
          <Grid item md={12}>
            <Alert severity="warning">
              Os caracteres: <span style={{ fontWeight: "bold" }}>{erros.join(",")}</span> não são reconhecidos!
            </Alert>
          </Grid>
        )}
      </Grid>
    </ModalTemplate>
  );
};

export default ModalAlteraStatus;
