import React from "react";
import { FaSyncAlt } from "react-icons/fa";
import "./styles.css";

export default function SnackBar({ showReload, reloadPage, msg }) {
  return (
    <div id="snackbar" className={showReload ? "show" : ""} onClick={reloadPage}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FaSyncAlt size={18} style={{ marginRight: 5 }} />
        <div className="title">Atualização</div>
      </div>
      <div className="subtitle">Uma nova versão está disponível.</div>
      <div style={{ fontSize: 10, marginTop: 4 }}>Clique para atualizar</div>
    </div>
  );
}
