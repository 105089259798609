import { TIPOS_DESCRITORES } from "../../../../global/constants";
import Utils from "../../../../utils/Uitls";
import CalendarioServices from "../../CalendarioService";

export default class ModeloTrimestreServices {
  
  static async geraListaDiasLetivosSemestre(
    inicioSemestre,
    fimSemestre,
    diasNaoLetivos,
    diasLetivos,
    descritoresSabadosLetivo
  ) {
    return new Promise((resolve, reject) => {
      try {
        let diasLetivosSemestre = {};
        const loop = (data) => {
          let d = new Date(data);
          let nextData = new Date(d.setDate(d.getDate() + 1));

          let key_data = Utils.formatData2(data);

          //se estiver na lista de dias não letivos ignora
          if (diasNaoLetivos[key_data]) {
            if (verificaFimSemestre(nextData)) {
              resolve(diasLetivosSemestre);
            } else {
              loop(nextData);
            }
            return;
          }

          //se for sabado ou domingo e não tiver nos dias letivos
          if (
            ([0, 6].includes(data.getDay()) && !diasLetivos[key_data]) ||
            (data.getDay() === 6 && !descritoresSabadosLetivo[key_data])
          ) {
            if (verificaFimSemestre(nextData)) {
              resolve(diasLetivosSemestre);
            } else {
              loop(nextData);
            }
            return;
          }

          diasLetivosSemestre[key_data] = {
            descritor_id: null,
            cor: "#008000",
            descricao: "DIA LETIVO",
            tipo_descritor_id: null,
            dia_letivo: 1,
            dia_da_semana: data.getDay(),
          };

          if (verificaFimSemestre(nextData)) {
            resolve(diasLetivosSemestre);
          } else {
            loop(nextData);
          }
        };

        const verificaFimSemestre = (nextData) => {
          return !(nextData.getTime() <= fimSemestre.getTime());
        };

        loop(inicioSemestre);
      } catch (e) {
        resolve({});
      }
    });
  }

  static async buscaDadosSobreDiasLetivosModeloTrimestre(listaDescritores) {
    try {
      // guardam os dias letivos e não letivos
      let diasNaoLetivos = {};
      let diasLetivos = {};

      //inicializa variaveis de inicio e fim de cada semestre e bimestres
      let inicio1Semestre = undefined;
      let fim1Semestre = undefined;

      let inicio2Semestre = undefined;
      let fim2Semestre = undefined;

      let inicio1Trimestre = undefined;
      let fim1Trimestre = undefined;

      let inicio2Trimestre = undefined;
      let fim2Trimestre = undefined;

      let inicio3Trimestre = undefined;
      let fim3Trimestre = undefined;

      let descritoresSabadoLetivo = {};

      Object.keys(listaDescritores).forEach((data) => {
        listaDescritores[data].forEach((descritor) => {
          //adiciona datas com dias letivos ou nao letivos
          if (descritor.dia_letivo === 2) {
            diasNaoLetivos[`${data}`] = true;
          } else if (descritor.dia_letivo === 1) {
            diasLetivos[`${data}`] = true;
          }
        });

        listaDescritores[data]
          .filter((d) => d.dia_letivo === 0)
          .forEach((descritor) => {
            let dia = CalendarioServices.getDateByDataSTR(descritor.data_inicio).getDay();
            //verifica se não é sabado ou domingo, e se não está nos dias nao letivos.
            if (![0, 6].includes(dia) && !diasNaoLetivos[`${data}`]) {
              diasLetivos[`${data}`] = true;
            }
          });

        listaDescritores[data].forEach((descritor) => {
          //busca datas de inicio e fim dos semestres
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_SEMESTRE_1) {
            inicio1Semestre = CalendarioServices.getDateByDataSTR(data);
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_SEMESTRE_1) {
            fim1Semestre = CalendarioServices.getDateByDataSTR(data);
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_SEMESTRE_2) {
            inicio2Semestre = CalendarioServices.getDateByDataSTR(data);
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_SEMESTRE_2) {
            fim2Semestre = CalendarioServices.getDateByDataSTR(data);
          }

          //busca datas de inicio e fim dos trimestres
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_TRIMESTRE_1) {
            inicio1Trimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_TRIMESTRE_1) {
            fim1Trimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_TRIMESTRE_2) {
            inicio2Trimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_TRIMESTRE_2) {
            fim2Trimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_TRIMESTRE_3) {
            inicio3Trimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_TRIMESTRE_3) {
            fim3Trimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.SABADO_LETIVO) {
            descritoresSabadoLetivo[data] = true;
          }
        });
      });

      let diasLetivosSemestre1 = {};
      let diasLetivosSemestre2 = {};

      let diasLetivosTrimestre1 = {};
      let diasLetivosTrimestre2 = {};
      let diasLetivosTrimestre3 = {};

      if (inicio1Semestre && fim1Semestre) {
        diasLetivosSemestre1 = await CalendarioServices.geraListaDiasLetivosSemestre(
          inicio1Semestre,
          fim1Semestre,
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      if (inicio2Semestre && fim2Semestre) {
        diasLetivosSemestre2 = await CalendarioServices.geraListaDiasLetivosSemestre(
          inicio2Semestre,
          fim2Semestre,
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      if (inicio1Trimestre && fim1Trimestre) {
        diasLetivosTrimestre1 =  await CalendarioServices.geraListaDiasLetivosSemestre(
          CalendarioServices.getDateByDataSTR(inicio1Trimestre),
          CalendarioServices.getDateByDataSTR(fim1Trimestre),
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      if (inicio2Trimestre && fim2Trimestre) {
        diasLetivosTrimestre2 =  await CalendarioServices.geraListaDiasLetivosSemestre(
          CalendarioServices.getDateByDataSTR(inicio2Trimestre),
          CalendarioServices.getDateByDataSTR(fim2Trimestre),
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );

        /**
         * TO-DO refatorar urgentemente a forma de extrair os dias não letivos 
         * que ficam dentre o periodo (fim do 1º semestre e inicio do 2º semestre) 
         * para corrigir a contagem de dias letivos do 2 trimestre
         * */ 
        if (fim1Semestre && inicio2Semestre) {
          // busca os dias letivos que estão entre o periodo (fim do 1º semestre e inicio do 2º semestre)
          let dias_nao_pertencentes_trimestre = await CalendarioServices.geraListaDiasLetivosSemestre(
            fim1Semestre,
            inicio2Semestre,
            diasNaoLetivos,
            diasLetivos,
            descritoresSabadoLetivo
          );
          if (Object.values(dias_nao_pertencentes_trimestre).length > 0) {
            let lista_corrigida_trimestre_2 = { ...diasLetivosTrimestre2 }
            
            // criam-se copias que guardam os valores do periodo (fim do 1º semestre e inicio do 2º semestre) pois eles retornam na lista
            let fim_1_semestre = dias_nao_pertencentes_trimestre[fim1Semestre];
            let inicio_2_semestre = dias_nao_pertencentes_trimestre[inicio2Semestre]
            
            // remove da lista principal relacionada aos dias letivos do trimestre esses dias que não devem ser contados, juntamente com os de periodo (fim do 1º semestre e inicio do 2º semestre)
            Object.keys(dias_nao_pertencentes_trimestre).forEach(item => {
              delete lista_corrigida_trimestre_2[item];
            })

            // recolocam-se os itens de periodo (fim do 1º semestre e inicio do 2º semestre) que foram removidos dentro da lista de dias letivos do trimestre novamente
            diasLetivosTrimestre2 = {...lista_corrigida_trimestre_2, [fim1Semestre]: fim_1_semestre, [inicio2Semestre]: inicio_2_semestre };
          }
        }
      }

      if (inicio3Trimestre && fim3Trimestre) {
        diasLetivosTrimestre3 =  await CalendarioServices.geraListaDiasLetivosSemestre(
          CalendarioServices.getDateByDataSTR(inicio3Trimestre),
          CalendarioServices.getDateByDataSTR(fim3Trimestre),
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      return {
        diasLetivos: {
          ...diasLetivosSemestre1,
          ...diasLetivosSemestre2,
        },
        countDiasLetivos1semestre: Object.keys(diasLetivosSemestre1).length,
        countDiasLetivos2semestre: Object.keys(diasLetivosSemestre2).length,
        countDiasLetivos1Trimestre: Object.keys(diasLetivosTrimestre1).length,
        countDiasLetivos2Trimestre: Object.keys(diasLetivosTrimestre2).length,
        countDiasLetivos3Trimestre: Object.keys(diasLetivosTrimestre3).length,
        inicio1Trimestre,
        fim1Trimestre,
        inicio2Trimestre,
        fim2Trimestre,
        inicio3Trimestre,
        fim3Trimestre
      };
    } catch (e) {
      return {};
    }
  }
}