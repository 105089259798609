import { Box, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import LoadingData from "../../../components/LoadingData";

const LegendaDescritores = () => {

    const { descritoresLegendas } = useSelector(state => state.calendarioLetivo)

    return (
        <Box>
            {descritoresLegendas &&
                <div style={{ marginTop: 10 }}>
                    <LoadingData obj={descritoresLegendas} msg={'Carregando legenda...'} />
                </div>
            }
            {
                descritoresLegendas && descritoresLegendas.dados &&
                <p style={{ fontSize: 20, marginTop: 20, marginBottom: 5 }} >Legendas:</p>
            }
            <Grid container style={{ marginBottom: 50 }} >
                {
                    descritoresLegendas &&
                    descritoresLegendas.dados &&
                    descritoresLegendas.dados.listaFilter.map((dado, key) => {
                        return (
                            <Grid key={key} item md={6} xs={12}  >
                                <div style={{
                                    border: `1px solid #dedede`,
                                    borderRadius: 5,
                                    padding: 5,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#fff'
                                }}>
                                    <div style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 5,
                                        backgroundColor: dado.cor
                                    }} />
                                    <p style={{
                                        marginBottom: 0,
                                        fontSize: 12,
                                        marginLeft: 5
                                    }}>
                                        {dado.descricao}
                                    </p>
                                </div>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

export default LegendaDescritores