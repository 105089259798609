import { Button, Card, Grid, } from '@mui/material';
import React, { useEffect, useRef, useState, } from 'react';
import ModalTemplate from '../../../../components/modal/ModalTemplate';
import CalendarioAcompanhamentoServices from '../../../calendario-acompanhamento/CalendarioAcompanhamentoServices';
import { useDispatch, useSelector } from 'react-redux';
import LoadingData from '../../../../components/LoadingData';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from '@mui/lab';

const ModalHistorico = ({ open, close, modeloSelecionado, modelo, escola, isModeloUnico }) => {

    const dispatch = useDispatch()

    const itemsRef = useRef([]);
    const [verMais, setVerMais] = useState({})

    const { historico } = useSelector(state => state.acompanhamento)

    useEffect(() => {
        if (modeloSelecionado) {
            CalendarioAcompanhamentoServices.buscaHistoricoById(dispatch, { calendario_id: modeloSelecionado })
        }
    }, [modeloSelecionado])

    useEffect(() => {
        if (historico?.dados && historico?.dados.length > 0) {
            let itens = itemsRef.current.slice(0, historico.dados.length);
            let obj = {}
            itens.forEach((value, key) => {
                if (value) {
                    let height = value.clientHeight
                    if (height === 200) {
                        obj[key] = 200
                    }
                }
            })

            setVerMais(obj)
        }
    }, [historico?.dados])


    const renderSubTitle = () => {
        const styles = { fontSize: 14, backgroundColor: '#dedede80', padding: '4px 10px', borderRadius: 20, marginRight: 5 }
        return (
            <div style={{ marginTop: 5, marginBottom: -5 }}>
                {escola && <span style={{ ...styles }}>{escola}</span>}
                {!isModeloUnico && modelo && <span style={{ ...styles }}>{`MODELO ${modelo.modelo}`}</span>}
                {modelo && !!modelo.manha && <span style={{ ...styles }}>{'MANHÃ'}</span>}
                {modelo && !!modelo.tarde && <span style={{ ...styles }}>{'TARDE'}</span>}
                {modelo && !!modelo.noite && <span style={{ ...styles }}>{'NOITE'}</span>}
                {modelo && !!modelo.integral && <span style={{ ...styles }}>{'INTEGRAL'}</span>}
            </div>
        )
    }

    return (
        <ModalTemplate
            title={`Histórico de atualizações`}
            subHeader={renderSubTitle()}
            open={open}
            closeModal={close}
            desabilitarBotao={false}
            loading={!!historico?.loading}>
            <Grid container spacing={2} style={{ width: '100%', margin: 0, }} >
                <Grid item md={12} style={{ padding: 0 }} >
                    {historico && <LoadingData obj={historico} msg="Buscando histórico" />}
                    {historico && !historico.loading && historico.dados &&
                        <Timeline
                            style={{ backgroundColor: '#dedede90' }}
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}>
                            {historico.dados.map((item, key) => {
                                return (
                                    <TimelineItem key={key}>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Card style={{ padding: 10 }} >
                                                <div style={{ marginBottom: 10 }}>
                                                    <span style={{ fontWeight: '200', fontSize: 16, padding: '4px 5px', backgroundColor: `${item.status.cor}60`, borderRadius: 5 }} >
                                                        {item.status.descricao} por {item.usuario_nome} ({item.data_criacao})
                                                    </span>
                                                </div>
                                                {item.comentario &&
                                                    <>
                                                        <div
                                                            ref={ref => itemsRef.current[key] = ref}
                                                            style={{ maxHeight: verMais[key] ? verMais[key] : 200 }}
                                                            dangerouslySetInnerHTML={{ __html: item.comentario }} />
                                                        {!!verMais[key] &&
                                                            <Button
                                                                style={{ backgroundColor: '#fff' }}
                                                                onClick={() => setVerMais(item => ({ ...item, [key]: verMais[key] === 200 ? 'none' : 200 }))}>
                                                                {verMais[key] === 200 ? 'Ver mais' : 'Ver menos'}
                                                            </Button>
                                                        }
                                                    </>
                                                }
                                            </Card>
                                        </TimelineContent>
                                    </TimelineItem>
                                )
                            })}
                        </Timeline>
                    }
                    {historico && !historico.loading && historico.dados && historico.dados.length === 0 &&
                        <p>Nenhum histórico encontrado.</p>
                    }
                </Grid>
            </Grid>
        </ModalTemplate >
    )
}

export default ModalHistorico;