import ApiRequest from "../../../api/ApiRequest";
import * as ApiRoute from "../../../api/ApiRoute";
import { listaStatus, listaTurnos, listaEscolas, listaModelos, alteraModelo, listaHistorico, listaSuperintendencias, listaTecnicos } from '../reducer'


export default class CalendarioAcompanhamentoAPI extends ApiRequest {
    static buscaTurnosGerais(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_TURNOS

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params, limit: 0 })
            }

            const callback = async (obj) => {
                if (obj.dados) {
                    let dados = obj.dados.map(d => ({ descricao: d.turno_descricao, sigla: d.turno_sigla, total: d.total }))
                    obj.dados = dados
                }
                dispatch(listaTurnos(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar os turnos!')
        }
    }

    static buscaStatusGerais(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_ALL_STATUS_CALENDARIO

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params, limit: 0 })
            }

            const callback = async (obj) => {
                if (obj.dados) {
                    let dados = {}
                    obj.dados.forEach(st => {
                        dados[st.id] = {
                            descricao: st.descricao,
                            cor: st.cor,
                        }
                    });
                    obj.dados = dados
                }
                dispatch(listaStatus(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar os status do calendário!')
        }
    }


    static buscarTecnicosGerais(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_ALL_MODELOS_TECNICOS

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params })
            }

            const callback = async (obj) => {
                dispatch(listaTecnicos(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar os técnicos!')
        }
    }
    static buscaEscolasDaRede(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_ESCOLAS

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params, inc_turnos: 1, use_loja: 1, limit: 0, status: 1, inc_turnos_real: 1, categoria: 1 })
            }

            const callback = async (obj) => {
                if (obj.dados) {
                    let dados = obj.dados.map(escola => {
                        let I = 0
                        let M = 0
                        let T = 0
                        let N = 0

                        escola.turnos.forEach(t => {
                            if (t) {
                                if (t === 'I') I = 1
                                if (t === 'M') M = 1
                                if (t === 'T') T = 1
                                if (t === 'N') N = 1
                            }
                        })

                        return ({ ...escola, I, M, T, N })
                    })
                    obj.dados = dados
                }
                dispatch(listaEscolas(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar as escolas!')
        }
    }

    static buscaCalendarios(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_MODELOS_CALENDARIO

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params, limit: 0 })
            }

            const callback = async (obj) => {
                dispatch(listaModelos(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar os calendários!')
        }
    }

    static buscaDadosCalendarioById(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_MODELO_BY_ID

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params })
            }

            const callback = async (obj) => {
                dispatch(alteraModelo(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar o calendário!')
        }
    }

    static buscaHistoricoById(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_HISTORICO_BY_MODELO_ID

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params })
            }

            const callback = async (obj) => {
                let dados = []
                if (obj.dados && obj.dados.length > 0) {
                    dados = obj.dados.sort((a, b) => a.id < b.id ? 1 : -1)
                }

                dispatch(listaHistorico({ ...obj, dados }))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar o histórico do calendário!')
        }
    }

    static buscaSuperintencias(dados, ignoreCache, params) {
        return dispatch => {
            let url = ApiRoute.GET_ALL_SUPERINTENDENCIAS

            let options = {
                method: 'POST',
                body: JSON.stringify({ ...params })
            }

            const callback = async (obj) => {
                dispatch(listaSuperintendencias(obj))
            }

            return super.requestAuth(url, options, dados, callback, 'Ops! Não foi possível listar as superintendências!')
        }
    }
}
