import RelatorioGreAPI from "./api/RelatorioGreAPI"
import Service from "../../api/Service"

export default class RelatorioGreService {
    static buscaRelatorioGres(dispatch, params){
        Service.factoryRequest(dispatch, RelatorioGreAPI.buscaRelatorioGres, true, params);
    }
    
    static buscaSuperintencias(dispatch, params){
        Service.factoryRequest(dispatch, RelatorioGreAPI.buscaSuperintencias, true, params);
    }

    static buscaEscolasDaRede(dispatch, params){
        Service.factoryRequest(dispatch, RelatorioGreAPI.buscaEscolasDaRede, true, params);
    }
}