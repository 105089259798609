import NavBarMobi from "@renatomobi/js-navbar-mobi/NavBarMobi";
import { AppKey } from "../../config/GlobalKeys";

export default class TemplateService {
    static preparaNavBar() {
        let options = new NavBarMobi();
        options.setAppkey(AppKey);
        options.setCorBackground("#00bfa5");
        options.setCorTexto("#ffffff");
        options.setCorIcons("#ffffff");
        options.setUrlLogo("/imgs/logo.png");
        options.setNomeApp("Calendário letivo");

        return options;
    }
}