import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { FormControl, MenuItem, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { atualizaAnoSelecionadoGlobal } from "../../global/reducer";
import HeaderServices from "./HeaderServices";
import LoadingData from "../LoadingData";

const HeaderGlobal = ({
  titulo,
  colorTitulo,
  espacamentoTituloSubtitulo,
  subtitulo,
  colorSubtitulo,
  botaoVoltar,
  semQuebra,
  isDisableFiltros,
  onChange,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.global.user);
  const anoSelecionado = useSelector((state) => state.global.anoSelecionado);
  const all_anos = useSelector((state) => state.global.anos);

  useEffect(() => {
    if (user && !anoSelecionado) {
      dispatch(atualizaAnoSelecionadoGlobal(user.ano_base));
    }
  }, [user]);

  useEffect(() => {
    if (!all_anos) {
      HeaderServices.buscaListaAnos(dispatch, {}, true);
    }
  }, [all_anos]);

  const changeAno = (event) => {
    dispatch(atualizaAnoSelecionadoGlobal(event.target.value));
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <>
      {all_anos && <LoadingData obj={all_anos} msg="Buscando dados de anos..." />}
      {all_anos && all_anos.dados && (
        <Card sx={{ padding: 2, paddingLeft: 3, marginBottom: 2 }}>
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item style={{ display: "flex" }} xs={semQuebra ? false : 12} md={6}>
              {!!botaoVoltar && <div style={{ marginRight: 10 }}>{botaoVoltar}</div>}
              <div>
                <Typography
                  sx={{ fontSize: { xs: 22, sm: 24, md: 26 }, fontWeight: "600" }}
                  color={colorTitulo}
                  gutterBottom={espacamentoTituloSubtitulo}
                >
                  {titulo}
                </Typography>
                <Typography sx={{ fontSize: 18 }} color={colorSubtitulo}>
                  {subtitulo}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <TextField
                  select
                  disabled={isDisableFiltros}
                  value={anoSelecionado}
                  label="Ano"
                  InputLabelProps={{ shrink: true, fontSize: "0.75rem" }}
                  onChange={changeAno}
                  sx={{ background: "#fff" }}
                  size="small"
                  InputProps={{ style: { fontSize: "18px" } }}
                >
                  {all_anos.dados.map((item, i) => {
                    return (
                      <MenuItem key={i} sx={{ fontSize: "18px" }} value={item.ano}>
                        {item.ano}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default HeaderGlobal;
