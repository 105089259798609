import { caracAceitos } from "../global/constants";

export default class StringUtils {
  static retiraAcentos(str) {
    if (str === null) return "";
    if (str) {
      let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
      let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
      let novastr = "";
      for (let i = 0; i < str.length; i++) {
        let troca = false;
        for (let a = 0; a < com_acento.length; a++) {
          if (str.substr(i, 1) === com_acento.substr(a, 1)) {
            novastr += sem_acento.substr(a, 1);
            troca = true;
            break;
          }
        }
        if (troca === false) {
          novastr += str.substr(i, 1);
        }
      }
      return novastr;
    }
    return str;
  }
  static validaUrl(url) {
    return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(url);
  }
  static validaUrlYoutube(url) {
    return /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.test(
      url
    );
  }

  static testeCaracteresEspeciais(text) {
    let regex = /[^\w\s]/g; // Expressão regular para encontrar todos os caracteres especiais
    let caracteresEspeciais = text.match(regex); // Encontra todos os caracteres especiais no texto
    caracteresEspeciais = [...new Set(caracteresEspeciais)].sort(); // Remove caracteres duplicados e ordena a lista
    let diferenca = this.diferencaArrays(caracAceitos, caracteresEspeciais); // gera lista com os caracteres nao reconhecidos
    let textAtt = "";

    if (diferenca.length) {
      textAtt = text;
      diferenca.forEach((item) => {
        textAtt = textAtt.replaceAll(item, `<em><del><strong>${item}</strong></del></em>`);
      });
    }
    return { diferenca, textAtt };
  }

  static diferencaArrays(a, b) {
    return b.filter((elemento) => !a.includes(elemento));
  }
}
