
export default class Utils {

  static checkObjectRequest(obj) {
    if (obj && obj.dados) return true;
    return false;
  }

  static checkObjectRequestNoData(obj) {
    if (obj && !obj.dados) return true;
    return false;
  }


  static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  static formatNumber2(number) {
    return Math.max(0, number).toFixed(0).replace(/(?=(?:\d{3})+$)(?!^)/g, '.');
  }

  static calc_percent = (amostra, total, floor = false, fixed = 1) => {
    if (typeof amostra !== 'undefined' && !isNaN(amostra) && typeof total !== 'undefined' && parseInt(total) !== 0) {
      let res = (amostra / total) * 100

      return floor ? Math.floor(res) : parseFloat(res).toFixed(fixed);

    } else {
      return (0).toFixed(fixed);
    }
  }

  static dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = window.atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  static separaNumeroDDD(telefone) {

    let tel_partes = telefone.split(' ');
    let ddd = tel_partes[0].replace('(', '').replace(')', '');
    let rest_num = tel_partes[1] + tel_partes[2].replace('-', '');

    return [ddd, rest_num];
  }

  static validaEmail(email) {
    //eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  static formatData(dt) {
    try {
      return dt.split('T')[0].split('-').reverse().join('/');
    } catch (e) {
      return dt;
    }
  }
  static formata_data(str_date, format) {

    if (typeof str_date == 'undefined' || str_date == null) {
      return '';
    }
    var short_meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    var full_meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    var date = typeof str_date != 'undefined' ? new Date(str_date) : new Date(),
      day = date.getDate(),
      month = date.getMonth() + 1,
      year = date.getFullYear(),
      hours = date.getHours(),
      minutes = date.getMinutes(),
      seconds = date.getSeconds();

    if (!format) {
      format = "dd/MM/yyyy";
    }

    var s_short_meses = /\b(MMM)\b/g,
      s_full_meses = /\b(MMMM)\b/g;

    if (format.match(s_short_meses) != null) {
      format = format.replace("MMM", short_meses[date.getMonth()]);
    } else if (format.match(s_full_meses)) {
      format = format.replace("MMMM", full_meses[date.getMonth()]);
    } else {
      format = format.replace("MM", month.toString().replace(/^(\d)$/, '0$1'));
    }

    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }

    format = format.replace("dd", day.toString().replace(/^(\d)$/, '0$1'));

    if (format.indexOf("t") > -1) {
      if (hours > 11) {
        format = format.replace("t", "pm");
      } else {
        format = format.replace("t", "am");
      }
    }

    if (format.indexOf("HH") > -1) {
      format = format.replace("HH", hours.toString().replace(/^(\d)$/, '0$1'));
    }

    if (format.indexOf("hh") > -1) {
      if (hours > 12) {
        hours -= 12;
      }

      if (hours === 0) {
        hours = 12;
      }
      format = format.replace("hh", hours.toString().replace(/^(\d)$/, '0$1'));
    }

    if (format.indexOf("mm") > -1) {
      format = format.replace("mm", minutes.toString().replace(/^(\d)$/, '0$1'));
    }

    if (format.indexOf("ss") > -1) {
      format = format.replace("ss", seconds.toString().replace(/^(\d)$/, '0$1'));
    }
    return format;
  }
  static randomColor() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  };

  static completaZero(n) {
    if (n < 10) {
      return '0' + n;
    }
    return n;
  }

  static formatData2(date) {
    try {
      return Utils.completaZero(date.getDate()) + "/" + Utils.completaZero(date.getMonth() + 1) + "/" + date.getFullYear();
    } catch (e) {
      return '';
    }

  }

  static formatDataString(date) {
    let date_partes = date.split('-');
    return date_partes[2] + '/' + date_partes[1] + '/' + date_partes[0];

  }

  static formatData3(d) {
    try {
      let month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    } catch (e) {
      return '';
    }
  }

  static formatData4(data) {
    let date = new Date(data)
    if (date.toString() !== 'Invalid Date') {
      return this.formata_data(date, 'yyyy-dd-MM')
    } else {
      let aux = data.split('/')
      let dataFormatada = aux[2] + '-' + aux[1] + '-' + aux[0]
      return dataFormatada
    }
  }


  static validarIntervalo(inicioHoras, inicioMinutos, finalHoras, finalMinutos) {
    if (inicioHoras > finalHoras) {
      return false;
    } else if ((inicioHoras === finalHoras) && (inicioMinutos >= finalMinutos)) {
      return false;
    }
    return true;
  }

  static checarDatasMobifamilia(data_app, data_correc, data_ini_mb, data_final_mb) {
    var data_1 = new Date(data_app);
    var data_2 = new Date(data_correc);
    var data_3 = new Date(data_ini_mb);
    var data_4 = new Date(data_final_mb);
    if ((data_1 > data_3) || (data_2 < data_4)) {
      return false;
    } else {
      return true
    }
  }

  static checarDatas(data_app, data_correc) {
    var data_1 = new Date(data_app);
    var data_2 = new Date(data_correc);
    if (data_1 > data_2) {
      return false;
    } else {
      return true
    }
  }

  static checarDatasPosterior(data_app, data_correc) {
    var data_1 = new Date(data_app);
    var data_2 = new Date(data_correc);
    if (data_1 < data_2) {
      return false;
    } else {
      return true
    }
  }

  static exportCsv(list, header, fileName) {

    var csv = header.join(';') + '\n';

    list.forEach(function (row) {
      let arr = [];
      for (let key in row) {
        let v = row[key] + '';
        while (v.includes('\r\n')) {
          v = v.replace("\r\n", '');
        }
        arr.push(v);
      }
      csv += arr.join(';');
      csv += "\n";
    });

    //console.log(csv);
    var hiddenElement = document.createElement('a');
    hiddenElement.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv)
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName + '.csv';
    hiddenElement.click();

  }



}