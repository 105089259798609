export const LISTA_FERIADOS = {
    "01/01/2023": [{
      "data": "01/01/2023",
      "nome": "Ano Novo",
      "tipo": "NACIONAL",
      "descricao": "O Ano-Novo ou Réveillon é um evento que acontece quando uma cultura celebra o fim de um ano e o começo do próximo. A celebração do evento é também chamada Réveillon, termo oriundo do verbo francês réveiller, que em português significa DESPERTAR",
      "uf": "",
      "municipio": ""
    }],
    "20/02/2023": [{
      "data": "20/02/2023",
      "nome": "Carnaval",
      "tipo": "FACULTATIVO",
      "descricao": "Ponto Facultativo, ou seja, cabe às empresas e orgão públicos decidirem se trabalharão ou não.",
      "uf": "",
      "municipio": ""
    }],
    "21/02/2023": [{
      "data": "21/02/2023",
      "nome": "Carnaval",
      "tipo": "FACULTATIVO",
      "descricao": "Carnaval NÃO é um feriado oficial, é Ponto Facultativo, ou seja, cabe às empresas e orgão públicos decidirem se trabalharão ou não.",
      "uf": "",
      "municipio": ""
    }],
    "07/04/2023": [{
      "data": "07/04/2023",
      "nome": "Sexta-Feira Santa",
      "tipo": "NACIONAL",
      "descricao": "Também chamada de \"Sexta Feira da Paixão\" é a sexta-feira que ocorre antes do domingo de Páscoa, e é o dia que os cristãos lembram da crucificação de Cristo.",
      "uf": "",
      "municipio": ""
    }],
    "21/04/2023": [{
      "data": "21/04/2023",
      "nome": "Dia de Tiradentes",
      "tipo": "NACIONAL",
      "descricao": "Joaquim José da Silva Xavier, o Tiradentes foi um dentista, tropeiro, minerador, comerciante, militar e ativista político. É reconhecido como mártir da Inconfidência Mineira e herói nacional. O dia de sua execução, 21 de abril, é feriado nacional.",
      "uf": "",
      "municipio": ""
    }],
    "01/05/2023": [{
      "data": "01/05/2023",
      "nome": "Dia do Trabalho",
      "tipo": "NACIONAL",
      "descricao": "O Dia do Trabalhador ou Dia Internacional dos Trabalhadores é celebrado anualmente no dia 1º de Maio em numerosos países do mundo, sendo feriado no Brasil, em Portugal e em outros países.",
      "uf": "",
      "municipio": ""
    }],
    "08/06/2023": [{
      "data": "08/06/2023",
      "nome": "Corpus Christi",
      "tipo": "FACULTATIVO",
      "descricao": "Ponto Facultativo no País, mas Feriado Municipal em algumas cidades.. Corpus Christi, expressão latina que significa Corpo de Cristo, é uma festa Cristã realizada na quinta-feira seguinte ao domingo da Santíssima Trindade.",
      "uf": "",
      "municipio": ""
    }],
    "16/08/2023": [{
      "data": "16/08/2023",
      "nome": "Aniversário de Teresina",
      "tipo": "MUNICIPAL",
      "descricao": "conforme Lei nº 2.847 de 22 de novembro de 1999",
      "uf": "PI",
      "municipio": "Teresina"
    }],
    "07/09/2023": [{
      "data": "07/09/2023",
      "nome": "Independência do Brasil",
      "tipo": "NACIONAL",
      "descricao": "O Dia da Indepência do Brasil, oficialmente a data comemorada é a de 7 de setembro de 1822, em que ocorreu o chamado \"Grito do Ipiranga\".",
      "uf": "",
      "municipio": ""
    }],
    "12/10/2023": [{
      "data": "12/10/2023",
      "nome": "Nossa Senhora Aparecida",
      "tipo": "NACIONAL",
      "descricao": "Nossa Senhora da Conceição Aparecida é a padroeira do Brasil. Sua festa é celebrada em 12 de outubro, um feriado nacional desde que o Papa João Paulo II consagrou a Basílica em 1980.",
      "uf": "",
      "municipio": ""
    }],
    "15/10/2023": [{
      "data": "15/10/2023",
      "nome": "Dia do Professor",
      "tipo": "FACULTATIVO",
      "descricao": "FERIADO ESCOLAR - No dia 15 de outubro de 1827 (dia consagrado à educadora Santa Teresa de Ávila), o Imperador do Brasil, Pedro I, baixou um Decreto Imperial que criou o Ensino Elementar no Brasil. Pelo decreto, \"todas as cidades, vilas e lugarejos teriam suas escolas de primeiras letras\"",
      "uf": "",
      "municipio": ""
    }],
    "19/10/2023": [{
      "data": "19/10/2023",
      "nome": "Dia do Piauí",
      "tipo": "ESTADUAL",
      "descricao": "Independência da província do Piauí da coroa portuguesa, que ocorreu na cidade de Parnaiba. Feriado Estadual de acordo com a Lei Estadual nº 176, de 30.08.1937.",
      "uf": "PI",
      "municipio": ""
    }],
    "28/10/2023": [{
      "data": "28/10/2023",
      "nome": "Dia do Servidor Público",
      "tipo": "FACULTATIVO",
      "descricao": "O dia do servidor público é feriado apenas para funcionários públicos.",
      "uf": "",
      "municipio": ""
    }],
    "02/11/2023": [{
      "data": "02/11/2023",
      "nome": "Dia de Finados",
      "tipo": "NACIONAL",
      "descricao": "O Dia de Finados ou Dia dos Fiéis Defuntos, (conhecido ainda como Dia dos Mortos no México), é celebrado pela Igreja Católica no dia 2 de novembro e é Feriado Nacional.",
      "uf": "",
      "municipio": ""
    }],
    "15/11/2023": [{
      "data": "15/11/2023",
      "nome": "Proclamação da República",
      "tipo": "NACIONAL",
      "descricao": "A Proclamação da República foi um levante político-militar ocorrido em 15 de novembro de 1889 que instaurou a forma Republicana Federativa Presidencialista de governo no Brasil, derrubando a Monarquia e, por conseguinte, o imperador dom Pedro II.",
      "uf": "",
      "municipio": ""
    }],
    "08/12/2023": [{
      "data": "08/12/2023",
      "nome": "N. Sra. da Conceição",
      "tipo": "FACULTATIVO",
      "descricao": "Nossa Senhora da Conceição",
      "uf": "",
      "municipio": ""
    }],
    "25/12/2023": [{
      "data": "25/12/2023",
      "nome": "Natal",
      "tipo": "NACIONAL",
      "descricao": "O Natal é comemorado anualmente em 25 de Dezembro. Originalmente destinado a celebrar o nascimento anual do Deus Sol no solstício de inverno, foi adaptado pela Igreja Católica no 3o século d.C., para permitir a conversão dos povos pagãos sob o domínio do Império Romano, passando a comemorar o nascimento de Jesus de Nazaré.",
      "uf": "",
      "municipio": ""
    }],
    "01/01/2024": [{
      "data": "01/01/2024",
      "nome": "Ano Novo",
      "tipo": "NACIONAL",
      "descricao": "O Ano-Novo ou Réveillon é um evento que acontece quando uma cultura celebra o fim de um ano e o começo do próximo. A celebração do evento é também chamada Réveillon, termo oriundo do verbo francês réveiller, que em português significa DESPERTAR",
      "uf": "",
      "municipio": ""
    }],
    "12/02/2024": [{
      "data": "12/02/2024",
      "nome": "Carnaval",
      "tipo": "FACULTATIVO",
      "descricao": "Ponto Facultativo, ou seja, cabe às empresas e orgão públicos decidirem se trabalharão ou não.",
      "uf": "",
      "municipio": ""
    }],
    "13/02/2024": [{
      "data": "13/02/2024",
      "nome": "Carnaval",
      "tipo": "FACULTATIVO",
      "descricao": "Carnaval NÃO é um feriado oficial, é Ponto Facultativo, ou seja, cabe às empresas e orgão públicos decidirem se trabalharão ou não.",
      "uf": "",
      "municipio": ""
    }],
    "29/03/2024": [{
      "data": "29/03/2024",
      "nome": "Sexta-Feira Santa",
      "tipo": "NACIONAL",
      "descricao": "Também chamada de \"Sexta Feira da Paixão\" é a sexta-feira que ocorre antes do domingo de Páscoa, e é o dia que os cristãos lembram da crucificação de Cristo.",
      "uf": "",
      "municipio": ""
    }],
    "21/04/2024": [{
      "data": "21/04/2024",
      "nome": "Dia de Tiradentes",
      "tipo": "NACIONAL",
      "descricao": "Joaquim José da Silva Xavier, o Tiradentes foi um dentista, tropeiro, minerador, comerciante, militar e ativista político. É reconhecido como mártir da Inconfidência Mineira e herói nacional. O dia de sua execução, 21 de abril, é feriado nacional.",
      "uf": "",
      "municipio": ""
    }],
    "01/05/2024": [{
      "data": "01/05/2024",
      "nome": "Dia do Trabalho",
      "tipo": "NACIONAL",
      "descricao": "O Dia do Trabalhador ou Dia Internacional dos Trabalhadores é celebrado anualmente no dia 1º de Maio em numerosos países do mundo, sendo feriado no Brasil, em Portugal e em outros países.",
      "uf": "",
      "municipio": ""
    }],
    "30/05/2024": [{
      "data": "30/05/2024",
      "nome": "Corpus Christi",
      "tipo": "FACULTATIVO",
      "descricao": "Ponto Facultativo no País, mas Feriado Municipal em algumas cidades.. Corpus Christi, expressão latina que significa Corpo de Cristo, é uma festa Cristã realizada na quinta-feira seguinte ao domingo da Santíssima Trindade.",
      "uf": "",
      "municipio": ""
    }],
    "16/08/2024": [{
      "data": "16/08/2024",
      "nome": "Aniversário de Teresina",
      "tipo": "MUNICIPAL",
      "descricao": "conforme Lei nº 2.847 de 22 de novembro de 1999",
      "uf": "PI",
      "municipio": "Teresina"
    }],
    "07/09/2024": [{
      "data": "07/09/2024",
      "nome": "Independência do Brasil",
      "tipo": "NACIONAL",
      "descricao": "O Dia da Indepência do Brasil, oficialmente a data comemorada é a de 7 de setembro de 1822, em que ocorreu o chamado \"Grito do Ipiranga\".",
      "uf": "",
      "municipio": ""
    }],
    "12/10/2024": [{
      "data": "12/10/2024",
      "nome": "Nossa Senhora Aparecida",
      "tipo": "NACIONAL",
      "descricao": "Nossa Senhora da Conceição Aparecida é a padroeira do Brasil. Sua festa é celebrada em 12 de outubro, um feriado nacional desde que o Papa João Paulo II consagrou a Basílica em 1980.",
      "uf": "",
      "municipio": ""
    }],
    "15/10/2024": [{
      "data": "15/10/2024",
      "nome": "Dia do Professor",
      "tipo": "FACULTATIVO",
      "descricao": "FERIADO ESCOLAR - No dia 15 de outubro de 1827 (dia consagrado à educadora Santa Teresa de Ávila), o Imperador do Brasil, Pedro I, baixou um Decreto Imperial que criou o Ensino Elementar no Brasil. Pelo decreto, \"todas as cidades, vilas e lugarejos teriam suas escolas de primeiras letras\"",
      "uf": "",
      "municipio": ""
    }],
    "19/10/2024": [{
      "data": "19/10/2024",
      "nome": "Dia do Piauí",
      "tipo": "ESTADUAL",
      "descricao": "Independência da província do Piauí da coroa portuguesa, que ocorreu na cidade de Parnaiba. Feriado Estadual de acordo com a Lei Estadual nº 176, de 30.08.1937.",
      "uf": "PI",
      "municipio": ""
    }],
    "28/10/2024": [{
      "data": "28/10/2024",
      "nome": "Dia do Servidor Público",
      "tipo": "FACULTATIVO",
      "descricao": "O dia do servidor público é feriado apenas para funcionários públicos.",
      "uf": "",
      "municipio": ""
    }],
    "02/11/2024": [{
      "data": "02/11/2024",
      "nome": "Dia de Finados",
      "tipo": "NACIONAL",
      "descricao": "O Dia de Finados ou Dia dos Fiéis Defuntos, (conhecido ainda como Dia dos Mortos no México), é celebrado pela Igreja Católica no dia 2 de novembro e é Feriado Nacional.",
      "uf": "",
      "municipio": ""
    }],
    "15/11/2024": [{
      "data": "15/11/2024",
      "nome": "Proclamação da República",
      "tipo": "NACIONAL",
      "descricao": "A Proclamação da República foi um levante político-militar ocorrido em 15 de novembro de 1889 que instaurou a forma Republicana Federativa Presidencialista de governo no Brasil, derrubando a Monarquia e, por conseguinte, o imperador dom Pedro II.",
      "uf": "",
      "municipio": ""
    }],
    "20/11/2024": [{
      "data": "20/11/2024",
      "nome": "Dia Nacional de Zumbi e da Consciência Negra",
      "tipo": "NACIONAL",
      "descricao": "Dia Nacional de Zumbi e da Consciência Negra",
      "uf": "",
      "municipio": ""
    }],
    "08/12/2024": [{
      "data": "08/12/2024",
      "nome": "N. Sra. da Conceição",
      "tipo": "FACULTATIVO",
      "descricao": "Nossa Senhora da Conceição",
      "uf": "",
      "municipio": ""
    }],
    "25/12/2024": [{
      "data": "25/12/2024",
      "nome": "Natal",
      "tipo": "NACIONAL",
      "descricao": "O Natal é comemorado anualmente em 25 de Dezembro. Originalmente destinado a celebrar o nascimento anual do Deus Sol no solstício de inverno, foi adaptado pela Igreja Católica no 3o século d.C., para permitir a conversão dos povos pagãos sob o domínio do Império Romano, passando a comemorar o nascimento de Jesus de Nazaré.",
      "uf": "",
      "municipio": ""
    }]
  }