import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./views/App";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { PROJECT_PERSIST } from "./config/GlobalKeys";

import Global from "./global/reducer";
import CalendarioLetivo from "./views/calendario/reducer/calendario";
import CalendarioAcompanhamento from "./views/calendario-acompanhamento/reducer";
import RelatorioGre from "./views/relatorio/reducer";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

const persistConfig = {
  key: PROJECT_PERSIST,
  storage,
  whitelist: ["global"],
};

const reducers = combineReducers({
  global: Global,
  calendarioLetivo: CalendarioLetivo,
  acompanhamento: CalendarioAcompanhamento,
  relatorioGre: RelatorioGre,
});

const persistReducers = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
