import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
    turnos: null,
    status: null,
    escolas: null,
    modelos: null,
    modelo: null,
    historico: null,
    superintencias: null,
    tecnicos: null
}

const acompanhamentoSlice = createSlice({
    name: 'acompanhamentoReducer',
    initialState: INITIAL_STATE,
    reducers: {
        listaTurnos: (state, action) => {
            state.turnos = action.payload ? { ...action.payload } : null
        },
        listaStatus: (state, action) => {
            state.status = action.payload ? { ...action.payload } : null
        },
        listaEscolas: (state, action) => {
            state.escolas = action.payload ? { ...action.payload } : null
        },
        listaModelos: (state, action) => {
            state.modelos = action.payload ? { ...action.payload } : null
        },
        alteraModelo: (state, action) => {
            state.modelo = action.payload ? { ...action.payload } : null
        },
        listaHistorico: (state, action) => {
            state.historico = action.payload ? { ...action.payload } : null;
        },
        listaSuperintendencias: (state, action) => {
            state.superintencias = action.payload ? { ...action.payload } : null;
        },
        listaTecnicos: (state, action) => {
            state.tecnicos = action.payload ? { ...action.payload } : null;
        },
    }
})

export const {
    alteraModelo,
    listaEscolas,
    listaHistorico,
    listaModelos,
    listaStatus,
    listaTurnos,
    listaSuperintendencias,
    listaTecnicos,

} = acompanhamentoSlice.actions
export default acompanhamentoSlice.reducer
