import { Collapse, Drawer, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp, FaSignOutAlt } from "react-icons/fa";
import { MdCalendarToday, MdOutlineClose, MdViewList, MdSummarize } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { ROLES } from "../../global/constants";
import packageInfo from "../../../package.json";
import { getUser, logout } from "../../global/service";
import "./main.css";

const VERSAO_WEB = packageInfo.version;

const Menu = ({ showMenu, setShowMenu }) => {
  const [openSubItens, setOpenSubItens] = useState({});
  const [user, setUser] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setSubmenu();
    getUser((u) => setUser(u));
  }, []);

  const setSubmenu = () => {
    let opensub_aux = {};
    navItems.forEach((item) => {
      if (item.subItens) {
        opensub_aux[item.primary] = false;
      }
    });
    setOpenSubItens(opensub_aux);
  };

  const navItems = [
    // {
    //     primary: "Dashboard",
    //     path: '/dashboard/',
    //     perfil: [1, 0],
    //     icon: <MdSpaceDashboard />
    // },
    {
      primary: "Acompanhamento",
      path: "/acompanhamento-calendario/",
      perfil: [ROLES.PERFIL_GESTOR, ROLES.PERFIL_GRE],
      icon: <MdViewList />,
    },
    {
      primary: "Calendário",
      path: "/calendario/",
      perfil: [ROLES.PERFIL_GESTOR, ROLES.PERFIL_ESCOLA],
      icon: <MdCalendarToday />,
    },
    {
      primary: "Relatório",
      path: "/relatorio/",
      perfil: [ROLES.PERFIL_GESTOR],
      icon: <MdSummarize />,
    },
    // {
    //     primary: "Calendário Escolar",
    //     path: '',
    //     perfil: [ROLES.PERFIL_GESTOR, ROLES.PERFIL_ESCOLA, ROLES.PERFIL_GRE],
    //     icon: <FaCalendar />,
    //     subItens: [
    //         {
    //             primary: "Acompanhamento",
    //             path: '/acompanhamento-calendario/',
    //             perfil: [ROLES.PERFIL_GESTOR, ROLES.PERFIL_GRE],
    //             // icon: <BiFoodMenu />
    //         },
    //         {
    //             primary: "Calendário",
    //             path: '/calendario/',
    //             perfil: [ROLES.PERFIL_GESTOR, ROLES.PERFIL_ESCOLA],
    //         },
    //         {
    //             primary: "Tabela",
    //             path: '/calendario-escolar/',
    //             perfil: [1, 6],
    //             // icon: <BiFoodMenu />
    //         },
    //     ]
    // },
  ];

  const showSubItens = (campo) => {
    setOpenSubItens({ ...openSubItens, [campo]: !openSubItens[campo] });
  };

  return (
    <Drawer style={{ zIndex: 99999 }} open={showMenu} onClose={() => setShowMenu(false)}>
      <div className="menu-lateral-calendario">
        <div className="drawer-header">
          <span>Menu</span>
          <IconButton onClick={() => setShowMenu(false)} style={{ width: 40, height: 40 }}>
            <MdOutlineClose />
          </IconButton>
        </div>
        <div>
          {user &&
            navItems
              .filter((i) => i.perfil.includes(parseInt(user.perfil)))
              .map((item, index) => (
                <List component="div" key={index} disablePadding>
                  {!item.link ? (
                    <Link
                      to={item.path === "" ? location.pathname + location.search : item.path}
                      key={index}
                      className="item-navegacao"
                      onClick={() => {
                        !item.subItens ? setShowMenu(false) : showSubItens(item.primary);
                      }}
                    >
                      <ListItemButton selected={location.pathname === item.path}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.primary} />
                        {item.subItens ? (
                          openSubItens[item.primary] ? (
                            <FaChevronUp size={14} color={"#757575"} />
                          ) : (
                            <FaChevronDown color="#757575" size={14} />
                          )
                        ) : null}
                      </ListItemButton>
                    </Link>
                  ) : (
                    <a
                      href={item.path}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                      className="item-navegacao"
                      onClick={() => setShowMenu(false)}
                    >
                      <ListItemButton>
                        <ListItemIcon sx={{ maxWidth: "19px", height: "19px" }}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.primary} />
                      </ListItemButton>
                    </a>
                  )}
                  {item.subItens && (
                    <Collapse in={openSubItens[item.primary]} timeout="auto" unmountOnExit>
                      {user &&
                        item.subItens
                          .filter((i) => i.perfil.includes(parseInt(user.perfil)))
                          .map((subItem, i) => (
                            <Link
                              to={subItem.path}
                              key={i}
                              className="item-navegacao"
                              onClick={() => setShowMenu(false)}
                            >
                              <ListItemButton selected={location.pathname === subItem.path} sx={{ pl: 5 }}>
                                <ListItemIcon sx={{ minWidth: "30px" }}>
                                  <BsDot />
                                </ListItemIcon>
                                <ListItemText primary={subItem.primary} />
                              </ListItemButton>
                            </Link>
                          ))}
                    </Collapse>
                  )}
                </List>
              ))}
          <ListItemButton>
            <ListItemIcon>
              <FaSignOutAlt />
            </ListItemIcon>
            <ListItemText primary="Sair" action="true" onClick={() => logout()} />
          </ListItemButton>
          <Grid
            container
            sx={{
              position: "absolute",
              left: "35%",
              bottom: 10,
            }}
          >
            Versão {VERSAO_WEB}
          </Grid>
        </div>
      </div>
    </Drawer>
  );
};

export default Menu;
