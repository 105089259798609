import { FormControl, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

let meses = [
    { label: 'Todos', value: '00' },
    { label: 'Janeiro', value: '01' },
    { label: 'Fevereiro', value: '02' },
    { label: 'Março', value: '03' },
    { label: 'Abril', value: '04' },
    { label: 'Maio', value: '05' },
    { label: 'Junho', value: '06' },
    { label: 'Julho', value: '07' },
    { label: 'Agosto', value: '08' },
    { label: 'Setembro', value: '09' },
    { label: 'Outubro', value: '10' },
    { label: 'Novembro', value: '11' },
    { label: 'Dezembro', value: '12' },
]

const PainelListaDescritores = () => {

    const { descritores } = useSelector(state => state.calendarioLetivo)

    const [mesSelecionado, setMesSelecionado] = useState('00')

    const retornaMsgDescritorDiaLetivo = (row) => {
        if (row.descritor) {
            switch (row.descritor.dia_letivo + "") {
                case "1":
                    return 'Sim';
                case "0":
                    return 'Ignorado';
                case "2":
                    return 'Não';
                default:
                    return '-';
            }
        } else {
            return '-';
        }
    }

    let descritoresLista = []
    if (descritores?.dados?.descritoresLista) {
        descritoresLista = mesSelecionado === '00' ?
            descritores.dados.descritoresLista :
            descritores?.dados?.descritoresLista.filter(desc => desc.data_inicio.substring(3, 5) === mesSelecionado)
    }

    return (
        <Grid item md={12} >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <span >Listagem de descritores:</span>
                <FormControl style={{ width: 150 }} size="small">
                    <TextField
                        select
                        value={mesSelecionado}
                        label="Filtrar por mês"
                        InputLabelProps={{ shrink: true, fontSize: "0.75rem" }}
                        onChange={val => setMesSelecionado(val.target.value)}
                        sx={{ background: "#fff" }}
                        size="small"
                        InputProps={{ style: { fontSize: "18px" } }}>
                        {meses.map((item, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    sx={{ fontSize: "18px" }}
                                    value={item.value}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </FormControl>
            </div>
            <TableContainer component={Paper} variant="outlined" style={{ marginTop: 10, marginBottom: 5 }} >
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>#</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Descritor</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Data início</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Data fim</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Dia Letivo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {descritoresLista.length === 0 &&
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>Nenhum descritor encontrado.</TableCell>
                            </TableRow>
                        }
                        {descritoresLista.map((row, key) => {
                            return (
                                <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{key + 1}</TableCell>
                                    <TableCell> {row.descritor.descricao}</TableCell>
                                    <TableCell>{row.data_inicio}</TableCell>
                                    <TableCell>{row.data_fim}</TableCell>
                                    <TableCell>{retornaMsgDescritorDiaLetivo(row)}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default PainelListaDescritores;