import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { FaCheckSquare, FaChevronDown, FaFilePdf, FaHistory, FaLayerGroup } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import ModalTemplate from "../../../components/modal/ModalTemplate";
import { ROLES } from "../../../global/constants";
import Utils from "../../../utils/Uitls";
import { alteraModelo } from "../../calendario-acompanhamento/reducer";
import CalendarioServices from "../CalendarioService";
import { atualizaStatusCalendarioSelecionado } from "../reducer/calendario";
import Calendario from "./Calendario";
import DadosCalendarioAnual from "./DadosCalendarioAnual";
import LegendaDescritores from "./LegendaDescritores";
import ListaDescritores from "./ListaDescritores";
import CalendarioPDF from "./PDF";
import PainelListaDescritores from "./PainelListaDescritores";
import ModalAlteraStatus from "./modal/ModalAlteraStatus";
import ModalHistorico from "./modal/ModalHistorico";
import ModalModeloFormulario from "./modal/ModalModeloFormulario";
import ModalRegras from "./modal/ModalRegras";
import { verificaUsuarioAcessoModeloTrimestre } from "../CalendarioHelper";

const label = { inputProps: { "aria-label": "Switch demo" } };

const PainelCalendario = () => {
  const [loadingEnviaDiasLetivos, setLoadingEnviaDiasLetivos] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [statusAtual, setStatusAtual] = useState();
  const [modalAlteraStatus, setModalAlteraStatus] = useState();
  const [loadingAlteraStatus, setLoadingAlteraStatus] = useState(false);
  const [modalEnvioAnalise, setModalEnvioAnalise] = useState(false);
  const [modalRegras, setModalRegras] = useState(false);
  const [modalHistorico, setModalHistorico] = useState(false);
  const [modalValidacao, setModalValidacao] = useState();
  const [modoView, setModoView] = useState("calendario");
  const [permiteAlteracaoViaStatus, setPermiteAlteracaoViaStatus] = useState(false);
  const [permiteAlteracaoViaAnoSelecionado, setPermiteAlteracaoViaAnoSelecionado] = useState();
  const [modelo, setModelo] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalAlteraModelo, setModalAlteraModelo] = useState(false);

  const [isModeloUnico, setIsModeloUnico] = useState();
  const [loadingQuantidadeDiasLetivos, setLoadingQuantidadeDiasLetivos] = useState();

  const dispatch = useDispatch();

  const {
    descritores,
    descritoresNaoAdicionados,
    descritoresModificados,
    modeloSelecionado,
    modelos,
    statusGerais,
    fluxoStatus,
    descritoresLegendas,
  } = useSelector((state) => state.calendarioLetivo);

  const { anoSelecionado, user, anos, modelo_turnos } = useSelector((state) => state.global);

  const { modelo: modeloAcompanhamento } = useSelector((state) => state.acompanhamento);

  //script para listar diasletivos
  // useEffect(() => {
  //     if (descritores?.dados) {
  //         Object.keys(descritores.dados.diasLetivos).forEach(key => console.log(key));
  //     }
  // }, [descritores?.dados])

  useEffect(() => {
    if (anos && anos.dados && anos.dados.length > 0 && anoSelecionado !== null) {
      setPermiteAlteracaoViaAnoSelecionado(anos.dados.find((a) => a.ano === anoSelecionado)?.status === 1);
    }
  }, [anos?.dados, anoSelecionado]);

  useEffect(() => {
    if (modelo_turnos && anoSelecionado) {
      setIsModeloUnico(!modelo_turnos.includes(anoSelecionado));
    }
  }, [modelo_turnos, anoSelecionado]);

  useEffect(() => {
    if (modeloAcompanhamento) {
      setModelo(modeloAcompanhamento?.dados);
      return;
    }

    if (modelos?.dados && modeloSelecionado) {
      setModelo(modelos.dados.find((model) => model.id === modeloSelecionado));
      return;
    }
  }, [modeloSelecionado, modeloAcompanhamento?.dados, modelos?.dados]);

  useEffect(() => {
    if (!statusGerais) {
      CalendarioServices.buscaStatusGerais(dispatch, {});
    }
  }, [statusGerais]);

  useEffect(() => {
    if (modeloSelecionado && modelo && statusGerais && statusGerais.dados) {
      if (modelo) {
        //se for modelo de rede, sempre vem null e tem permissão de alterar
        if (modelo.escola_id === null) {
          setPermiteAlteracaoViaStatus(true);
        } else {
          let status = statusGerais.dados.find((status) => status.id === modelo.status_atual_id);
          setStatusAtual(status);
        }
      }
    }
  }, [modelo?.status_atual_id, statusGerais?.dados]);

  useEffect(() => {
    if (statusAtual && fluxoStatus?.dados) {
      setPermiteAlteracaoViaStatus(verificaPerfilLogadoEStatusCalendario(user, fluxoStatus?.dados, statusAtual));
    }
  }, [statusAtual, fluxoStatus?.dados]);

  useEffect(() => {
    if (descritores && descritores.dados && descritoresLegendas && descritoresLegendas.dados && modelo) {
      //monta lista de descritores ainda não adicionados
      CalendarioServices.getDescritoresNaoAdicionados(
        descritoresLegendas.dados.lista,
        descritores.dados.descritoresLista,
        user.perfil === ROLES.PERFIL_GESTOR && modelo.escola_id === null,
        modelo,
        dispatch
      );
    }

    if (descritores && descritores.dados) {
      //monta lista de ultimos descritores adicionados/alterados
      CalendarioServices.getDescritoresModificados(descritores.dados.descritoresLista, dispatch);
    }
  }, [descritores?.dados, descritoresLegendas?.dados, modelo]);

  const verificaPerfilLogadoEStatusCalendario = (user, fluxoStatus, status_atual, ignoraPerfil) => {
    if (ROLES.PERFIL_ESCOLA === user.perfil || ignoraPerfil) {
      let permite_alteracao = !!fluxoStatus.find(
        (f) => f.perfil === user.perfil && f.status_anterior_id + "" === status_atual?.id + ""
      );
      return permite_alteracao;
    } else {
      return false;
    }
  };

  const montaPdf = async () => {
    if (!modelo) {
      toastr.warning("Ops! Não foi possível gerar o PDF!");
      return;
    }

    let data_ini = CalendarioServices.getDateByDataSTR(modelo.data_inicio);
    let data_fim = CalendarioServices.getDateByDataSTR(modelo.data_termino);
    let count_meses = CalendarioServices.calculaDiferencaMeses(data_fim, data_ini);
    //console.log(data_fim, data_ini, count_meses)

    let dados = [];
    let date = data_ini;

    for (let i = 0; i < count_meses; i++) {
      let mes = date.getMonth();
      let ano = date.getFullYear();

      dados.push(await CalendarioServices.montaMes(ano, mes, descritores.dados));

      date.setMonth(date.getMonth() + 1);
      date = new Date(date);
    }

    if (count_meses % 2 !== 0) {
      dados.push(null);
    }

    let legendas = descritoresLegendas.dados.listaFilter;

    //organiza tabela de legendas de 2 em 2
    let listaLegendasFinal = await CalendarioServices.montaLegandas(legendas);
    downloadPdf(dados, listaLegendasFinal);
  };

  const RenderPDF = ({ meses, legendas }) => {
    let date = new Date();
    let dataEmissao = `${Utils.formatData2(date)}`;
    let horaEmissao = `${Utils.completaZero(date.getHours())}:${Utils.completaZero(
      date.getMinutes()
    )}:${Utils.completaZero(date.getSeconds())}`;

    let dadosEscola = undefined;
    if (user.perfil === ROLES.PERFIL_ESCOLA) {
      dadosEscola = `ESCOLA: ${user.escola.nome}  INEP: ${user.escola.inep}`;
    } else {
      if (modelo.escola) {
        dadosEscola = `ESCOLA: ${modelo.escola.nome}  INEP: ${modelo.escola.inep}`;
      }
    }
    return (
      <CalendarioPDF
        meses={meses}
        legendas={legendas}
        dadosCalendarioAnual={descritores?.dados ?? {}}
        header={{
          anoSelecionado: anoSelecionado,
          logo: user.empresa.logo,
          entidade: user.empresa.entidade,
          orgao: user.empresa.orgao,
          dataEmissao: `Data de emissão: ${dataEmissao} as ${horaEmissao}`,
          dadosEscola: dadosEscola,
          infoDataEmissao: dataEmissao,
          infoHoraEmissao: horaEmissao
        }}
        user={user}
      />
    );
  };

  const downloadPdf = async (dados, legendas) => {
    setLoadingPDF(true);
    const blob = await pdf(<RenderPDF meses={dados} legendas={legendas} />).toBlob();
    let fileURL = window.URL.createObjectURL(blob);
    let filename = `calendario-${new Date().getTime()}.pdf`;
    let link = document.createElement("a");
    link.href = fileURL;
    link.download = filename;
    link.click();

    setLoadingPDF(false);
  };

  const alteraSituacaoCalendario = (calendario_id, status_id, comentario, gera_dias_letivos) => {
    const onLoading = (val) => setLoadingAlteraStatus(val);
    const onSuccess = () => {
      if (modeloAcompanhamento?.dados) {
        dispatch(
          alteraModelo({
            ...modeloAcompanhamento,
            dados: { ...modeloAcompanhamento.dados, status_atual_id: status_id },
          })
        );
      } else {
        dispatch(
          atualizaStatusCalendarioSelecionado({
            calendario_id,
            status_id,
          })
        );
      }

      switch (status_id) {
        case 2:
          toastr.success("Calendário enviado para análise");
          setModalEnvioAnalise(false);
          break;
        default:
          toastr.success("Situação do calendário alterada com sucesso.");
          setModalAlteraStatus(false);
          break;
      }
    };

    const enviaDiasLetivosCallback = () => {
      if (descritores?.dados) {
        enviaDiasLetivos(calendario_id, onLoading, onSuccess);
      } else {
        onSuccess();
      }
    };

    let callback = gera_dias_letivos ? enviaDiasLetivosCallback : onSuccess;

    let params = {
      calendario_id,
      status_id,
      comentario,
    };
    CalendarioServices.alteraSituacaoCalendario(dispatch, params, onLoading, callback);
  };

  const enviaDiasLetivos = (calendario_id, onLoading, onSuccess) => {
    let datas = Object.keys(descritores.dados.diasLetivos);

    let params = {
      calendario_id,
      dias_letivos: `${datas.map((d) => d.split("/").reverse().join("-"))}`,
      total: datas.length,
    };
    CalendarioServices.enviaDiasLetivos(dispatch, params, onLoading, onSuccess);
  };

  const BotaoAcaoCalendario = ({
    label,
    variant = "contained",
    action = () => {},
    disabled = false,
    color = "primary",
    loading,
  }) => (
    <Button
      disabled={disabled || loading}
      style={{ marginBottom: 10 }}
      variant={variant}
      color={color}
      onClick={action}
    >
      {loading && <CircularProgress size={20} style={{ marginRight: 10 }} />}
      <Typography fontSize={14} noWrap>
        {label}
      </Typography>
    </Button>
  );

  const abrirModalEnvioAnalise = () => {
    setLoadingQuantidadeDiasLetivos(true);
    try {
      CalendarioServices.buscaQuantidadeMinimaDiasLetivos(dispatch, { ano: anoSelecionado }, (res) => {
        if (res.success) {
          const erros = [];
          let { countDiasLetivos1semestre, countDiasLetivos2semestre } = descritores.dados;

          let minimoDiasLetivosDefault = undefined;
          let minimoDiasLetivosEscola = undefined;

          res.response.forEach((val) => {
            if (val.escola_id === user.relacionado.id) {
              minimoDiasLetivosEscola = val.qtd_dias;
            }

            if (val.escola_id === 0) {
              minimoDiasLetivosDefault = val.qtd_dias;
            }
          });

          let minimoDiasLetivos = minimoDiasLetivosEscola ?? minimoDiasLetivosDefault;

          //esse valor vai ser parametrizado
          if (res.response.length > 0 && (countDiasLetivos1semestre + countDiasLetivos2semestre < minimoDiasLetivos)) {
            erros.push(`A quantidade mínima de dias letivo no calendário letivo é: ${minimoDiasLetivos}`);
          }

          if (descritoresNaoAdicionados?.length > 0) {
            erros.push(`Existem descritores obrigátorios não adicionados.`);
          }
          setLoadingQuantidadeDiasLetivos(false);

          if (erros.length > 0) {
            setModalValidacao(erros);
          } else {
            setModalEnvioAnalise(true);
          }
        } else {
           toastr.warning("Não foi possível concluir envio.");
           setLoadingQuantidadeDiasLetivos(false);
         }
      });
    } catch (e) {}
  };

  const retornaBotaoRelacionadoStatusCalendarioPerfil = (user, statusAtual, listaCompletaFluxosStatus) => {
    if (!statusAtual || !permiteAlteracaoViaAnoSelecionado) {
      return null;
    }

    switch (user.perfil) {
      case ROLES.PERFIL_ESCOLA:
        if (statusAtual.id === 1) {
          // se o calendário estiver incompleto pode ser enviado para análise
          return (
            <BotaoAcaoCalendario
              loading={loadingQuantidadeDiasLetivos}
              variant={"outlined"}
              label={"Enviar para análise"}
              action={abrirModalEnvioAnalise}
            />
          );
        } else {
          let status_encontrado = verificaPerfilLogadoEStatusCalendario(
            user,
            listaCompletaFluxosStatus,
            statusAtual,
            true
          );
          if (status_encontrado) {
            // se encontrou status relacionado, então pode reenviar o calendário para análise
            return (
              <BotaoAcaoCalendario
                loading={loadingQuantidadeDiasLetivos}
                variant={"outlined"}
                label={"Reenviar para análise"}
                action={abrirModalEnvioAnalise}
                disabled={loadingPDF}
              />
            );
          } else {
            return null;
          }
        }
      default:
        let status_encontrado = verificaPerfilLogadoEStatusCalendario(
          user,
          listaCompletaFluxosStatus,
          statusAtual,
          true
        );
        if (status_encontrado) {
          return (
            <BotaoAcaoCalendario
              variant={"outlined"}
              label={"Alterar situação calendário"}
              action={() => setModalAlteraStatus(true)}
              disabled={loadingPDF}
            />
          );
        } else {
          return null;
        }
    }
  };

  const retornaChipStatusCalendarioPerfil = (user, statusAtual) => {
    if (!statusAtual) {
      return null;
    }

    return (
      <Chip
        style={{
          width: "100%",
          marginBottom: 10,
          fontSize: 16,
          backgroundColor: `${statusAtual.cor}60`,
        }}
        icon={<FaLayerGroup />}
        label={`Status atual: ${statusAtual.descricao}`}
      />
    );
  };

  const retornaBotaoOpcoes = () => {
    const onClose = () => setAnchorEl(null);

    const alteraFormaVisualizacao = () => setModoView((modo) => (modo === "calendario" ? "lista" : "calendario"));

    const open = Boolean(anchorEl);

    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu-options-calendario" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          style={{ marginBottom: 10 }}
          onClick={(ev) => setAnchorEl(ev.currentTarget)}
          endIcon={<FaChevronDown size={12} />}
        >
          Mais opções
        </Button>
        <Menu
          className="menu-calendario"
          id="basic-menu-options-calendario"
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{ style: { width: "305px" } }}
        >
          <MenuItem onClick={alteraFormaVisualizacao}>
            <Switch checked={modoView === "lista"} {...label} />
            Visualizar dados em lista
          </MenuItem>
          <MenuItem disabled={loadingPDF} onClick={montaPdf}>
            <FaFilePdf style={{ color: "#252525", marginRight: 10 }} size={18} />
            {loadingPDF ? "Gerando..." : "Gerar PDF"}
          </MenuItem>
          {user.perfil === ROLES.PERFIL_GESTOR && (
            <MenuItem
              disabled={loadingEnviaDiasLetivos}
              onClick={() =>
                enviaDiasLetivos(
                  modeloSelecionado,
                  () => setLoadingEnviaDiasLetivos(true),
                  () => setLoadingEnviaDiasLetivos(false)
                )
              }
            >
              <FaCheckSquare style={{ color: "#252525", marginRight: 10 }} size={18} solid />
              {loadingEnviaDiasLetivos ? "Enviando..." : "Validar dias letivos"}
            </MenuItem>
          )}
          {statusAtual && (
            <MenuItem
              onClick={() => {
                setModalHistorico(true);
                onClose();
              }}
            >
              <FaHistory style={{ color: "#252525", marginRight: 10 }} size={18} />
              Visualizar histórico
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  };

  const renderReferencia = () => {
    if (!modelo || modelo.escola_id === null || isModeloUnico) {
      return null;
    }

    return (
      <div
        style={{
          marginBottom: 10,
          backgroundColor: "#EFEFEF",
          height: 39,
          borderRadius: 4,
          fontSize: 14,
          display: "flex",
          fontWeight: 500,
          alignItems: "center",
          padding: "0px 8px",
        }}
      >
        <b style={{ marginRight: 5 }}>Referência:</b> Modelo {modelo.modelo}
      </div>
    );
  };

  const retornaBotaoAlterarModelo = () => {
    if (
      modelo &&
      modelo.escola_id === null &&
      user.perfil === ROLES.PERFIL_GESTOR &&
      permiteAlteracaoViaAnoSelecionado
    ) {
      return (
        <>
          <Button variant="outlined" style={{ marginBottom: 10 }} onClick={() => setModalAlteraModelo(true)}>
            Editar período
          </Button>
          <ModalModeloFormulario
            open={modalAlteraModelo}
            close={() => setModalAlteraModelo(false)}
            anoSelecionado={anoSelecionado}
            user={user}
            modelo={modelo}
            isModeloUnico={isModeloUnico}
          />
        </>
      );
    }

    return null;
  };

  const retornaDadosDoCalendarioAnual = () => {
    if (descritores && descritores.dados) {
      return <DadosCalendarioAnual dados_descritores={{...descritores.dados}} modelo_trimestre={verificaUsuarioAcessoModeloTrimestre(user, anoSelecionado)} />;
    }
    return null;
  };

  return (
    <>
      {descritores && descritores.loading ? null : (
        <>
          <Grid item xs={12} sm={3} md={3} lg={3} style={{ padding: "0px 10px" }}>
            {renderReferencia()}
            {retornaChipStatusCalendarioPerfil(user, statusAtual)}
            {retornaBotaoAlterarModelo()}
            {retornaBotaoRelacionadoStatusCalendarioPerfil(user, statusAtual, fluxoStatus.dados)}
            {retornaBotaoOpcoes()}
            {retornaDadosDoCalendarioAnual()}
            {descritoresNaoAdicionados && (
              <ListaDescritores
                title={"Descritores obrigatórios:"}
                lista={descritoresNaoAdicionados}
                cor={"#e53344"}
                totais={
                  descritoresLegendas?.dados?.lista
                    ? descritoresLegendas.dados.lista.filter((l) => l.obrigatorio === 1)
                    : []
                }
                progress={modelo?.escola_id === null ? false : true}
                msgEmpty={"Calendário completo"}
                openModal={(val) => setModalRegras(val)}
              />
            )}
            {descritoresModificados && (
              <ListaDescritores
                title={"Últimos descritores modificadores/adicionados:"}
                lista={descritoresModificados}
                dataVisible={true}
                cor={"#14a2b8"}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={9} md={9} lg={9}>
            <Grid container spacing={1}>
              {modoView === "calendario" ? (
                <Calendario
                  ano={anoSelecionado}
                  permiteAlteracao={permiteAlteracaoViaStatus && permiteAlteracaoViaAnoSelecionado}
                />
              ) : (
                <PainelListaDescritores />
              )}
            </Grid>
            <LegendaDescritores />
          </Grid>
        </>
      )}
      {modalAlteraStatus && (
        <ModalAlteraStatus
          anoSelecionado={anoSelecionado}
          close={() => setModalAlteraStatus(false)}
          open={true}
          user={user}
          loading={loadingAlteraStatus}
          onSubmit={alteraSituacaoCalendario}
          modelo={modelo}
        />
      )}
      {modalEnvioAnalise && modeloSelecionado && (
        <ModalTemplate
          title={"Enviar calendário para análise"}
          open={modalEnvioAnalise}
          textConfirm={"Enviar"}
          closeModal={() => setModalEnvioAnalise(false)}
          onConfirm={() =>
            alteraSituacaoCalendario(modeloSelecionado, 2, "Escola enviou o calendário para análise", true)
          }
          loading={loadingAlteraStatus}
          desabilitarBotao={true}
          habilitarConfirmacao={true}
        >
          Deseja enviar o calendário para análise?
        </ModalTemplate>
      )}
      <ModalTemplate
        title={"Observações no calendário"}
        open={!!modalValidacao}
        closeModal={() => setModalValidacao(undefined)}
        desabilitarBotao={false}
      >
        <div>
          {!!modalValidacao && (
            <Alert severity="warning">
              <AlertTitle>Atenção</AlertTitle>
              <ul>
                {modalValidacao.map((validacao, k) => (
                  <li key={k}>{validacao}</li>
                ))}
              </ul>
            </Alert>
          )}
        </div>
      </ModalTemplate>
      <ModalRegras
        modelo={modelo}
        descritoresCalendario={descritores?.dados?.descritoresLista}
        open={!!modalRegras}
        descritorSelecionado={modalRegras}
        close={() => setModalRegras(undefined)}
      />
      {modalHistorico && (
        <ModalHistorico
          isModeloUnico={isModeloUnico}
          modeloSelecionado={modeloSelecionado}
          open={modalHistorico}
          close={() => setModalHistorico(false)}
          modelo={modelo}
          escola={user.perfil === ROLES.PERFIL_ESCOLA ? user.nome : modelo?.escola?.nome}
        />
      )}
    </>
  );
};

export default PainelCalendario;
