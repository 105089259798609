import React, { useEffect, useState } from "react";
import MainRoutes from "../config/Routes";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { KEY_WORKER, STATUS_WORKER } from "../global/constants";
import SnackBar from "../components/UpdateButton";

export default function App() {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(serviceWorkerRegistration | null);

  const onUpdateSW = (registragion) => {
    setShowReload(true);
    setWaitingWorker(registragion.waiting);
  };

  const reloadPage = () => {
    if (waitingWorker.postMessage) {
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
      window.localStorage.setItem(KEY_WORKER, STATUS_WORKER.SEM_ATUALIZACAO);
      setShowReload(false);
    }
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onUpdateSW });

    let waiting = window.localStorage.getItem(KEY_WORKER);
    if (waiting && waiting === STATUS_WORKER.POSSUI_ATUALIZACAO)
      serviceWorkerRegistration.register({ onForce: onUpdateSW });
  }, []);

  return (
    <>
      <SnackBar showReload={showReload} reloadPage={reloadPage} />
      <MainRoutes />
    </>
  );
}
