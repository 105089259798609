import { toPng } from 'html-to-image';
import React, { useEffect, useRef } from 'react';

const Painel = () => {

    const ref = useRef(null)

    const geraImagem = () => {
        if (ref.current === null) {
            return
        }

        toPng(ref.current, { cacheBust: true, })
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = `imagem-${new Date().getTime()}.png`
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        geraImagem()
    }, [ref?.current])

    return (
        <div ref={ref}
            style={{
                backgroundColor: '#FFF',
                padding: 25,
                border: '2px solid black',
                marginTop: 10,
                width: 600,
                textAlign: 'center',
            }}>
            <h2>IMAGEM GERADA POR HTML - TESTE</h2>
        </div>
    )
}

export default Painel;