import React from 'react';
import { styled } from '@mui/system';
import TooltipCalendario from './TooltipCalendario';
import Utils from '../../../utils/Uitls';
import { LISTA_FERIADOS } from '../../../global/lista_feriados';
import { useSelector } from 'react-redux';
import { isUsuarioSemec } from '../CalendarioHelper'
import { CORES_MARCADORES_CALENDARIO } from '../../../global/constants';

const ItemCalendario = ({ descritores, diaLetivo, action, date, permiteAlteracao }) => {

    const { user } = useSelector(state => state.global);

    const MarcadorDia = styled('div')(({ color }) => ({
        width: '30px',
        height: '3px',
        backgroundColor: color,
    }));

    const MarcadorDiaAno = styled('div')(({ color }) => ({
        width: '5px',
        height: '5px',
        top: 4,
        right: 4,
        borderRadius: '50%',
        backgroundColor: color,
        position: 'absolute',
    }));

    const exibeIndicadorFeriados = (usuario, listaFeriadosDia) => {
        if (isUsuarioSemec(usuario) && listaFeriadosDia) {
            return true;            
        } else {
            if (listaFeriadosDia) {
                let feriado_municipal = listaFeriadosDia.find(item => item.municipio+"" !== "");
                if (listaFeriadosDia.length === 1) {
                    if (feriado_municipal) {
                        return false;
                    }
                }
                return true;
            } else {
                return false;
            }
        }
    }

    const feriadosRelacionadosDia = exibeIndicadorFeriados(user, LISTA_FERIADOS[Utils.formatData2(date)]);

    return (
        <TooltipCalendario
            action={action}
            date={date}
            marcadores={descritores}
            diaLetivo={diaLetivo}
            permiteAlteracao={permiteAlteracao}>
            {descritores &&
                descritores.map((descritor, key) => {
                    return <MarcadorDia key={key} color={descritor.cor} />
                })}
            {
                diaLetivo &&
                <MarcadorDia color={diaLetivo.cor} />
            }
            {feriadosRelacionadosDia && <MarcadorDiaAno color={CORES_MARCADORES_CALENDARIO.FERIADOS} />}
        </TooltipCalendario>
    )
}

export default ItemCalendario;